import React from "react";
import { Card } from "react-bootstrap";
import "./CardStyles.scss";
const CardView = ({ children, title, image }) => {
  return (
    <div className="card-control">
      <Card className="card">
        {image && (
          <Card.Img
            variant="top"
            src={image}
            style={{ height: "250px", width: "332px" }}
          />
        )}
        <Card.Body className="card-body">
          <Card.Title>{title}</Card.Title>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardView;
