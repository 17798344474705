import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  IoEllipsisVertical,
  IoCloudUploadOutline,
  IoTrashOutline,
  IoShareSocialOutline,
  IoCreateOutline,
  IoFolderOutline,
  IoDuplicateOutline,
} from "react-icons/io5";
import { TbApi } from "react-icons/tb";
import variables from "../../../assets/styles/_variables.scss";
import { stringConstants } from "../../../constants/stringConstants";

const TreeItemMenu = ({
  isRootItem,
  itemType,
  onRenameItemClicked,
  onDuplicateItemClicked,
  onAddFolderClicked,
  onAddNewRequestClicked,
  onSyncItemClicked,
  onShareItemClicked,
  onDeleteItemClicked,
}) => {
  const menuItems = [
    {
      //menu item #0 : Rename
      icon: <IoCreateOutline size={13} color={variables.secondaryColor} />,
      text: stringConstants.rename,
      onClick: onRenameItemClicked,
    },

    {
      //menu item #1 : Duplicate
      icon: <IoDuplicateOutline size={12} color={variables.secondaryColor} />,
      text: stringConstants.duplicate,
      onClick: onDuplicateItemClicked,
    },

    {
      //menu item #2 : Add Folder
      icon: <IoFolderOutline size={12} color={variables.secondaryColor} />,
      text: stringConstants.addFolder,
      onClick: onAddFolderClicked,
    },

    {
      //menu item #3 : Add Request
      icon: <TbApi size={14} color={variables.secondaryColor} />,
      text: stringConstants.addNewRequest,
      onClick: onAddNewRequestClicked,
    },

    {
      //menu item #4 : Sync Item
      icon: <IoCloudUploadOutline size={12} color={variables.secondaryColor} />,
      text: stringConstants.syncItem,
      onClick: onSyncItemClicked,
    },

    {
      //menu item #5 : Share Collection
      icon: <IoShareSocialOutline size={12} color={variables.secondaryColor} />,
      text: stringConstants.shareCollection,
      onClick: onShareItemClicked,
    },

    {
      //menu item #6 : Delete
      icon: <IoTrashOutline size={12} color={variables.secondaryColor} />,
      text: stringConstants.delete,
      onClick: onDeleteItemClicked,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {
        !isRootItem ? (
          <IconButton
            aria-label="more"
            id="tree-item-icon-button"
            aria-controls={open ? "tree-item-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <IoEllipsisVertical size={8} color="#000" />
          </IconButton>
        ) : null //Don't show menu icon for root element
      }
      <Menu
        id="tree-item-menu"
        MenuListProps={{
          "aria-labelledby": "tree-item-icon-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {/* menu item #0 : Rename ----------------------*/}
        <MenuItem button dense>
          <div
            className="treeitem_menu"
            onClick={() => {
              handleClose();
              menuItems[0].onClick();
            }}
          >
            {menuItems[0].icon}
            <text style={{ "padding-left": "10px" }}>{menuItems[0].text} </text>
          </div>
        </MenuItem>

        {/* menu item #1 : Duplicate -------------------*/}
        {itemType === stringConstants.NODE_TYPE_API ? (
          <MenuItem button dense>
            <div
              className="treeitem_menu"
              onClick={() => {
                handleClose();
                menuItems[1].onClick();
              }}
            >
              {menuItems[1].icon}
              <text style={{ "padding-left": "10px" }}>
                {menuItems[1].text}
              </text>
            </div>
          </MenuItem>
        ) : null}

        {/* menu item #2 : Add Folder -----------------*/}
        {itemType !== stringConstants.NODE_TYPE_API ? (
          <MenuItem button dense>
            <div
              className="treeitem_menu"
              onClick={() => {
                handleClose();
                menuItems[2].onClick();
              }}
            >
              {menuItems[2].icon}
              <text style={{ "padding-left": "10px" }}>
                {menuItems[2].text}
              </text>
            </div>
          </MenuItem>
        ) : null}

        {/* menu item #2 : Add Request -----------------*/}
        {itemType !== stringConstants.NODE_TYPE_API ? (
          <MenuItem button dense>
            <div
              className="treeitem_menu"
              onClick={() => {
                handleClose();
                menuItems[3].onClick();
              }}
            >
              {menuItems[3].icon}
              <text style={{ "padding-left": "10px" }}>
                {menuItems[3].text}
              </text>
            </div>
          </MenuItem>
        ) : null}

        {/* menu item #4 : Sync Item -------------------*/}
        <MenuItem button dense>
          <div
            className="treeitem_menu"
            onClick={() => {
              handleClose();
              menuItems[4].onClick();
            }}
          >
            {menuItems[4].icon}
            <text style={{ "padding-left": "10px" }}>{menuItems[4].text}</text>
          </div>
        </MenuItem>

        {/* menu item #5 : Share Collection ------------*/}
        {itemType === stringConstants.NODE_TYPE_COLLECTION ? (
          <MenuItem button dense>
            <div
              className="treeitem_menu"
              onClick={() => {
                handleClose();
                menuItems[5].onClick();
              }}
            >
              {menuItems[5].icon}
              <text style={{ "padding-left": "10px" }}>
                {menuItems[5].text}
              </text>
            </div>
          </MenuItem>
        ) : null}

        {/* menu item #6 : Delete */}
        <MenuItem button dense>
          <div
            className="treeitem_menu"
            onClick={() => {
              handleClose();
              menuItems[6].onClick();
            }}
          >
            {menuItems[6].icon}
            <text style={{ "padding-left": "10px" }}>{menuItems[6].text}</text>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TreeItemMenu;

// ------- Only Text Item sample code----------
//
//   <MenuItem
//     key={"SyncItem"}
//     onClick={() => {
//       handleClose();
//       onSyncItemClicked();
//     }}
//     dense
//   >
//     Sync Item
//   </MenuItem>
