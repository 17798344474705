import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import "./mainLayoutStyles.scss";

class MainLayout extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="main">
        <main>{this.props.children}</main>
      </div>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
