import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { ButtonView } from "../../controlsNew";
import { stringConstants } from "../../../constants/stringConstants";
// import "./JsonFileReaderStyles.scss";

const JsonFileReader = ({
  title,
  message,
  onFileRead,
  onCancelClick,
  styles,
}) => {
  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      let fileContent = e.target.result;
      fileContent = fileContent.replace(/(\r\n|\n|\r|\t)/gm, "");
      // console.log("fileContent", fileContent);
      onFileRead(fileContent);
    };
  };
  return (
    <>
      <Modal show={true} onHide={onCancelClick} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <text>{message}</text>
          <br />
          <br />
          <input type="file" onChange={handleChange} />
          <br />
          <br />
        </Modal.Body>

        <Modal.Footer>
          <ButtonView
            title={stringConstants.importCancel}
            onClick={onCancelClick}
            styles="secondary large"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

JsonFileReader.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onFileRead: PropTypes.func,
  onCancelClick: PropTypes.func,
  style: PropTypes.string,
};

JsonFileReader.defaultProps = {
  disabled: false,
  styles: "primal",
};

export default JsonFileReader;
