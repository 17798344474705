import { stringConstants } from "../../constants/stringConstants";
import { actionTypes } from "./environmentActions";

const initialState = {
  // All Environments and variables corresponding to the environments
  environments: [],
  variables: [],

  // This ENV is to be used while accessing {{var}} in api's.
  // This Environment id is set from "Select Env" button in <EnvContentPanel/>
  selectedEnvId: stringConstants.NO_ENV_SELECTED_ID,

  // This is only for showing variables list in <EnvContentPanel/> when env name is clicked in sidebar
  isEnvClicked: false,

  // This is only for showing variables list in <EnvContentPanel/> when env name is clicked in sidebar
  clickedEnvId: null,

  // Timestamp as a temp variable while updating redux data
  timestamp: null,
};

export default function environment(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.ADD_NEW_ENV:
      return { ...state, ...payload };

    case actionTypes.ADD_NEW_VAR:
      return { ...state, ...payload };

    case actionTypes.EDIT_ENV_NAME:
      return { ...state, ...payload };

    case actionTypes.DELETE_ENV:
      return { ...state, ...payload };

    case actionTypes.GET_VARIABLES_FOR_ENV:
      return { ...state, ...payload };

    case actionTypes.SET_CLICKED_ENV_ID:
      return { ...state, ...payload };

    case actionTypes.RESET_CLICKED_ENV_ID:
      return { ...state, ...payload };

    case actionTypes.SET_SELECTED_ENV_ID:
      return { ...state, ...payload };

    case actionTypes.RESET_SELECTED_ENV_ID:
      return { ...state, ...payload };

    case actionTypes.REPLACE_ALL_ENV_DATA:
      return { ...state, ...payload };

    case actionTypes.RESET_ENV_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
}
