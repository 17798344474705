export const actionTypes = {
  SAVE_USER_AUTH_DETAILS: "SAVE_USER_AUTH_DETAILS",
  RESET_USER_AUTH_DETAILS: "RESET_USER_AUTH_DETAILS",
};

export function saveUserAuthDetails(authObject) {
  console.log("saveUserAuthDetails" + JSON.stringify(authObject));
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_USER_AUTH_DETAILS,
      payload: {
        isUserLoggedIn: true,
        userFirstName: authObject.firstName,
        userLastName: authObject.lastName,
        userEmail: authObject.email,
        token: authObject.token,
      },
    });
  };
}

export function resetUserAuthDetails() {
  console.log("resetUserAuthDetails");

  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_USER_AUTH_DETAILS,
      payload: {
        isUserLoggedIn: false,
        userFirstName: null,
        userLastName: null,
        userEmail: null,
        token: null,
      },
    });
  };
}
