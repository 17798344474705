import {
  IoFolderOutline,
  IoShapesOutline,
  IoExtensionPuzzleOutline,
  IoGridOutline,
  IoGitNetworkOutline,
  IoCalendarNumberOutline,
} from "react-icons/io5";

import { BsFolder } from "react-icons/bs";
import { stringConstants } from "./stringConstants";

export const sidebarButtons = [
  {
    id: stringConstants.CONTEXT_APIS,
    name: stringConstants.CONTEXT_APIS,
    icon: <BsFolder size={15} />,
  },
  {
    id: stringConstants.CONTEXT_ENV,
    name: stringConstants.CONTEXT_ENV,
    icon: <IoShapesOutline size={15} />,
  },
  {
    id: stringConstants.CONTEXT_FLOW,
    name: stringConstants.CONTEXT_FLOW,
    icon: <IoGitNetworkOutline size={17} />,
  },
  {
    id: stringConstants.CONTEXT_HISTORY,
    name: stringConstants.CONTEXT_HISTORY,
    icon: <IoCalendarNumberOutline size={17} />,
  },
];
