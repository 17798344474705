import React from "react";
import {
  Input,
  IconButtonView,
  ButtonView,
  PopupDialog,
  Dropdown,
  ListView,
  ListItemSimple,
} from "../../../controlsNew";
import { stringConstants } from "../../../../constants/stringConstants";
import { TbLayoutGridAdd, TbVariablePlus } from "react-icons/tb";
import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";
import { globalVariablesEnv } from "../../../../store/environment/environmentActions";
import "./EnvSideBarStyles.scss";

const EnvSideBarPanel = (props) => {
  // console.log("SIDEBAR PROPS: " + JSON.stringify(props, null, 4));

  function renderListView() {
    //Check if environments list contain "Globals" environment
    const lodash = require("lodash");
    var data = lodash.cloneDeep(props.environments);
    var index = data.findIndex((item) => {
      if (item.id === globalVariablesEnv.id) {
        return true;
      }
      return false;
    });

    //We don't want to show "Globals" environment in this list, as it is shown separately.
    if (index !== -1) {
      data.splice(index, 1);
    }

    return (
      <ListView
        data={data}
        onClick={(item) => props.onEnvironmentListClick(item)}
        showCheckmark={true}
      />
    );
  }

  function renderAddNewEnvPopupContent() {
    return (
      <>
        <Input
          placeholder={stringConstants.addEnvironmentPopupPlaceholder}
          noOutline
          onChange={props.onNewEnvironmentNameChange}
          value={props.newEnvironmentName}
        />

        <br />
        <text>Please select a Collection for this Environment</text>
        <br />

        <ListView
          data={props.collections}
          onClick={(item) => props.onCollectionListClick(item)}
        />

        <br />
        <text>
          Selected Collection : {props.newEnvSelectedCollection?.name ?? ""}
        </text>
        <br />
      </>
    );
  }

  return (
    <>
      <div className="sidebar-envs-container ">
        <div className="sidebar-horizontal-container-2 px-1 py-2">
          <ButtonView
            title={stringConstants.addEnvironment}
            icon={<TbLayoutGridAdd size={15} color="#555" />}
            styles="grey-outline small"
            onClick={props.onAddNewEnvClick}
            fullWidth
          />

          <ButtonView
            // title="Sync All Data"
            icon={<IoCloudUploadOutline size={15} color="#000" />}
            onClick={props.onSyncEnvDataClicked}
            styles="grey-outline small"
            tooltip={stringConstants.syncEnvironment}
          />

          <ButtonView
            // title="Fetch All Data"
            icon={<IoCloudDownloadOutline size={15} color="#000" />}
            onClick={props.onFetchEnvDataClicked}
            styles="grey-outline small"
            tooltip={stringConstants.fetchEnvironment}
          />
        </div>

        <div className="sidebar-horizontal-container-2 px-2 py-2">
          <ListItemSimple
            dataObject={globalVariablesEnv}
            onClick={() => props.onGlobalEnvClick()}
            // showCheckmark={true}
          />
        </div>

        {renderListView()}

        {props.selectedEnvId === stringConstants.NO_ENV_SELECTED_ID ? (
          <text className="list_text_italic p-2 mx-2">
            <em>No Environment Selected</em>
          </text>
        ) : null}
      </div>

      {props.showAddNewEnvironmentPopup === true ? (
        <PopupDialog
          title={stringConstants.addEnvironmentPopupTitle}
          message={stringConstants.addEnvironmentPopupSubtitle}
          onConfirmClick={props.onAddEnvironmentConfirmClick}
          onCancelClick={props.onAddEnvironmentCancelClick}
          renderContents={renderAddNewEnvPopupContent()}
        />
      ) : null}
    </>
  );
};

export default EnvSideBarPanel;
