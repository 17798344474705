import React, { Component } from "react";
import moment from "moment";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import { showLoader, hideLoader } from "../../../../store/loader/loaderActions";

import {
  addNewCollection,
  addNewNodeInCollectionTree,
  importCollection,
  getCollectionDataFromId,
  deleteCollection,
  deleteNode,
  getNodeDetails,
  updateNodeDataOnSaveClick,
  updateCollectionNodeOnSaveClick,
  updateOpenTabsDataOnRename,
  saveNode,
} from "../../../../store/workspace/workspaceActions";

import {
  checkIfCollectionExistInDB,
  getUsersAdminAccessIds,
  getUsersWriteAccessIds,
  getUsersReadAccessIds,
  pushUsersNewCollections,
  getPermissionsDataForCollection,
  uploadUserAccessDataInDB,
  updateSingleNode,
  pushUsersWriteAccessCollections,
  fetchUsersCollections,
} from "../../../../firebase.js";

import {
  addIdAndTypeInImportedCollection,
  addIdAndTypeInNewCollection,
  addIdAndTypeInNewFolder,
  generateRandomNodeId,
  getCollectionsDataWithoutDeletedItems,
} from "../../../../utils/apiDataUtil";

import { validateTextInput } from "../../../../utils/validator";
import { stringConstants } from "../../../../constants/stringConstants";
import WorkspaceSideBarPanel from "./WorkspaceSideBarPanel";
import "./WorkspaceSideBarStyles.scss";

export class WorkspaceSideBarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allCollections: this.props.collections,

      // Popup for --> [1] Add New collection, [2] Add Folder, [3] Add New Request, [4] Rename options.
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",

      // Import Collection
      showFileReader: false,

      // Sync All Data
      showSyncConfirmDialog: false,

      // Rename Item
      nodeData: {},

      // Add folder / Add Request
      newItemsParentNodeId: "",

      // Share Collection
      showShareCollectionPopup: false,
      shareCollectionId: "",
      shareCollectionName: "",
      shareCollectionUsersList: [],
    };

    this.loadCollectionsInTreeView = this.loadCollectionsInTreeView.bind(this);

    this.onInputPopupEnteredNameChange =
      this.onInputPopupEnteredNameChange.bind(this);
    this.onInputPopupCancelClick = this.onInputPopupCancelClick.bind(this);
    this.onInputPopupConfirmClick = this.onInputPopupConfirmClick.bind(this);

    this.onAddNewCollectionClick = this.onAddNewCollectionClick.bind(this);
    this.onAddCollectionConfirmClick =
      this.onAddCollectionConfirmClick.bind(this);

    this.onImportCollectionClicked = this.onImportCollectionClicked.bind(this);
    this.onFileRead = this.onFileRead.bind(this);
    this.onImportCancelClick = this.onImportCancelClick.bind(this);

    this.onSyncDataClicked = this.onSyncDataClicked.bind(this);
    this.onSyncConfirmClick = this.onSyncConfirmClick.bind(this);
    this.onSyncCancelClick = this.onSyncCancelClick.bind(this);
    this.onFetchDataClicked = this.onFetchDataClicked.bind(this);

    this.onRenameItemClicked = this.onRenameItemClicked.bind(this);
    this.onRenameConfirmClick = this.onRenameConfirmClick.bind(this);

    this.onDuplicateItemClicked = this.onDuplicateItemClicked.bind(this);

    this.onAddFolderClicked = this.onAddFolderClicked.bind(this);
    this.onAddFolderConfirmClick = this.onAddFolderConfirmClick.bind(this);

    this.onAddNewRequestClicked = this.onAddNewRequestClicked.bind(this);
    this.onAddNewRequestConfirmClick =
      this.onAddNewRequestConfirmClick.bind(this);

    this.onSyncItemClicked = this.onSyncItemClicked.bind(this);

    this.onShareItemClicked = this.onShareItemClicked.bind(this);
    this.onUsersListDataChange = this.onUsersListDataChange.bind(this);
    this.onUsersListAddItemClick = this.onUsersListAddItemClick.bind(this);
    this.onUsersListDeleteItemClick =
      this.onUsersListDeleteItemClick.bind(this);
    this.onShareCollectionConfirmClick =
      this.onShareCollectionConfirmClick.bind(this);
    this.onShareCollectionCancelClick =
      this.onShareCollectionCancelClick.bind(this);

    this.onDeleteItemClicked = this.onDeleteItemClicked.bind(this);
    this.deleteItemAndSyncToServer = this.deleteItemAndSyncToServer.bind(this);
  }

  componentDidMount() {
    this.loadCollectionsInTreeView();
  }

  componentWillReceiveProps(newProps) {
    // console.log("SIDEBAR : componentWillReceiveProps!!");
    if (this.props !== newProps) {
      this.props = newProps;

      this.loadCollectionsInTreeView();
    }
  }

  componentWillUpdate() {
    // console.log("SIDEBAR : componentWillUpdate!!");
  }

  /*--------------- LOAD COLLECTIONS IN TREEVIEW ----------------*/

  loadCollectionsInTreeView = () => {
    var lodash = require("lodash");
    var allCollectionsData = lodash.cloneDeep(this.props.collections);

    var updatedCollectionsForTreeView = []; //This will be shown in sidebar Treeview!!!

    allCollectionsData.forEach((collectionItem) => {
      if (
        collectionItem.hasOwnProperty("isDeleted") &&
        collectionItem.isDeleted === true
      ) {
        //Don't add this collection, as it is deleted
      } else {
        var collectionDataWithoutDeletedItems =
          getCollectionsDataWithoutDeletedItems(collectionItem);

        // console.warn(
        //   "loadCollectionsInTreeView ### " +
        //     collectionItem.id +
        //     "--> " +
        //     JSON.stringify(collectionDataWithoutDeletedItems, null, 4)
        // );

        updatedCollectionsForTreeView.push(collectionDataWithoutDeletedItems);
      }
    });

    this.setState({ allCollections: updatedCollectionsForTreeView });
  };

  /*--------------- COMMON POPUP FOR ENTERING NAME ----------------*/

  onInputPopupEnteredNameChange = (event) => {
    var name = event.target.value;
    this.setState({ inputPopupEnteredName: name });
  };

  onInputPopupCancelClick = () => {
    this.setState({
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",
    });
  };

  onInputPopupConfirmClick = () => {
    if (validateTextInput(this.state.inputPopupEnteredName)) {
      if (
        this.state.inputPopupType === stringConstants.POPUP_TYPE_ADD_COLLECTION
      ) {
        this.onAddCollectionConfirmClick();
      } else if (
        this.state.inputPopupType === stringConstants.POPUP_TYPE_ADD_FOLDER
      ) {
        this.onAddFolderConfirmClick();
      } else if (
        this.state.inputPopupType === stringConstants.POPUP_TYPE_ADD_NEW_REQUEST
      ) {
        this.onAddNewRequestConfirmClick();
      } else if (
        this.state.inputPopupType === stringConstants.POPUP_TYPE_RENAME
      ) {
        this.onRenameConfirmClick();
      }
    } else {
      this.props.showAlert({
        isOpen: true,
        title: "Error",
        type: "danger",
        msg: stringConstants.renameItemNameError,
      });
    }
  };

  /*--------------- ADD NEW COLLETION ----------------*/

  onAddNewCollectionClick = () => {
    this.setState({
      showNameInputPopup: true,
      inputPopupType: stringConstants.POPUP_TYPE_ADD_COLLECTION,
      inputPopupTitle: stringConstants.addCollectionPopupTitle,
      inputPopupSubtitle: stringConstants.addCollectionPopupSubtitle,
      inputPopupEnteredName: "",
    });
  };

  onAddCollectionConfirmClick = () => {
    //Add id and type to collection object
    var collectionObjectWithIds = addIdAndTypeInNewCollection(
      this.state.inputPopupEnteredName
    );

    //Save data updated with Ids in redux
    this.props.addNewCollection(collectionObjectWithIds);

    this.setState({
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",
    });
  };

  /*----------------- IMPORT COLLECTION ------------------*/

  onImportCollectionClicked = () => {
    this.setState({ showFileReader: true });

    // console.log("ALL COLLECTIONS : " + JSON.stringify(getState().workspace.collections));
    // console.log("ALL NODES : " + JSON.stringify(getState().workspace.nodes));
  };

  onFileRead = (jsonData) => {
    this.setState({ showFileReader: false });
    var collectionObject = {};
    try {
      collectionObject = JSON.parse(jsonData);
      collectionObject.name = collectionObject.info?.name;
      console.log("onFileRead : " + JSON.stringify(collectionObject, null, 4));

      // traverse tree here to add Ids
      var collectionObjectWithIds = addIdAndTypeInImportedCollection(
        collectionObject,
        "",
        true
      );

      //Save data updated with Ids in redux
      this.props.importCollection(collectionObjectWithIds);
    } catch (error) {
      // If JSON parse error occured
      console.log("onFileRead : catch " + error.message);
      this.props.showAlert({
        isOpen: true,
        title: "Error in reading file",
        type: "danger",
        msg: error.message,
      });
    }
  };

  onImportCancelClick = () => this.setState({ showFileReader: false });

  /*----------------- SYNC ALL DATA  ------------------*/

  onSyncDataClicked = () => {
    this.setState({ showSyncConfirmDialog: true });
    // pushAllCollectionsData();
    // pushAllNodesData();
  };

  onSyncCancelClick = () => {
    this.setState({ showSyncConfirmDialog: false });
  };

  onSyncConfirmClick = async () => {
    this.setState({ showSyncConfirmDialog: false });
    this.props.showLoader();

    // Do this for each collection item :
    // Check if collection exist in Realtime DB (isPresent)?
    // IF YES --> Check if user has write access to it, // YES then push  // NO then skip,
    // IF NO --> Add this collection in newCollectionIds, push it along with access control data.

    let newCreatedCollectionIds = [];
    let writeAccessCollectionIds = [];

    var writeIds = await getUsersWriteAccessIds(this.props.userEmail);
    console.warn("writeIds :" + writeIds);

    for (let index = 0; index < this.props.collections.length; index++) {
      var collectionId = this.props.collections[index].id;
      const isPresent = await checkIfCollectionExistInDB(collectionId);

      if (isPresent && writeIds.includes(collectionId)) {
        writeAccessCollectionIds.push(collectionId);
      } else if (!isPresent) {
        newCreatedCollectionIds.push(collectionId);
      }
    }

    console.warn("writeAccessCollectionIds :" + writeAccessCollectionIds);
    console.warn("newCreatedCollectionIds :" + newCreatedCollectionIds);

    await pushUsersWriteAccessCollections(writeAccessCollectionIds);
    await pushUsersNewCollections(newCreatedCollectionIds);

    this.props.hideLoader();
    this.props.showAlert({
      isOpen: true,
      title: "Success",
      type: "success",
      msg: stringConstants.syncDataSuccess,
    });
  };

  /*----------------- FETCH ALL DATA ------------------*/

  onFetchDataClicked = async () => {
    this.props.showLoader();

    var readIds = await getUsersReadAccessIds(this.props.userEmail);
    console.warn("readIds " + readIds);

    fetchUsersCollections(readIds, () => {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.fetchDataSuccess,
      });

      this.loadCollectionsInTreeView();
    });
  };

  /*-------------- RENAME ITEM ------------------*/

  onRenameItemClicked = (nodeId, nodeData) => {
    var nodeAllDetails = this.props.getNodeDetails(nodeId);
    this.setState({
      showNameInputPopup: true,
      inputPopupType: stringConstants.POPUP_TYPE_RENAME,
      inputPopupTitle: stringConstants.renameItemPopupTitle,
      inputPopupSubtitle: stringConstants.renameItemPopupSubTitle,
      inputPopupEnteredName: nodeAllDetails?.name ?? "",
      nodeData: nodeData,
    });
  };

  onRenameConfirmClick = () => {
    var renamedName = this.state.inputPopupEnteredName;
    var nodeId = this.state.nodeData.id;

    if (this.state.nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
      var collectionData = this.props.getCollectionDataFromId(nodeId);
      collectionData.name = renamedName;
      this.props.updateCollectionNodeOnSaveClick(nodeId, collectionData);
    } else {
      var nodeObject = this.props.getNodeDetails(nodeId);
      nodeObject.name = renamedName;
      nodeObject.title = renamedName;
      this.props.updateNodeDataOnSaveClick(nodeId, nodeObject);
      this.props.updateOpenTabsDataOnRename(nodeId, nodeObject);
    }

    this.setState({
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",
      nodeData: {},
    });
  };

  /*-------------- DUPLICATE ITEM ------------------*/

  onDuplicateItemClicked = (nodeId, nodeData) => {
    //nodeData is node's Data IN COLLECTION DB, thus only contains id and type

    if (nodeData.type === stringConstants.NODE_TYPE_API) {
      var collectionId = "";
      var parentConnectingLink = "";

      var originalNodeData = this.props.getNodeDetails(nodeId);
      var originalNodeConnectingLink = originalNodeData.connectingLink;
      if (originalNodeConnectingLink?.includes("--")) {
        var firstIndex = originalNodeConnectingLink.indexOf("--");
        var lastIndex = originalNodeConnectingLink.lastIndexOf("--");
        collectionId = originalNodeConnectingLink.substr(0, firstIndex);
        parentConnectingLink = originalNodeConnectingLink.substr(0, lastIndex);
      }

      var newNodeId = generateRandomNodeId();
      var newNodeConnectingLink = parentConnectingLink + "--" + newNodeId;

      // console.log("DUPLICATE : " );
      // console.log({ collectionId });
      // console.log({ parentConnectingLink });
      // console.log({ newNodeId });
      // console.log({ newNodeConnectingLink });

      var duplicateNodeDataForNodesDB = { ...originalNodeData };
      duplicateNodeDataForNodesDB["id"] = newNodeId;
      duplicateNodeDataForNodesDB["connectingLink"] = newNodeConnectingLink;
      duplicateNodeDataForNodesDB["name"] = originalNodeData.name + " Copy";
      duplicateNodeDataForNodesDB["title"] = originalNodeData.title + " Copy";
      duplicateNodeDataForNodesDB["updateTimestamp"] = moment().format(
        stringConstants.TIMESTAMP_FORMAT
      );
      console.log("nodesDB:" + JSON.stringify(duplicateNodeDataForNodesDB));

      var duplicateNodeDataForCollectionDB = { ...nodeData };
      duplicateNodeDataForCollectionDB["id"] = newNodeId;
      console.log("collDB:" + JSON.stringify(duplicateNodeDataForCollectionDB));

      // Save node in nodes DB
      this.props.saveNode(newNodeId, duplicateNodeDataForNodesDB);

      // Save node in collections DB
      this.props.addNewNodeInCollectionTree(
        parentConnectingLink,
        collectionId,
        duplicateNodeDataForCollectionDB
      );

      // Refresh treeview
      this.loadCollectionsInTreeView();
    }
  };

  /*-------------- ADD FOLDER  ------------------*/
  onAddFolderClicked = (nodeId, nodeData) => {
    this.setState({
      showNameInputPopup: true,
      inputPopupType: stringConstants.POPUP_TYPE_ADD_FOLDER,
      inputPopupTitle: stringConstants.addFolderPopupTitle,
      inputPopupSubtitle: stringConstants.addFolderPopupSubtitle,
      inputPopupEnteredName: "",
      newItemsParentNodeId: nodeId,
    });
  };

  onAddFolderConfirmClick = () => {
    var parentNodeId = this.state.newItemsParentNodeId;
    var parentNodeDetails = this.props.getNodeDetails(parentNodeId);
    console.log("parentNodeDetails : " + JSON.stringify(parentNodeDetails));

    var collectionId = "";
    var parentConnectingLink = "";

    if (parentNodeDetails?.connectingLink?.includes("--")) {
      parentConnectingLink = parentNodeDetails.connectingLink;
      var index = parentConnectingLink.indexOf("--");
      collectionId = parentConnectingLink.substr(0, index);
    } else {
      parentConnectingLink = parentNodeId;
      collectionId = parentNodeId;
    }
    console.log("collectionId :  " + collectionId);
    console.log("parentConnectingLink : " + parentConnectingLink);

    //Add id and type to collection object (saveNode is called inside this function!!)
    var folderDataForCollectionDB = addIdAndTypeInNewFolder(
      this.state.inputPopupEnteredName,
      parentConnectingLink
    );

    console.log(
      "NEW FOLDER WithIdAndType : " + JSON.stringify(folderDataForCollectionDB)
    );

    //Save data updated with Ids in redux ( in tree )
    this.props.addNewNodeInCollectionTree(
      parentConnectingLink, //Connecting Link of node on which 'add folder' is clicked
      collectionId, //Collection id for node on which 'add folder' is clicked
      folderDataForCollectionDB
    );

    this.setState({
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",
      newItemsParentNodeId: "",
    });
  };

  /*-------------- ADD NEW REQUEST  ------------------*/
  onAddNewRequestClicked = (nodeId, nodeData) => {
    this.setState({
      showNameInputPopup: true,
      inputPopupType: stringConstants.POPUP_TYPE_ADD_NEW_REQUEST,
      inputPopupTitle: stringConstants.addNewRequestPopupTitle,
      inputPopupSubtitle: stringConstants.addNewRequestPopupSubtitle,
      inputPopupEnteredName: "",
      newItemsParentNodeId: nodeId,
    });
  };

  onAddNewRequestConfirmClick = () => {
    var newNodeId = generateRandomNodeId();

    var parentNodeId = this.state.newItemsParentNodeId;
    var parentNodeDetails = this.props.getNodeDetails(parentNodeId);
    console.log("parentNodeDetails : " + JSON.stringify(parentNodeDetails));

    var collectionId = "";
    var parentConnectingLink = "";

    if (parentNodeDetails?.connectingLink?.includes("--")) {
      parentConnectingLink = parentNodeDetails.connectingLink;
      var index = parentConnectingLink.indexOf("--");
      collectionId = parentConnectingLink.substr(0, index);
    } else {
      parentConnectingLink = parentNodeId;
      collectionId = parentNodeId;
    }
    console.log("collectionId :  " + collectionId);
    console.log("parentConnectingLink : " + parentConnectingLink);

    var newRequestDataForNodesDB = {
      id: newNodeId,
      name: this.state.inputPopupEnteredName,
      title: this.state.inputPopupEnteredName,
      type: stringConstants.NODE_TYPE_API,
      connectingLink: parentConnectingLink + "--" + newNodeId,

      request: {
        method: "",
        url: { raw: "" },
        header: [],
        body: {
          mode: stringConstants.RAW_DATA,
        },
      },
      response: {},
      responseContentType: null,
      isUnsavedNewTab: false,
      isUpdated: false,
      updateTimestamp: moment().format(stringConstants.TIMESTAMP_FORMAT),
    };

    // For Nodes DB - save all data of node (request, response etc) along with id, type and connectingLink
    this.props.saveNode(newNodeId, newRequestDataForNodesDB);

    // For collections DB, save only id, title, type and connectingLink
    var newRequestDataForCollectionsDB = {
      id: newNodeId,
      type: stringConstants.NODE_TYPE_API,
    };

    // For Collections DB - Save data with id and type only
    this.props.addNewNodeInCollectionTree(
      parentConnectingLink, //Connecting Link of node on which 'add folder' is clicked
      collectionId, //Collection id for node on which 'add folder' is clicked
      newRequestDataForCollectionsDB
    );

    this.setState({
      showNameInputPopup: false,
      inputPopupType: "",
      inputPopupTitle: "",
      inputPopupSubtitle: "",
      inputPopupEnteredName: "",
      newItemsParentNodeId: "",
    });
  };

  /*-------------- SYNC ITEM ------------------*/
  onSyncItemClicked = async (nodeId, nodeData) => {
    console.log("SIDEBAR Container :  onSyncItemClicked : " + nodeId);
    this.props.showLoader();
    var nodeItem = {}; // THIS ITEM will be pushed to server!!
    var collectionId = ""; // This is for checking if collection present on server or not

    var writeIds = await getUsersWriteAccessIds(this.props.userEmail);
    console.warn("writeIds :" + writeIds);

    if (nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
      nodeItem = this.props.getCollectionDataFromId(nodeId);
      collectionId = nodeId;
    } else {
      nodeItem = this.props.getNodeDetails(nodeId);
      var index = nodeItem.connectingLink?.indexOf("--");
      if (index !== -1 || index !== null || index !== undefined) {
        collectionId = nodeItem.connectingLink.substr(0, index);
      }
    }

    console.warn("nodeItem :" + JSON.stringify(nodeItem));
    console.warn("collectionId :" + collectionId);

    const isPresent = await checkIfCollectionExistInDB(collectionId);
    console.log("isPresent " + collectionId + " --> " + isPresent);

    // #1 -- collection present in DB and user has write access
    if (isPresent && writeIds.includes(collectionId)) {
      if (nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
        var writeAccessCollectionIds = [collectionId];
        await pushUsersWriteAccessCollections(writeAccessCollectionIds);

        this.props.hideLoader();
        this.props.showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: stringConstants.syncItemSuccess,
        });
      } else {
        updateSingleNode(nodeId, nodeItem, collectionId, (error) => {
          this.props.hideLoader();
          console.warn("updateSingleNode CALLBACK");
          if (error) {
            this.props.showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: error,
            });
          } else {
            this.props.showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: stringConstants.syncItemSuccess,
            });
          }
        });
      }
    }

    // #2 -- collection present in DB and user DON'T have write access
    else if (isPresent && !writeIds.includes(collectionId)) {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.syncItemErrorTitle,
        type: "danger",
        msg: stringConstants.syncItemErrorDescription,
      });
    }

    // #3 -- collection NOT present in DB and sync item clicked on folder or API
    else if (
      !isPresent &&
      nodeData.type !== stringConstants.NODE_TYPE_COLLECTION
    ) {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.syncItemErrorTitle,
        type: "danger",
        msg: stringConstants.collectionNotAvailableOnServerError,
      });
    }

    // #4 -- collection NOT present in DB and sync item clicked on collection itself
    else if (
      !isPresent &&
      nodeData.type === stringConstants.NODE_TYPE_COLLECTION
    ) {
      var newCollectionIds = [];
      newCollectionIds.push(collectionId);

      await pushUsersNewCollections(newCollectionIds);

      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.syncItemSuccess,
      });
    }
  };

  /*-------------- DELETE ITEM ------------------*/
  onDeleteItemClicked = async (nodeId, nodeData) => {
    this.props.showLoader();

    var collectionId = "";
    if (nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
      collectionId = nodeId;
    } else {
      var nodeItem = this.props.getNodeDetails(nodeId);
      var index = nodeItem.connectingLink?.indexOf("--");
      if (index !== -1 || index !== null || index !== undefined) {
        collectionId = nodeItem.connectingLink.substr(0, index);
      }
    }
    console.warn("collectionId :" + collectionId);

    const isPresent = await checkIfCollectionExistInDB(collectionId);
    console.log("isPresent " + collectionId + " --> " + isPresent);

    var writeIds = await getUsersWriteAccessIds(this.props.userEmail);
    console.warn("writeIds :" + writeIds);

    this.props.hideLoader();

    // #1 -- collection NOT present in DB, it is local collection only (only delete items)
    if (!isPresent) {
      this.deleteItemAndSyncToServer(nodeData, collectionId, false);
    }

    // #2 -- collection present in DB and user has write access (delete items and sync them)
    else if (isPresent && writeIds.includes(collectionId)) {
      this.deleteItemAndSyncToServer(nodeData, collectionId, true);
    }

    // #3 -- collection present in DB and user DON'T have write access (show error)
    else if (isPresent && !writeIds.includes(collectionId)) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.deleteItemErrorTitle,
        type: "danger",
        msg: stringConstants.syncItemErrorDescription,
      });
    }
  };

  deleteItemAndSyncToServer = (nodeData, collectionId, syncToServer) => {
    // Delete collection or node
    if (nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
      this.props.deleteCollection(nodeData.id);
    } else {
      this.props.deleteNode(nodeData.id);
    }

    // Sync collection or node if specified
    if (syncToServer === true) {
      if (nodeData.type === stringConstants.NODE_TYPE_COLLECTION) {
        var writeIds = [];
        writeIds.push(nodeData.id);
        pushUsersWriteAccessCollections(writeIds);
      } else {
        updateSingleNode(nodeData.id, nodeData, collectionId, (error) => {
          this.props.hideLoader();
          console.warn("updateSingleNode CALLBACK");
          if (error) {
            console.log("ERROR post delete: while syncing item");
          } else {
            console.log("SUCCESS post delete: syncing item success");
          }
        });
      }
    }
  };

  /*-------------- SHARE COLLECTION ------------------*/
  onShareItemClicked = async (nodeId, nodeData) => {
    this.props.showLoader();

    var adminIds = await getUsersAdminAccessIds(this.props.userEmail);
    console.warn("adminIds : " + JSON.stringify(adminIds));

    //Since "share item" option is only available on node_type=collection , nodeId is always the CollectionId
    const isPresent = await checkIfCollectionExistInDB(nodeId);
    console.log("isPresent " + nodeId + " --> " + isPresent);

    // #1 -- collection present in DB and user has write access
    if (isPresent && adminIds.includes(nodeId)) {
      var userWiseData = await getPermissionsDataForCollection(nodeId);
      console.warn("userWiseData : " + JSON.stringify(userWiseData));

      this.props.hideLoader();
      this.setState({
        shareCollectionUsersList: userWiseData,
        showShareCollectionPopup: true,
        shareCollectionName: nodeData.name,
        shareCollectionId: nodeId,
      });
    }

    // #2 -- collection present in DB and user DON'T have admin access
    else if (isPresent && !adminIds.includes(nodeId)) {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.shareCollectionErrorTitle,
        type: "danger",
        msg: stringConstants.shareCollectionErrorDescription,
      });
    }

    // #3 -- collection NOT present in DB
    else if (!isPresent) {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.shareCollectionErrorTitle,
        type: "danger",
        msg: stringConstants.collectionNotAvailableOnServerError,
      });
    }
  };

  onUsersListDataChange = (newData, email) => {
    let userListOld = this.state.shareCollectionUsersList;
    let userListNew = [];

    userListOld.forEach((user) => {
      if (String(user.userEmail) === String(email)) {
        userListNew.push(newData);
      } else {
        userListNew.push(user);
      }
    });
    this.setState({ shareCollectionUsersList: userListNew });
  };

  onUsersListAddItemClick = () => {
    let newUserList = this.state.shareCollectionUsersList;

    let emptyItemIndex = newUserList.findIndex(
      (user) => String(user.userEmail) === ""
    );

    if (parseInt(emptyItemIndex) === -1) {
      //no empty email item found, thus insert new item
      let userItem = { userEmail: "", read: false, write: false, admin: false };
      newUserList.push(userItem);
      this.setState({ shareCollectionUsersList: newUserList });
    } else {
      // already a new empty item found
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: stringConstants.emptyListItemAlreadyFound,
      });
    }
  };

  onUsersListDeleteItemClick = () => {
    //TODO - no delete option yet!
  };

  onShareCollectionConfirmClick = async () => {
    this.setState({ showShareCollectionPopup: false });

    let newUsersList = this.state.shareCollectionUsersList;
    console.log("new users list : " + JSON.stringify(newUsersList, null, 5));

    for (let index = 0; index < newUsersList.length; index++) {
      const user = newUsersList[index];
      await uploadUserAccessDataInDB(
        user.userEmail,
        this.state.shareCollectionId,
        user.read,
        user.write,
        user.admin
      );
    }
  };

  onShareCollectionCancelClick = () => {
    this.setState({ showShareCollectionPopup: false });
  };

  render() {
    return (
      <WorkspaceSideBarPanel
        allCollectionsArray={this.state.allCollections}
        // Common Popup Related Functions
        showNameInputPopup={this.state.showNameInputPopup}
        inputPopupTitle={this.state.inputPopupTitle}
        inputPopupSubtitle={this.state.inputPopupSubtitle}
        inputPopupEnteredName={this.state.inputPopupEnteredName}
        onInputPopupEnteredNameChange={this.onInputPopupEnteredNameChange}
        onInputPopupConfirmClick={this.onInputPopupConfirmClick}
        onInputPopupCancelClick={this.onInputPopupCancelClick}
        // Add New collection
        onAddNewCollectionClick={this.onAddNewCollectionClick}
        onAddCollectionConfirmClick={this.onAddCollectionConfirmClick}
        // Import Collection
        onImportCollectionClicked={this.onImportCollectionClicked}
        showFileReader={this.state.showFileReader}
        onFileRead={this.onFileRead}
        onImportCancelClick={this.onImportCancelClick}
        // Sync ALL DATA
        onSyncDataClicked={this.onSyncDataClicked}
        showSyncConfirmDialog={this.state.showSyncConfirmDialog}
        onSyncConfirmClick={this.onSyncConfirmClick}
        onSyncCancelClick={this.onSyncCancelClick}
        // Fetch ALL DATA
        onFetchDataClicked={this.onFetchDataClicked}
        // Rename Item
        onRenameItemClicked={this.onRenameItemClicked}
        onRenameConfirmClick={this.onRenameConfirmClick}
        // Duplicate item
        onDuplicateItemClicked={this.onDuplicateItemClicked}
        // Add Folder
        onAddFolderClicked={this.onAddFolderClicked}
        onAddFolderConfirmClick={this.onAddFolderConfirmClick}
        //Add New Request
        onAddNewRequestClicked={this.onAddNewRequestClicked}
        onAddNewRequestConfirmClick={this.onAddNewRequestConfirmClick}
        // Sync Item
        onSyncItemClicked={this.onSyncItemClicked}
        // Delete Item
        onDeleteItemClicked={this.onDeleteItemClicked}
        // Share Collection
        onShareItemClicked={this.onShareItemClicked}
        showShareCollectionPopup={this.state.showShareCollectionPopup}
        shareCollectionName={this.state.shareCollectionName}
        shareCollectionUsersList={this.state.shareCollectionUsersList}
        onUsersListDataChange={this.onUsersListDataChange}
        onUsersListAddItemClick={this.onUsersListAddItemClick}
        onUsersListDeleteItemClick={this.onUsersListDeleteItemClick}
        onShareCollectionConfirmClick={this.onShareCollectionConfirmClick}
        onShareCollectionCancelClick={this.onShareCollectionCancelClick}
      />
    );
  }
}

WorkspaceSideBarContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    collections: state.workspace.collections,
    timestamp: state.workspace.timestamp,
    userEmail: state.auth.userEmail,
    nodes: state.workspace.nodes,
  };
};

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
  showAlert,
  addNewCollection,
  addNewNodeInCollectionTree,
  importCollection,
  getCollectionDataFromId,
  getNodeDetails,
  deleteCollection,
  deleteNode,
  updateNodeDataOnSaveClick,
  updateCollectionNodeOnSaveClick,
  updateOpenTabsDataOnRename,
  saveNode,
})(WorkspaceSideBarContainer);
