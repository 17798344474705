import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Dropdown,
  Input,
  IconButtonView,
  InputWithHighlighter,
} from "../../../controlsNew";
import { IoTrashOutline } from "react-icons/io5";
import {
  headerItemKeys,
  headerItemValues,
} from "../../../../constants/headerItemKeyValues";
import "./ListItemHeadersStyles.scss";

const ListItemHeaders = ({
  dataObject, //{ key, value, desription, checked }
  onDataChange,
  onDeleteItemClick,
  styles,
}) => {
  function onKeyChange(event) {
    let newKey = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.key = newKey;
    onDataChange(newDataObject, dataObject.key);
  }

  function onKeyDropdownSelect(event) {
    let newKey = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.key = newKey;
    newDataObject.value = "";
    onDataChange(newDataObject, dataObject.key);
  }

  function onValueChange(value) {
    let newValue = value; // for InputWithHighlighter
    let newDataObject = { ...dataObject };
    newDataObject.value = newValue;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onValueDropdownSelect(event) {
    let newValue = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.value = newValue;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onDescriptionChange(event) {
    let newDescription = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.description = newDescription;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onCheckedChange(event) {
    let newDataObject = { ...dataObject };
    newDataObject.checked = !dataObject.checked;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onDeleteClicked(event) {
    console.log("onDeleteClicked");
    onDeleteItemClick(dataObject.key);
  }

  return (
    <div className="dynamic-list-item">
      <div className="check-mark">
        <Checkbox
          checked={dataObject.checked}
          onCheckedChange={onCheckedChange}
        />
      </div>
      <div className="input-wide">
        <Input
          noOutline
          value={dataObject.key}
          placeholder={"Key"}
          onChange={onKeyChange}
        />

        <Dropdown
          label=""
          value=""
          data={headerItemKeys}
          onSelect={onKeyDropdownSelect}
          arrowOnly
        />
      </div>

      <div className="input-wide">
        <div className="floating-placeholder">
          {/* Need this, as InputWithHighlighter does not support placeholder with custom styling */}
          <text>{dataObject.value === "" ? "Value" : ""}</text>
        </div>

        <InputWithHighlighter
          noOutline
          value={dataObject.value}
          placeholder={"Value"}
          onChange={onValueChange}
        />

        <Dropdown
          label=""
          value=""
          data={headerItemValues}
          onSelect={onValueDropdownSelect}
          arrowOnly
        />
      </div>

      <div className="input-wide">
        <Input
          noOutline
          value={dataObject.description}
          placeholder={"Description"}
          onChange={onDescriptionChange}
        />
      </div>

      <div className="delete-mark">
        <IconButtonView
          icon={<IoTrashOutline size={15} color="#000" />}
          styles={{ width: "10px" }}
          onClick={onDeleteClicked}
        />
      </div>
    </div>
  );
};

ListItemHeaders.propTypes = {
  dataObject: PropTypes.object,
  onDataChange: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCheckedChange: PropTypes.func,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ListItemHeaders.defaultProps = {
  disabled: false,
};

export default ListItemHeaders;
