import { createStore } from "redux";
import { globals } from "../globals";
import { rootReducer } from "./rootReducer";
import { applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "context", "workspace", "environment"],
  blacklist: ["alert"],
};

globals.history = createBrowserHistory();

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(globals.history)
);

const middleware = applyMiddleware(thunk);
const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };

globals.store = store;

export const getState = () => {
  return globals.store.getState();
};

export const getStore = () => {
  return globals.store;
};

export const { history } = globals.history;
