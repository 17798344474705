import React from "react";
import { Header } from "../../common";
import {
  TabViewDynamic,
  PopupDialog,
  ButtonView,
  ToggleButtons,
  TextArea,
  Input,
} from "../../controlsNew";
import { FiCoffee, FiUser } from "react-icons/fi";
import logo from "../../../assets/images/logo-ssi-new.png";
import RequestContainer from "../Workspace/RequestContainer/RequestContainer";
import ResponseContainer from "../Workspace/ResponseContainer/ResponseContainer";
import WorkspaceSideBarContainer from "../Workspace/WorkspaceSideBarContainer/WorkspaceSideBarContainer";
import EnvContentContainer from "../Env/EnvContentContainer/EnvContentContainer";
import EnvSideBarContainer from "../Env/EnvSideBarContainer/EnvSideBarContainer";
import { stringConstants } from "../../../constants/stringConstants";
import { getEnvironmentDataFromId } from "../../../store/environment/environmentActions";
import "./HomeStyles.scss";

const HomePanel = (props) => {
  // console.log("HomePanel PROPS: " + JSON.stringify(props));

  // console.log(
  //   "HomePanel : selectedEnvId :::: " + JSON.stringify(props.selectedEnvId)
  // );

  const renderSidebarContent = () => {
    if (props.selectedContext === stringConstants.CONTEXT_APIS) {
      return <WorkspaceSideBarContainer />;
    } else if (props.selectedContext === stringConstants.CONTEXT_ENV) {
      return <EnvSideBarContainer />;
    } else return null;
  };

  const renderLoginButtonPlaceHolder = () => {
    return (
      <div className="content-container">
        <div className="empty">
          <div className="p-3">
            <FiUser size={25} color="#5d5d5d" />
          </div>

          <text>
            Please <em> Login </em> with your smartsight email to access
            <em> SSI-Checkpoint!</em>
          </text>

          <div className="mt-2">
            <ButtonView
              title={stringConstants.login}
              onClick={props.onLoginClicked}
              styles="secondary medium"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPostLoginContent = () => {
    return (
      <div>
        {props.selectedContext === stringConstants.CONTEXT_APIS
          ? renderApiAndCollectionContent()
          : props.selectedContext === stringConstants.CONTEXT_ENV
          ? renderEnvironmentAndVariablesContent()
          : null}

        {props.showLogoutConfirmDialog === true ? (
          <PopupDialog
            title={stringConstants.logoutPopupTitle}
            message={stringConstants.logoutPopupSubTitle}
            onConfirmClick={props.onLogoutConfirmClick}
            onCancelClick={props.onLogoutCancelClick}
          />
        ) : null}
      </div>
    );
  };

  const renderApiAndCollectionContent = () => {
    return (
      <div className="inner-content-container">
        <div className="tab-and-env-container mt-1">
          <ButtonView
            title={"ENV"}
            // icon={<AiOutlineEye size={12} color="#000" />}
            styles={
              props.selectedEnvId === stringConstants.NO_ENV_SELECTED_ID
                ? "grey-outline smaller"
                : "success-outline smaller"
            }
            onClick={() => {}}
            tooltip={
              props.selectedEnvId === stringConstants.NO_ENV_SELECTED_ID
                ? "No Environment Selected"
                : getEnvironmentDataFromId(props.selectedEnvId).name
            }
          />

          <TabViewDynamic
            tabOptions={props.tabOptions}
            currentTabId={props.currentTabId}
            onImportCurlClicked={props.onImportCurlClicked}
          />
        </div>

        {props.noTabsOpen === true ? (
          renderNoTabsOpenPlaceHolder()
        ) : (
          <>
            <RequestContainer />
            <ResponseContainer />
          </>
        )}

        {props.showImportCurlPopup === true ? (
          <PopupDialog
            title={stringConstants.importCurlPopupTitle}
            message={stringConstants.importCurlPopupSubtitle}
            onConfirmClick={props.onImportCurlConfirmClick}
            onCancelClick={props.onImportCurlCancelClick}
            renderContents={renderImportCurlPopupContent()}
            widePopup
          />
        ) : null}
      </div>
    );
  };

  function renderImportCurlPopupContent() {
    return (
      <div className="curl-popup">
        <div className="pt-3">
          <Input
            value={props.importedCurlName}
            placeholder={stringConstants.importCurlPlaceholder1}
            onChange={props.onCurlNameChange}
            showBottomline
          />
        </div>
        <div className="my-1 pt-3">
          <TextArea
            value={props.importedCurl}
            placeholder={stringConstants.importCurlPlaceholder2}
            onChange={props.onCurlChange}
            style={{ marginLeft: "-5px" }}
            largerBox
          />
        </div>
      </div>
    );
  }

  const renderNoTabsOpenPlaceHolder = () => {
    return (
      <div className="empty">
        <div className="p-3">
          <FiCoffee size={25} color="#5d5d5d" />
        </div>

        <text>
          Select an <em> API</em> or open a <em>New Tab</em> to get started!
        </text>
      </div>
    );
  };

  const renderEnvironmentAndVariablesContent = () => {
    return (
      <div className="inner-content-container">
        {props.isEnvClicked ? (
          <EnvContentContainer />
        ) : (
          renderNoEnvSelectedPlaceHolder()
        )}
      </div>
    );
  };

  const renderNoEnvSelectedPlaceHolder = () => {
    return (
      <div className="empty">
        <div className="p-3">
          <FiCoffee size={25} color="#5d5d5d" />
        </div>

        <text>
          Select an <em> Environment</em> to see all <em> Variables </em>
          associated with it!
        </text>
      </div>
    );
  };

  return (
    <div className="home-container">
      <div className="headers-container">
        <div className="navbar-brand">
          <div className="logo-bg">
            <img className="logo-img" src={logo} />
          </div>
        </div>

        <Header
          userName={props.userName}
          isUserLoggedIn={props.isUserLoggedIn}
          // onLoginClicked={props.onLoginClicked}
          onLogoutClicked={props.onLogoutClicked}
          // onImportCollectionClicked={props.onImportCollectionClicked}
          // onSyncDataClicked={props.onSyncDataClicked}
          // onFetchDataClicked={props.onFetchDataClicked}
        />
      </div>

      <div className="full-content-container">
        <div className="sidebar-container">
          <div className="sidepanel-container">
            <div className="toggle-button-container p-1">
              {props.isUserLoggedIn ? (
                <ToggleButtons
                  initialValue={props.selectedContext}
                  data={props.toggleButtonsData}
                  onItemSelected={props.onToggleButtonSelected}
                />
              ) : null}
            </div>

            <div className="tree-container">
              {props.isUserLoggedIn ? renderSidebarContent() : null}
            </div>
          </div>
        </div>

        <div className="content-container">
          {props.isUserLoggedIn
            ? renderPostLoginContent()
            : renderLoginButtonPlaceHolder()}
        </div>
      </div>
    </div>
  );
};

export default HomePanel;
