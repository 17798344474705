import moment from "moment";
import { showAlert } from "../alert/alertActions";
import { getState, getStore } from "../configure/configureStore";
import { stringConstants } from "../../constants/stringConstants";
import { getCollectionDataFromId } from "../workspace/workspaceActions";

export const actionTypes = {
  ADD_NEW_ENV: "ADD_NEW_ENV",
  ADD_NEW_VAR: "ADD_NEW_VAR",
  EDIT_ENV_NAME: "EDIT_ENV_NAME",
  DELETE_ENV: "DELETE_ENV",
  GET_VARIABLES_FOR_ENV: "GET_VARIABLES_FOR_ENV",
  SET_CLICKED_ENV_ID: "SET_CLICKED_ENV_ID",
  RESET_CLICKED_ENV_ID: "RESET_CLICKED_ENV_ID",
  SET_SELECTED_ENV_ID: "SET_SELECTED_ENV_ID",
  RESET_SELECTED_ENV_ID: "RESET_SELECTED_ENV_ID",
  REPLACE_ALL_ENV_DATA: "REPLACE_ALL_ENV_DATA",
  RESET_ENV_DATA: "RESET_ENV_DATA",
};

export const globalVariablesEnv = {
  id: stringConstants.GLOBAL_VARIABLES_ENV_ID,
  name: "Global Variables",
  collectionId: null,
  selected: true,
};

/* ----------------  Environment -------------------- */

export function setupGlobalEnvironment() {
  let existingEnvironments = [];
  existingEnvironments = getState().environment.environments;

  var index = existingEnvironments.findIndex((item) => {
    if (item.id === globalVariablesEnv.id) {
      return true;
    }
    return false;
  });

  if (index === -1) {
    existingEnvironments.push(globalVariablesEnv);
  }
  console.log("Global Env Added! : " + existingEnvironments);

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_ENV,
      payload: {
        environments: existingEnvironments,
        timestamp: moment().toString(),
      },
    });
  };
}

export function addNewEnvironment(envObject) {
  let existingEnvironments = [];
  existingEnvironments = getState().environment.environments;
  existingEnvironments.push(envObject);

  console.log("ENV ADDED : " + JSON.stringify(existingEnvironments));

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_ENV,
      payload: {
        environments: existingEnvironments,
        timestamp: moment().toString(),
      },
    });
  };
}

export function editEnvironmentName(envId, newName) {
  let existingEnvironments = [];
  existingEnvironments = getState().environment.environments;

  existingEnvironments.forEach((envItem) => {
    if (envItem.id === envId) {
      envItem.name = newName;
    }
  });

  console.log("ENV Updated" + JSON.stringify(existingEnvironments));

  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_ENV_NAME,
      payload: {
        environments: existingEnvironments,
        timestamp: moment().toString(),
      },
    });
  };
}

export function getEnvironmentDataFromId(envId) {
  let existingEnvironments = [];
  existingEnvironments = getState().environment.environments;

  // console.log("existingEnvironments" + JSON.stringify(existingEnvironments));
  let selectedEnvData = {};
  var collectionId = null;

  existingEnvironments.forEach((envItem) => {
    if (envItem.id === envId) {
      selectedEnvData = envItem;
      collectionId = envItem.collectionId;
    }
  });

  // Add collection name param in selectedEnvData before returning it
  var collectionData = getStore().dispatch(
    getCollectionDataFromId(collectionId)
  );
  selectedEnvData["collectionName"] = collectionData?.name ?? "";
  console.log("getEnvironmentDataFromId : " + JSON.stringify(selectedEnvData));
  return selectedEnvData;
}

export function deleteEnvironment(envId) {
  let existingEnvironments = [];
  existingEnvironments = getState().environment.environments;

  var index = existingEnvironments.findIndex((envItem) => {
    if (envItem.id === envId) {
      return true;
    } else return false;
  });

  if (index !== -1) {
    //Delete this env from redux
    existingEnvironments.splice(index, 1);

    //Reset clicked Env Id
    getStore().dispatch(resetClickedEnvironmentId());

    //Reset selected Env Id (if this env was selected)
    if (getState().environment.selectedEnvId === envId) {
      getStore().dispatch(resetSelectedEnvironmentId());
    }
  }

  //Delete all variables associated with this env
  var variablesOfEnv = getVariablesForEnvironment(envId);
  variablesOfEnv.forEach((item) => {
    getStore().dispatch(deleteVariable(item));
  });

  console.log("Post delete ENV : " + JSON.stringify(existingEnvironments));
  console.log(
    "Post delete VAR: " + JSON.stringify(getState().environment.variables)
  );

  setTimeout(() => {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.deleteEnvironmentSuccess,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ENV,
      payload: {
        environments: existingEnvironments,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ----------------  Variables -------------------- */

export function saveVariable(variableObject) {
  let existingVariables = [];
  existingVariables = getState().environment.variables;

  var index = existingVariables.findIndex((variable) => {
    if (
      String(variable.name) === String(variableObject.name) &&
      String(variable.environmentId) === String(variableObject.environmentId)
    ) {
      // This variable exists in DB
      return true;
    }
    return false;
  });

  // console.warn("saveVariable : FOUND INDEX  -->  ", index);

  if (index !== -1) {
    existingVariables.splice(index, 1, variableObject);
  } else {
    existingVariables.push(variableObject);
  }

  console.log("VAR post saveVariable : " + JSON.stringify(existingVariables));

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_VAR,
      payload: {
        variables: existingVariables,
        timestamp: moment().toString(),
      },
    });
  };
}

export function deleteVariable(variableObject) {
  let existingVariables = [];
  existingVariables = getState().environment.variables;

  var index = existingVariables.findIndex((variable) => {
    if (
      variable.name === variableObject.name &&
      variable.envId === variableObject.envId
    ) {
      // This variable exists in DB
      return true;
    }
    return false;
  });

  if (index !== -1) {
    existingVariables.splice(index, 1);
  }

  // console.log("VAR post deleteVariable : " + JSON.stringify(existingVariables));

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_VAR,
      payload: {
        variables: existingVariables,
        timestamp: moment().toString(),
      },
    });
  };
}

export function getVariablesForEnvironment(envId) {
  let existingVariables = [];
  existingVariables = getState().environment.variables;
  let selectedEnvVariables = [];

  existingVariables.forEach((variable) => {
    if (variable.environmentId === envId) {
      selectedEnvVariables.push(variable);
    }
  });

  // console.log("Env Variables" + JSON.stringify(selectedEnvVariables));
  return selectedEnvVariables;
}

/* -------------------  Environment Clicked ----------------------- */

export function setClickedEnvironmentId(envId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CLICKED_ENV_ID,
      payload: {
        isEnvClicked: true,
        clickedEnvId: envId,
        timestamp: moment().toString(),
      },
    });
  };
}

export function resetClickedEnvironmentId() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_CLICKED_ENV_ID,
      payload: {
        isEnvClicked: false,
        clickedEnvId: null,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ------------  Environment Selected / Deselected --------------- */

export function setSelectedEnvironmentId(envId) {
  let allEnvironments = [];
  allEnvironments = getState().environment.environments;

  allEnvironments.forEach((item) => {
    if (item.id === envId || item.id === globalVariablesEnv.id) {
      item.selected = true;
    } else {
      item.selected = false;
    }
  });

  console.log("Updated environments : " + JSON.stringify(allEnvironments));

  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_ENV_ID,
      payload: {
        environments: allEnvironments,
        selectedEnvId: envId,
        timestamp: moment().toString(),
      },
    });
  };
}

export function resetSelectedEnvironmentId() {
  let allEnvironments = [];
  allEnvironments = getState().environment.environments;

  allEnvironments.forEach((item) => {
    //Except "Golbals", mark selected = false for all other environments
    if (item.id !== globalVariablesEnv.id) {
      item.selected = false;
    }
  });

  console.log("Updated environments : " + JSON.stringify(allEnvironments));

  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SELECTED_ENV_ID,
      payload: {
        environments: allEnvironments,
        selectedEnvId: stringConstants.NO_ENV_SELECTED_ID,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ---------------  Replace All Env and Var Data ----------------- */

export function replaceAllEnvAndVariablesData(
  environmentArray,
  variablesArray
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPLACE_ALL_ENV_DATA,
      payload: {
        environments: environmentArray,
        variables: variablesArray,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ----------------------  Reset All Data ---------------------- */

export function resetAllEnvData() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_ENV_DATA,
      payload: {
        environments: [],
        variables: [],
        selectedEnvId: stringConstants.NO_ENV_SELECTED_ID,
        isEnvClicked: false,
        clickedEnvId: null,
        timestamp: null,
      },
    });
  };
}
