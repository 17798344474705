import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./TextAreaStyles.scss";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
const inputRef = React.createRef();

const TextArea = ({
  value,
  onChange,
  label,
  error,
  tabIndex = 0,
  className = "",
  disabled,
  size = "",
  setFocus = 0,
  isrequired = false,
  row = 3,
  largerBox,
  ...props
}) => {
  useEffect(() => {
    if (setFocus) {
      inputRef.current.focus();
    }
  }, [setFocus]);

  return (
    <div className={`textarea-wrapper  ${largerBox ? "maximum-area" : ""}`}>
      <span className={`label text-muted`}>
        {label} {isrequired && <span className="text-danger">*</span>}
      </span>
      <textarea
        rows={row}
        ref={inputRef}
        tabIndex={tabIndex}
        disabled={disabled}
        className={`
          textarea
          ${error && !disabled ? "textarea_error" : ""}
          ${disabled ? "disabled" : ""}
          ${size}
          ${largerBox ? "maximum-area" : ""}
          `}
        value={value}
        onChange={onChange}
        {...props}
      />

      <div className={`error`}>{error && !disabled ? error : ""}</div>
    </div>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

TextArea.defaultProps = {
  type: "text",
};

export default TextArea;
