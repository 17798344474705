import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import RequestPanel from "./RequestPanel";
import moment from "moment";
import {
  updateCurrentTabData,
  makeApiCallAndGetResponse,
  updateNodeDataOnSaveClick,
  saveNode,
  addNewNodeInCollectionTree,
  getNodeDetails,
  getNodeDetailsWithRef,
  getCollectionDataFromId,
  updateApiResponseAsLoading,
} from "../../../../store/workspace/workspaceActions";
import { showAlert } from "../../../../store/alert/alertActions";
import { validateTextInput } from "../../../../utils/validator";
import { stringConstants } from "../../../../constants/stringConstants";
import { refreshAllTabs } from "../../../../utils/apiDataUtil";
import "./RequestStyles.scss";

export class RequestContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTabDetails: props.currentTabApiDetails,
      currentTabId: props.currentTabApiId,

      apiName: props.currentTabApiDetails.name,
      method: props.currentTabApiDetails.request?.method,
      url: props.currentTabApiDetails.request?.url?.raw,

      requestBodyModeSelected:
        props.currentTabApiDetails.request?.body?.mode ??
        stringConstants.RAW_DATA,

      showSaveNewApiPopup: false,
      saveApiFolderList: null,
      saveApiCollectionPath: "",
      saveApiCurrentFolderPath: "",
      selectedNodeForSaveApi: null,
    };

    this.onApiNameChange = this.onApiNameChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);

    this.onHeadersDataChange = this.onHeadersDataChange.bind(this);
    this.onAddNewHeaderClick = this.onAddNewHeaderClick.bind(this);
    this.onDeleteHeaderItemClick = this.onDeleteHeaderItemClick.bind(this);

    this.onChangeRequestBodyMode = this.onChangeRequestBodyMode.bind(this);
    this.onChangeRequestBodyRawData =
      this.onChangeRequestBodyRawData.bind(this);

    this.onChangeRequestBodyFormData =
      this.onChangeRequestBodyFormData.bind(this);
    this.onAddNewFormDataClick = this.onAddNewFormDataClick.bind(this);
    this.onDeleteFormDataClick = this.onDeleteFormDataClick.bind(this);

    this.onCollectionListItemClick = this.onCollectionListItemClick.bind(this);
    this.onSaveNewApiConfirmClick = this.onSaveNewApiConfirmClick.bind(this);
    this.onSaveNewApiCancelClick = this.onSaveNewApiCancelClick.bind(this);
    this.bodyRawDataValidation = this.bodyRawDataValidation.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownHandler);
  }

  componentWillReceiveProps(newProps) {
    // console.log("Request Container : componentWillReceiveProps!!");
    if (this.props !== newProps) {
      this.props = newProps;
    }
  }

  componentWillUpdate() {
    // console.log("Request Container : componentWillUpdate!!");
  }

  /*--------------  KEY PRESS HANDLER  ------------------*/

  keydownHandler = (event) => {
    /*  --- Testing code for key press ---
    if (event.shiftKey) {
      console.log("keydownHandler : Shift + " + event.key);
    } else if (event.ctrlKey) {
      console.log("keydownHandler : Ctrl + " + event.key);
    } else if (event.altlKey) {
      console.log("keydownHandler : Alt + " + event.key);
    } else if (event.metaKey) {
      console.log("keydownHandler : CMD + " + event.key);
    } else {
      console.log("keydownHandler : " + event.key);
    }  */

    // Save API on pressing CMD+S or CTRL+S
    if (event.metaKey && event.key === "s") {
      event.preventDefault();
      console.log("SAVE API");
      this.onSaveClick();
    }

    if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      console.log("SAVE API");
      this.onSaveClick();
    }
  };

  /*-------------- ON CHANGE HANDLERS  ------------------*/

  onMethodChange = (event) => {
    var method = event.target.value;

    let newData = this.props.currentTabApiDetails;
    newData.request.method = method;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onUrlChange = (value) => {
    // Since we are using InputWithHighlighter this change is made
    // var url = event.target.value;
    var url = value;

    let newData = this.props.currentTabApiDetails;
    if (newData.name === "" || newData.name === newData.request.url.raw) {
      newData.request.url.raw = url;
      newData.name = url;
      newData.title = url;
    } else {
      newData.request.url.raw = url;
    }
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onApiNameChange = (event) => {
    var name = event.target.value;

    let newData = this.props.currentTabApiDetails;
    newData.name = name;
    newData.title = name;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onHeadersDataChange = (newDataItem, oldKey) => {
    // console.log("onHeadersDataChange" + JSON.stringify(newDataItem));

    let headersOld = this.props.currentTabApiDetails.request?.header ?? [];

    let headersNew = [];

    headersOld.forEach((header) => {
      if (String(header.key) === String(oldKey)) {
        // console.log("BINGO!");
        headersNew.push(newDataItem);
      } else {
        headersNew.push(header);
      }
    });

    // console.log("FINAL : " + JSON.stringify(headersNew));

    let newData = this.props.currentTabApiDetails;
    newData.request.header = headersNew;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onAddNewHeaderClick = () => {
    // console.log("onAddNewHeaderClick");

    let headersOld = this.props.currentTabApiDetails.request?.header ?? [];

    let doesEmptyHeaderItemExists = false;

    if (headersOld.length > 0) {
      headersOld.forEach((header) => {
        // console.log("#current item : " + JSON.stringify(header));
        if (
          String(header.key).trim() === "" &&
          String(header.value).trim() === ""
        ) {
          // console.log("BINGO!");
          doesEmptyHeaderItemExists = true;
          // SHOW ALERT :
          this.props.showAlert({
            isOpen: true,
            title: "Attention",
            type: "danger",
            msg: stringConstants.emptyHeaderError,
          });
        }
      });
    }

    if (!doesEmptyHeaderItemExists) {
      headersOld.push({ key: "", value: "", description: "", checked: true });

      let newData = this.props.currentTabApiDetails;
      newData.request.header = headersOld;
      newData.isUpdated = true;
      this.props.updateCurrentTabData(newData, moment().toString());
      this.forceUpdate();
    }
  };

  onDeleteHeaderItemClick = (itemKey) => {
    // console.log("onDeleteHeaderItemClick" + itemKey);
    let headersOld = this.props.currentTabApiDetails.request?.header ?? [];

    let headersNew = [];

    headersOld.forEach((header) => {
      // console.log("#current item : " + JSON.stringify(header));
      if (String(header.key) === String(itemKey)) {
        // console.log("BINGO!");
        // DON'T PUSH THIS ITEM!
      } else {
        headersNew.push(header);
      }
    });

    console.log("FINAL : " + JSON.stringify(headersNew));

    let newData = this.props.currentTabApiDetails;
    newData.request.header = headersNew;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onChangeRequestBodyMode = (event) => {
    var mode = event.target.value;
    this.setState({ requestBodyModeSelected: mode });

    // console.log(
    //   "currentTabApiDetails : " +
    //     JSON.stringify(this.props.currentTabApiDetails)
    // );

    let newData = this.props.currentTabApiDetails;
    newData["request"] = newData?.request ?? {};
    newData.request["body"] = newData?.request?.body ?? {};
    newData.request.body["mode"] = newData.request.body.mode ?? "";

    // console.log("newData BEFORE changing mode : " + JSON.stringify(newData));
    newData.request.body.mode = mode;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onChangeRequestBodyRawData = (event) => {
    var body = event.target.value;

    let newData = this.props.currentTabApiDetails;
    newData.request.body.mode = stringConstants.RAW_DATA;
    newData.request.body.raw = body;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onChangeRequestBodyFormData = (newDataItem, oldKey) => {
    // console.log("onChangeRequestBodyFormData" + JSON.stringify(newDataItem));

    let formDataOld =
      this.props.currentTabApiDetails.request?.body?.formdata ?? [];

    let formDataNew = [];

    formDataOld.forEach((formDataItem) => {
      if (String(formDataItem.key) === String(oldKey)) {
        // console.log("BINGO!");
        formDataNew.push(newDataItem);
      } else {
        formDataNew.push(formDataItem);
      }
    });

    // console.log("FINAL : " + JSON.stringify(headersNew));

    let newData = this.props.currentTabApiDetails;
    newData.request.body.formdata = formDataNew;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  onAddNewFormDataClick = () => {
    // console.log("onAddNewFormDataClick");

    let formDataOld =
      this.props.currentTabApiDetails.request?.body?.formdata ?? [];

    let doesEmptyFormDataItemExists = false;

    if (formDataOld.length > 0) {
      formDataOld.forEach((formDataItem) => {
        // console.log("#current item : " + JSON.stringify(formDataItem));
        if (
          String(formDataItem.key).trim() === "" &&
          String(formDataItem.value).trim() === ""
        ) {
          // console.log("BINGO!");
          doesEmptyFormDataItemExists = true;
          // SHOW ALERT :
          this.props.showAlert({
            isOpen: true,
            title: "Attention",
            type: "danger",
            msg: stringConstants.emptyFormDataError,
          });
        }
      });
    }

    if (!doesEmptyFormDataItemExists) {
      formDataOld.push({
        key: "",
        value: "",
        description: "",
        type: "text",
        checked: true,
      });

      let newData = this.props.currentTabApiDetails;
      newData.request.body.formdata = formDataOld;
      newData.isUpdated = true;
      this.props.updateCurrentTabData(newData, moment().toString());
      this.forceUpdate();
    }
  };

  onDeleteFormDataClick = (itemKey) => {
    // console.log("onDeleteFormDataClick" + itemKey);

    let formDataOld =
      this.props.currentTabApiDetails.request?.body?.formdata ?? [];

    let formDataNew = [];

    formDataOld.forEach((formDataItem) => {
      // console.log("#current item : " + JSON.stringify(formDataItem));
      if (String(formDataItem.key) === String(itemKey)) {
        // console.log("BINGO!");
        // DON'T PUSH THIS ITEM!
      } else {
        formDataNew.push(formDataItem);
      }
    });

    // console.log("FINAL : " + JSON.stringify(formDataNew));

    let newData = this.props.currentTabApiDetails;
    newData.request.body.formdata = formDataNew;
    newData.isUpdated = true;
    this.props.updateCurrentTabData(newData, moment().toString());
    this.forceUpdate();
  };

  /*-------------- ON SAVE CLICK  ------------------*/

  onSaveClick = () => {
    // alert(JSON.stringify(this.props.currentTabApiDetails, null, 4));
    let currentTabDetails = this.props.currentTabApiDetails;
    let currentTabId = currentTabDetails.id;

    //--------------------------------------------------
    // This is a 'New Tab' opened, and some changes are done inside tab
    // show popup of collection list to save API
    if (
      currentTabDetails.isUnsavedNewTab === true &&
      currentTabDetails.isUpdated === true
    ) {
      if (!validateTextInput(currentTabDetails.name)) {
        this.props.showAlert({
          isOpen: true,
          title: stringConstants.saveApiErrorTitle,
          type: "danger",
          msg: stringConstants.saveApiErrorMessage,
        });
      } else {
        this.setState({
          showSaveNewApiPopup: true,
          saveApiFolderList: this.props.collections,
          saveApiCollectionPath: "",
          saveApiCurrentFolderPath: "",
          selectedNodeForSaveApi: null,
        });
      }
    }
    //--------------------------------------------------
    // This is a redux saved API opened in tab, but its contents are changed after opening
    // Locate API in redux and Update its data there.
    else if (currentTabDetails.isUpdated === true) {
      if (!validateTextInput(currentTabDetails.name)) {
        this.props.showAlert({
          isOpen: true,
          title: stringConstants.saveApiErrorTitle,
          type: "danger",
          msg: stringConstants.saveApiErrorMessage,
        });
      } else {
        // update data in nodes DB for this tab Id aka node Id
        this.props.updateNodeDataOnSaveClick(currentTabId, currentTabDetails);
        this.forceUpdate();
        refreshAllTabs();
      }
    }
    //--------------------------------------------------
    // No changes are made in API / New Tab after opening
    else if (currentTabDetails.isUpdated === false) {
      this.props.showAlert({
        isOpen: true,
        title: "",
        type: "warning",
        msg: stringConstants.noNewChangesFound,
      });
    }
  };

  onCollectionListItemClick = (listItem) => {
    console.log("ITEM ::: " + JSON.stringify(listItem));

    let collectionData = listItem;
    let innerFolderAvailable = false;
    let innerFolderList = [];

    if (Array.isArray(collectionData.item)) {
      for (let index = 0; index < collectionData.item.length; index++) {
        const lodash = require("lodash");
        var collectionItemCopy = lodash.cloneDeep(collectionData.item[index]); //{ ...collectionData.item[index] };
        var folderDetails = this.props.getNodeDetails(collectionItemCopy.id);

        console.log("collectionItemCopy:" + JSON.stringify(collectionItemCopy));
        //If item is also a folder
        if (collectionItemCopy.type === stringConstants.NODE_TYPE_FOLDER) {
          innerFolderAvailable = true;
          collectionItemCopy.name = folderDetails.name;
          collectionItemCopy.connectingLink = folderDetails.connectingLink;
          innerFolderList.push(collectionItemCopy);
        }
      }
    }

    if (innerFolderAvailable) {
      //Show list with inner folders
      console.log("innerList:" + JSON.stringify(innerFolderList, null, 4));

      let path =
        this.state.saveApiCollectionPath === ""
          ? collectionData.name + " / "
          : this.state.saveApiCollectionPath + collectionData.name + " / ";

      this.setState({
        saveApiFolderList: innerFolderList,
        saveApiCollectionPath: path,
        saveApiCurrentFolderPath: "",
        selectedNodeForSaveApi: listItem,
      });
    } else {
      //This folder does not have inner folders, set selected folder is state variable
      console.warn("SELECTED ITEM :::" + JSON.stringify(listItem));

      this.setState({
        saveApiCurrentFolderPath: collectionData.name,
        selectedNodeForSaveApi: listItem,
      });
    }
  };

  onSaveNewApiConfirmClick = () => {
    this.setState({ showSaveNewApiPopup: false });

    var nodeId = this.props.currentTabApiDetails.id;
    var parentNodeId = this.state.selectedNodeForSaveApi.id;

    var parentNode = {};
    var parentConnectingLink = "";
    var collectionId = "";

    if (
      this.state.selectedNodeForSaveApi.type ===
      stringConstants.NODE_TYPE_COLLECTION
    ) {
      parentNode = this.props.getCollectionDataFromId(parentNodeId);
      parentConnectingLink = this.state.selectedNodeForSaveApi.id;
      collectionId = parentNodeId;
    } else {
      parentNode = this.props.getNodeDetailsWithRef(parentNodeId);
      parentConnectingLink = this.state.selectedNodeForSaveApi.connectingLink;
      var index = parentConnectingLink.indexOf("--");
      collectionId = parentConnectingLink.substr(0, index);
    }

    // let parentNode =
    //   this.state.selectedNodeForSaveApi.type ===
    //   stringConstants.NODE_TYPE_COLLECTION
    //     ? this.props.getCollectionDataFromId(parentNodeId)
    //     : this.props.getNodeDetailsWithRef(parentNodeId);

    // var parentConnectingLink =
    //   this.state.selectedNodeForSaveApi.type ===
    //   stringConstants.NODE_TYPE_COLLECTION
    //     ? this.state.selectedNodeForSaveApi.id
    //     : this.state.selectedNodeForSaveApi.connectingLink;

    console.log("parentId :  " + parentNodeId);
    console.log("parentConnectingLink : " + parentConnectingLink);
    console.log("collectionId :  " + collectionId);

    // Add this API to nodes DB
    const lodash = require("lodash");
    let newData = lodash.cloneDeep(this.props.currentTabApiDetails); //{ ...this.props.currentTabApiDetails };
    newData.type = stringConstants.NODE_TYPE_API;
    newData.connectingLink = parentConnectingLink + "--" + nodeId;
    newData.isUnsavedNewTab = false;
    newData.isUpdated = false;
    newData.updateTimestamp = moment().format(stringConstants.TIMESTAMP_FORMAT);
    this.props.saveNode(nodeId, newData);

    // Add this API to selected folder in collections DB
    // Save data updated with Ids in redux ( in tree )
    var apiDataForCollectionDB = { id: newData.id, type: newData.type };
    this.props.addNewNodeInCollectionTree(
      parentConnectingLink, //Connecting Link of selected node to save API
      collectionId, //Collection id for selected node to save API
      apiDataForCollectionDB
    );

    //Update current tab data with id and isUpdated fields
    this.props.currentTabApiDetails.isUnsavedNewTab = false;
    this.props.currentTabApiDetails.isUpdated = false;
    refreshAllTabs();

    // setTimeout(() => {
    //   console.log("POST NEW API ADD : parentNode : ");
    //   console.log(
    //     this.state.selectedNodeForSaveApi.type ===
    //       stringConstants.NODE_TYPE_COLLECTION
    //       ? JSON.stringify(
    //           this.props.getCollectionDataFromId(parentNodeId),
    //           null,
    //           4
    //         )
    //       : JSON.stringify(this.props.getNodeDetails(parentNodeId), null, 4)
    //   );
    // }, 1500);
  };

  onSaveNewApiCancelClick = () => {
    this.setState({ showSaveNewApiPopup: false });
  };

  /*-------------- ON SEND CLICK  ------------------*/
  onSendClick = () => {
    this.isRequestValid(
      (isValidationSuccessfull, headerItems, bodyJsonData, bodyFormData) => {
        if (isValidationSuccessfull) {
          console.warn("isRequestValid returned true : ");

          //------------ show loader in response panel --------------------
          this.props.updateApiResponseAsLoading(
            this.props.currentTabApiId,
            this.props.currentTabApiDetails
          );

          //------------ make API call if data is valid --------------------
          this.props.makeApiCallAndGetResponse(
            this.props.currentTabApiId,
            this.props.currentTabApiDetails,
            String(
              this.props.currentTabApiDetails.request.method
            ).toLowerCase(),
            this.props.currentTabApiDetails.request.url.raw,
            headerItems,
            this.state.requestBodyModeSelected,
            bodyJsonData,
            bodyFormData
          );
        }
      }
    );
  };

  // isUrlValid = () => {};

  // isMethodValid = () => {};

  // isBodyValid = () => {};

  isRequestValid = (callback) => {
    var method = this.props.currentTabApiDetails.request?.method ?? "";

    var url = this.props.currentTabApiDetails.request?.url?.raw ?? "";

    var headers = this.props.currentTabApiDetails.request?.header ?? [];

    console.log(
      "isRequestValid : " +
        JSON.stringify(this.props.currentTabApiDetails, null, 4)
    );

    // ----------- Headers Manipulation -----------
    let finalHeaderItems = [];
    headers.forEach((headerItem) => {
      if (headerItem.key === "" && headerItem.value === "") {
        // don't add this empty header
      } else if (headerItem.checked) {
        // add this non-empty header only if checked is true
        finalHeaderItems.push(headerItem);
      }
    });

    // --------- Body text validation  ----------
    let isBodyValid = false;
    let finalBodyRawText = null;
    let finalBodyFormData = null;

    if (
      this.props.currentTabApiDetails.request?.body &&
      this.props.currentTabApiDetails.request?.body?.mode ===
        stringConstants.RAW_DATA
    ) {
      // Body mode is - Raw Data
      console.log("isRequestValid : Body mode is - Raw Data");
      this.bodyRawDataValidation((isBodyTextValidJSON, finalBodyText) => {
        isBodyValid = isBodyTextValidJSON;
        finalBodyRawText = finalBodyText;
        finalBodyFormData = null;
      });
    } else if (
      this.props.currentTabApiDetails.request?.body &&
      this.props.currentTabApiDetails.request?.body?.mode ===
        stringConstants.FORM_DATA
    ) {
      // Body mode is - Form Data
      console.log("isRequestValid : Body mode is - Form Data");
      isBodyValid = true;
      finalBodyRawText = null;
      finalBodyFormData =
        this.props.currentTabApiDetails.request?.body?.formdata ?? [];
    } else if (
      this.props.currentTabApiDetails.request?.body?.mode === undefined
    ) {
      // No body present
      console.log("isRequestValid :  No body present");
      isBodyValid = true;
      finalBodyRawText = null;
      finalBodyFormData = null;
    }

    // ---------- final validation  ---------------

    if (url.trim() === "") {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Url can not be empty!",
      });
      callback(false, null, null, null);
    } else if (method === "") {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select a method!",
      });
      callback(false, null, null, null);
    } else if (!isBodyValid) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Some error occurred while parsing body data!",
      });
      callback(false, null, null, null);
    } else {
      callback(true, finalHeaderItems, finalBodyRawText, finalBodyFormData);
    }
  };

  bodyRawDataValidation = (callback) => {
    var isBodyTextValidJSON = true;
    let bodyText = this.props.currentTabApiDetails.request?.body?.raw ?? "";

    // if 'raw' param is not present in body , then use ""

    console.log("inside bodyRawDataValidation : bodyText");
    console.log(bodyText);

    if (bodyText !== "") {
      try {
        JSON.parse(bodyText);
      } catch (error) {
        console.warn("Body Json Parse Error : " + error);
        isBodyTextValidJSON = false;
      }
    }

    if (isBodyTextValidJSON) {
      let finalBodyText = bodyText.trim() === "" ? {} : JSON.parse(bodyText);

      // body present, with text data empty or correct json ----> return  true, {} or {JSON}
      callback(isBodyTextValidJSON, finalBodyText);
    } else {
      // body present, with text data of incorrect json ----> return  false , null
      callback(isBodyTextValidJSON, null);
    }
  };

  /*----------------------------------------------*/

  render() {
    return (
      <RequestPanel
        // redux data props for Current Tab
        currentTabApiId={this.props.currentTabApiId}
        currentTabApiDetails={this.props.currentTabApiDetails}
        //--------------------------------
        // request props
        onApiNameChange={this.onApiNameChange}
        onMethodChange={this.onMethodChange}
        onUrlChange={this.onUrlChange}
        //--------------------------------
        // header props
        onHeadersDataChange={this.onHeadersDataChange}
        onAddNewHeaderClick={this.onAddNewHeaderClick}
        onDeleteHeaderItemClick={this.onDeleteHeaderItemClick}
        //--------------------------------
        // body props
        requestBodyModeSelected={this.state.requestBodyModeSelected}
        onChangeRequestBodyMode={this.onChangeRequestBodyMode}
        onChangeRequestBodyRawData={this.onChangeRequestBodyRawData}
        onChangeRequestBodyFormData={this.onChangeRequestBodyFormData}
        onAddNewFormDataClick={this.onAddNewFormDataClick}
        onDeleteFormDataClick={this.onDeleteFormDataClick}
        //--------------------------------
        // buttons
        onSendClick={this.onSendClick}
        onSaveClick={this.onSaveClick}
        //--------------------------------
        // popup to save New Tab API
        showSaveNewApiPopup={this.state.showSaveNewApiPopup}
        onSaveNewApiConfirmClick={this.onSaveNewApiConfirmClick}
        onSaveNewApiCancelClick={this.onSaveNewApiCancelClick}
        saveApiFolderList={this.state.saveApiFolderList}
        saveApiCollectionPath={this.state.saveApiCollectionPath}
        saveApiCurrentFolderPath={this.state.saveApiCurrentFolderPath}
        onCollectionListItemClick={this.onCollectionListItemClick}
      />
    );
  }
}

RequestContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    collections: state.workspace.collections,
    currentTabApiDetails: state.workspace.currentTabApiDetails,
    currentTabApiId: state.workspace.currentTabApiId,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  updateCurrentTabData,
  addNewNodeInCollectionTree,
  makeApiCallAndGetResponse,
  updateNodeDataOnSaveClick,
  saveNode,
  getNodeDetails,
  getNodeDetailsWithRef,
  getCollectionDataFromId,
  updateApiResponseAsLoading,
})(RequestContainer);
