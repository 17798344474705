/* KINDLY DO NOT DELETE ANY COMMENTS OF THIS FILE. ELSE AUTO-CODE MERGER WILL NOT WORK IN FUTURE */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import alert from "../alert/alertReducer";
import loader from "../loader/loaderReducer";
import sidebar from "../sidebar/sidebarReducer";
import auth from "../auth/authReducer";
import context from "../context/contextReducer";
import workspace from "../workspace/workspaceReducer";
import environment from "../environment/environmentReducer";
// import module level reducers here

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    loader,
    sidebar,
    auth,
    context,
    workspace,
    environment,
    // add module level reducers here
  });
