import React from "react";
import {
  Input,
  ButtonView,
  TreeViewJson,
  PopupDialog,
  ListViewDynamic,
  JsonFileReader,
} from "../../../controlsNew";
import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";
import { BsFolderPlus } from "react-icons/bs";
import { BsFiletypeJson, BsFileEarmarkCode } from "react-icons/bs";
import { GoFileCode } from "react-icons/go";
import { stringConstants } from "../../../../constants/stringConstants";
import "./WorkspaceSideBarStyles.scss";

const WorkspaceSideBarPanel = (props) => {
  // console.log("SIDEBAR PROPS: " + JSON.stringify(props, null, 4));

  function renderTreeView(allCollectionsArray) {
    var collectionsTree = {
      id: "root",
      name: "My Collections",
      type: stringConstants.NODE_TYPE_COLLECTION,
      item: allCollectionsArray,
    };

    return (
      <TreeViewJson
        data={collectionsTree}
        onRenameItemClicked={props.onRenameItemClicked}
        onDuplicateItemClicked={props.onDuplicateItemClicked}
        onAddFolderClicked={props.onAddFolderClicked}
        onAddNewRequestClicked={props.onAddNewRequestClicked}
        onSyncItemClicked={props.onSyncItemClicked}
        onShareItemClicked={props.onShareItemClicked}
        onDeleteItemClicked={props.onDeleteItemClicked}
      />
    );
  }

  function renderNameInputPopupContent() {
    return (
      <Input
        placeholder={stringConstants.name}
        onChange={props.onInputPopupEnteredNameChange}
        value={props.inputPopupEnteredName}
        showBottomline
      />
    );
  }

  function renderShareCollectionPopupContent() {
    return (
      <ListViewDynamic
        data={props.shareCollectionUsersList}
        onDataChange={props.onUsersListDataChange}
        onAddNewItemClick={props.onUsersListAddItemClick}
        onDeleteItemClick={props.onUsersListDeleteItemClick}
        type={stringConstants.LIST_TYPE_SHARE_COLLECTION}
      />
    );
  }

  return (
    <>
      <div className="sidebar-apis-container ">
        <div className="sidebar-horizontal-container mt-2">
          <ButtonView
            title={stringConstants.addCollectionPopupTitle}
            icon={<BsFolderPlus size={15} color="#000" />}
            styles="grey-outline small"
            onClick={props.onAddNewCollectionClick}
            // tooltip={stringConstants.addCollectionPopupTitle}
            fullWidth
          />

          {/* <IconButtonView
            icon={<IoAdd size={15} color="#000" />}
            tooltip={stringConstants.addCollectionPopupTitle}
            onClick={props.onAddNewCollectionClick}
          />

          <Input disabled={true} placeholder={"No Environment Selected"} />

          <Dropdown
            label={null}
            data={methods}
            value={null}
            onSelect={() => {}}
            // noBorder
            // noUnderline
            arrowOnly
          /> */}
        </div>

        <div className="sidebar-horizontal-container-2 mt-1 pb-2">
          <ButtonView
            title={stringConstants.importCollection}
            icon={<BsFileEarmarkCode size={15} color="#000" />}
            onClick={props.onImportCollectionClicked}
            styles="grey-outline small"
            // tooltip={stringConstants.importCollection}
            fullWidth
          />

          <ButtonView
            // title="Sync All Data"
            icon={<IoCloudUploadOutline size={15} color="#000" />}
            onClick={props.onSyncDataClicked}
            styles="grey-outline small"
            tooltip={stringConstants.syncData}
          />

          <ButtonView
            // title="Fetch All Data"
            icon={<IoCloudDownloadOutline size={15} color="#000" />}
            onClick={props.onFetchDataClicked}
            styles="grey-outline small"
            tooltip={stringConstants.fetchData}
          />
        </div>
        {renderTreeView(props.allCollectionsArray)}
      </div>

      {props.showNameInputPopup === true ? (
        <PopupDialog
          title={props.inputPopupTitle}
          message={props.inputPopupSubtitle}
          onConfirmClick={props.onInputPopupConfirmClick}
          onCancelClick={props.onInputPopupCancelClick}
          renderContents={renderNameInputPopupContent()}
        />
      ) : null}

      {props.showFileReader === true ? (
        <JsonFileReader
          title={stringConstants.importCollectionPopupTitle}
          message={stringConstants.importCollectionPopupSubTitle}
          onFileRead={(json) => props.onFileRead(json)}
          onCancelClick={props.onImportCancelClick}
        />
      ) : null}

      {props.showSyncConfirmDialog === true ? (
        <PopupDialog
          title={stringConstants.syncDataPopupTitle}
          message={stringConstants.syncDataPopupSubTitle}
          onConfirmClick={props.onSyncConfirmClick}
          onCancelClick={props.onSyncCancelClick}
        />
      ) : null}

      {props.showShareCollectionPopup === true ? (
        <PopupDialog
          title={
            stringConstants.shareCollectionPopupTitle +
            props.shareCollectionName
          }
          message={stringConstants.shareCollectionPopupSubtitle}
          onConfirmClick={props.onShareCollectionConfirmClick}
          onCancelClick={props.onShareCollectionCancelClick}
          renderContents={renderShareCollectionPopupContent()}
          widePopup
        />
      ) : null}
    </>
  );
};

export default WorkspaceSideBarPanel;
