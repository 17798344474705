import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { stringConstants } from "../../../../constants/stringConstants";
import ResponsePanel from "./ResponsePanel";
import "./ResponseStyles.scss";

export class ResponseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedResponseBodyMode: stringConstants.PRETTY_DATA,
    };

    this.onChangeResponseBodyMode = this.onChangeResponseBodyMode.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    // console.log("Response Container : componentWillReceiveProps!!");
    if (this.props !== newProps) {
      this.props = newProps;
    }
  }

  componentWillUpdate() {
    // console.log("Response Container : componentWillUpdate!!");
  }

  onChangeResponseBodyMode = (event) => {
    var mode = event.target.value;
    this.setState({ selectedResponseBodyMode: mode });
    this.forceUpdate();
  };

  render() {
    return (
      <ResponsePanel
        selectedResponseBodyMode={this.state.selectedResponseBodyMode}
        onChangeResponseBodyMode={this.onChangeResponseBodyMode}
        currentTabApiDetails={this.props.currentTabApiDetails}
      />
    );
  }
}

ResponseContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    currentTabApiDetails: state.workspace.currentTabApiDetails,
    currentTabApiId: state.workspace.currentTabApiId,
    timestamp: state.workspace.timestamp,
  };
};

export default connect(mapStateToProps, {})(ResponseContainer);
