// Domain : USAGE - FOR DEV
// export const DOMAIN = "https://democheckpoint.smartsight.in";

// Domain : USAGE - FOR PROD
export const DOMAIN = "https://checkpoint.smartsight.in";

/*--------------------------------------------------------------------------*/

// Firebase Config variables : USAGE - FOR DEV
// export const FIREBASE_API_KEY = "AIzaSyALMiGONl9vdx48UgE7AtSyb3e4kHPuaXw";
// export const FIREBASE_AUTH_DOMAIN = "ssi-checkpoint.firebaseapp.com";
// export const FIREBASE_DATABASE_URL =
//   "https://ssi-checkpoint-default-rtdb.firebaseio.com";
// export const FIREBASE_PROJECT_ID = "ssi-checkpoint";
// export const FIREBASE_STORAGE_BUCKET = "ssi-checkpoint.appspot.com";
// export const FIREBASE_MESSAGING_SENDER_ID = "1017832841370";
// export const FIREBASE_APP_ID = "1:1017832841370:web:f7aac5fa9cffb97c80bf23";
// export const FIREBASE_MEASUREMENT_ID = "G-9VHNLD6YX0";

// Firebase Config variables : USAGE - FOR PROD
export const FIREBASE_API_KEY = "AIzaSyAzsFjkX1zqVpdejNGL6QzdAn84x_hIoSc";
export const FIREBASE_AUTH_DOMAIN = "checkpoint-8e921.firebaseapp.com";
export const FIREBASE_DATABASE_URL =
  "https://checkpoint-8e921-default-rtdb.firebaseio.com";
export const FIREBASE_PROJECT_ID = "checkpoint-8e921";
export const FIREBASE_STORAGE_BUCKET = "checkpoint-8e921.appspot.com";
export const FIREBASE_MESSAGING_SENDER_ID = "1018723721519";
export const FIREBASE_APP_ID = "1:1018723721519:web:fb0ad5a2b8ae23186541da";
export const FIREBASE_MEASUREMENT_ID = "G-00VVFSGE4W";
