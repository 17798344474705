import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  addNewEnvironment,
  getEnvironmentDataFromId,
  getVariablesForEnvironment,
  editEnvironmentName,
  setSelectedEnvironmentId,
  resetSelectedEnvironmentId,
  saveVariable,
  deleteVariable,
  deleteEnvironment,
} from "../../../../store/environment/environmentActions";
import { generateRandomNodeId } from "../../../../utils/apiDataUtil";
import { validateTextInput } from "../../../../utils/validator";
import { stringConstants } from "../../../../constants/stringConstants";
import EnvContentPanel from "./EnvContentPanel";
import "./EnvContentStyles.scss";

export class EnvContentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedEnvData: {},
      variablesForClickedEnv: [],
      variablesDeleted: [],
      envNameUpdated: "",
      showEditEnvNamePopup: false,
      showDeleteEnvPopup: false,
    };

    this.refreshMainContent = this.refreshMainContent.bind(this);

    this.onEditEnvNameClick = this.onEditEnvNameClick.bind(this);
    this.onEditEnvNameInputChange = this.onEditEnvNameInputChange.bind(this);
    this.onEditEnvNameConfirmClick = this.onEditEnvNameConfirmClick.bind(this);
    this.onEditEnvNameCancelClick = this.onEditEnvNameCancelClick.bind(this);

    this.onSelectEnvironmentClick = this.onSelectEnvironmentClick.bind(this);
    this.onDeSelectEnvironmentClick =
      this.onDeSelectEnvironmentClick.bind(this);

    this.onAddNewVariableClick = this.onAddNewVariableClick.bind(this);
    this.onChangeVariableData = this.onChangeVariableData.bind(this);
    this.onDeleteVariableClick = this.onDeleteVariableClick.bind(this);
    this.checkIfVariableHasValidData =
      this.checkIfVariableHasValidData.bind(this);
    this.onSaveEnvironmentClick = this.onSaveEnvironmentClick.bind(this);

    this.onDeleteEnvironmentClick = this.onDeleteEnvironmentClick.bind(this);
    this.onDeleteEnvConfirmClick = this.onDeleteEnvConfirmClick.bind(this);
    this.onDeleteEnvCancelClick = this.onDeleteEnvCancelClick.bind(this);
  }

  componentDidMount() {
    this.refreshMainContent();
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    // console.log("Response Container : componentWillReceiveProps!!");
    if (this.props !== newProps) {
      this.props = newProps;
      this.refreshMainContent();
    }
  }

  componentWillUpdate() {
    // console.log("Response Container : componentWillUpdate!!");
  }

  refreshMainContent = () => {
    let variables = getVariablesForEnvironment(this.props.clickedEnvId);
    let clickedEnv = getEnvironmentDataFromId(this.props.clickedEnvId);

    this.setState({
      variablesForClickedEnv: variables,
      clickedEnvData: clickedEnv,
    });
  };

  onEditEnvNameClick = () => {
    this.setState({ showEditEnvNamePopup: true });
  };

  onEditEnvNameInputChange = (event) => {
    var envName = event.target.value;
    this.setState({ envNameUpdated: envName });
  };

  onEditEnvNameConfirmClick = () => {
    this.props.editEnvironmentName(
      this.props.clickedEnvId,
      this.state.envNameUpdated
    );
    this.setState({ showEditEnvNamePopup: false, envNameUpdated: "" });
    this.refreshMainContent();
    this.forceUpdate();
  };

  onEditEnvNameCancelClick = () => {
    this.setState({ showEditEnvNamePopup: false, envNameUpdated: "" });
  };

  onSelectEnvironmentClick = () => {
    var envData = this.state.clickedEnvData;
    envData.selected = true;
    this.setState({ clickedEnvData: envData });
    this.props.setSelectedEnvironmentId(this.props.clickedEnvId);
  };

  onDeSelectEnvironmentClick = () => {
    var envData = this.state.clickedEnvData;
    envData.selected = false;
    this.setState({ clickedEnvData: envData });
    this.props.resetSelectedEnvironmentId();
  };

  onAddNewVariableClick = () => {
    var newVariable = {
      name: "",
      initialValue: "",
      currentValue: "",
      environmentId: this.state.clickedEnvData.id,
    };

    var allVariablesForThisEnv = this.state.variablesForClickedEnv;
    allVariablesForThisEnv.push(newVariable);

    this.setState({ variablesForClickedEnv: allVariablesForThisEnv });
  };

  onChangeVariableData = (newDataItem, oldName) => {
    let variablesListOld = this.state.variablesForClickedEnv ?? [];
    let variablesListNew = [];

    variablesListOld.forEach((variablesItem) => {
      if (String(variablesItem.name) === String(oldName)) {
        // console.log("BINGO!");
        variablesListNew.push(newDataItem);
      } else {
        variablesListNew.push(variablesItem);
      }
    });

    // console.log("FINAL : " + JSON.stringify(variablesListNew));
    this.setState({ variablesForClickedEnv: variablesListNew });
  };

  onDeleteVariableClick = (variableName) => {
    let variablesListOld = this.state.variablesForClickedEnv ?? [];
    let variablesListNew = [];
    let variablesDeletedList = [];

    variablesListOld.forEach((variablesItem) => {
      if (String(variablesItem.name) === String(variableName)) {
        // console.log("BINGO!");
        variablesDeletedList.push(variablesItem);
      } else {
        variablesListNew.push(variablesItem);
      }
    });

    // console.log("FINAL : " + JSON.stringify(variablesListNew));
    this.setState({
      variablesForClickedEnv: variablesListNew,
      variablesDeleted: variablesDeletedList,
    });
  };

  checkIfVariableHasValidData = (item) => {
    if (!validateTextInput(item.name)) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.saveEnvironmentErrorTitle,
        type: "danger",
        msg: stringConstants.saveEnvironmentError1,
      });
      return false;
    } else if (!validateTextInput(item.initialValue)) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.saveEnvironmentErrorTitle,
        type: "danger",
        msg: stringConstants.saveEnvironmentError2,
      });
      return false;
    } else if (!validateTextInput(item.currentValue)) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.saveEnvironmentErrorTitle,
        type: "danger",
        msg: stringConstants.saveEnvironmentError3,
      });
      return false;
    }
    return true;
  };

  onSaveEnvironmentClick = () => {
    var allVariablesForThisEnv = this.state.variablesForClickedEnv;
    var allVariablesDeleted = this.state.variablesDeleted;
    var allVariablesSavedSuccessfully = false;

    // Save all variables one by one
    for (let index = 0; index < allVariablesForThisEnv.length; index++) {
      const item = allVariablesForThisEnv[index];

      if (this.checkIfVariableHasValidData(item)) {
        // Check if current variable is non-empty. If this is last one, set flag as true
        this.props.saveVariable(item);
        if (index === allVariablesForThisEnv.length - 1) {
          allVariablesSavedSuccessfully = true;
        }
      } else {
        // Only move to next variable if current one is saved, else, break for loop
        break;
      }
    }

    if (allVariablesSavedSuccessfully) {
      // Remove Deleted variables one by one from redux
      allVariablesDeleted.forEach((item) => {
        this.props.deleteVariable(item);
      });

      // Show success message!
      setTimeout(() => {
        this.props.showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: stringConstants.saveEnvironmentSuccess,
        });
      }, 500);
    }
  };

  onDeleteEnvironmentClick = () => {
    this.setState({ showDeleteEnvPopup: true });
  };

  onDeleteEnvConfirmClick = () => {
    this.setState({ showDeleteEnvPopup: false });
    this.props.deleteEnvironment(this.props.clickedEnvId);
  };

  onDeleteEnvCancelClick = () => {
    this.setState({ showDeleteEnvPopup: false });
  };

  render() {
    return (
      <EnvContentPanel
        isEnvClicked={this.props.isEnvClicked}
        clickedEnvId={this.props.clickedEnvId}
        environments={this.props.environments}
        variables={this.props.variables}
        //-------------
        clickedEnvData={this.state.clickedEnvData}
        variablesForClickedEnv={this.state.variablesForClickedEnv}
        //-------------
        showEditEnvNamePopup={this.state.showEditEnvNamePopup}
        envNameUpdated={this.state.envNameUpdated}
        onEditEnvNameClick={this.onEditEnvNameClick}
        onEditEnvNameInputChange={this.onEditEnvNameInputChange}
        onEditEnvNameConfirmClick={this.onEditEnvNameConfirmClick}
        onEditEnvNameCancelClick={this.onEditEnvNameCancelClick}
        //-------------
        onSelectEnvironmentClick={this.onSelectEnvironmentClick}
        onDeSelectEnvironmentClick={this.onDeSelectEnvironmentClick}
        //-------------
        onAddNewVariableClick={this.onAddNewVariableClick}
        onChangeVariableData={this.onChangeVariableData}
        onDeleteVariableClick={this.onDeleteVariableClick}
        onSaveEnvironmentClick={this.onSaveEnvironmentClick}
        //--------------
        showDeleteEnvPopup={this.state.showDeleteEnvPopup}
        onDeleteEnvironmentClick={this.onDeleteEnvironmentClick}
        onDeleteEnvConfirmClick={this.onDeleteEnvConfirmClick}
        onDeleteEnvCancelClick={this.onDeleteEnvCancelClick}
      />
    );
  }
}

EnvContentContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    isEnvClicked: state.environment.isEnvClicked,
    clickedEnvId: state.environment.clickedEnvId,
    environments: state.environment.environments,
    variables: state.environment.variables,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  addNewEnvironment,
  getEnvironmentDataFromId,
  getVariablesForEnvironment,
  editEnvironmentName,
  setSelectedEnvironmentId,
  resetSelectedEnvironmentId,
  saveVariable,
  deleteVariable,
  deleteEnvironment,
})(EnvContentContainer);
