export const stringConstants = {
  welcomeMsg:
    "Please Login with your smartsight email to access SSI-Checkpoint!",

  /*---------- Header Buttons -----------------*/

  login: "Login",
  logout: "Logout",

  logoutPopupTitle: "Confirm Logout?",
  logoutPopupSubTitle:
    "Once you logout all your local changes will be deleted. Make sure you have synced all data to server before logout. Are you sure to perform logout?",

  loginSuccess: "You are successfully Logged In!",
  logoutSuccess: "You are successfully Logged Out!",

  /*---------- Sidebar Workspace -----------------*/

  addCollectionPopupTitle: "Add New Collection",
  addCollectionPopupSubtitle: "Please enter a name for new collection",
  addCollectionPopupPlaceholder: "Collection name",
  addCollectionSuccess: "New collection added!",
  collectionNameError: "Collection name can not be empty!",

  importCollection: "Import Collection",
  importCollectionPopupTitle: "Import Collection",
  importCollectionPopupSubTitle: "Choose a JSON file to import collection",
  importCancel: "Cancel Import",
  importCollectionSuccess: "Collection Imported successfully!",

  syncData: "Sync Collections Data",
  syncDataPopupTitle: "Sync Collections Data?",
  syncDataPopupSubTitle:
    "This action will sync all your collections data to server. Make sure to save changes in your open tabs before syncing. Are you sure to continue?",
  syncDataSuccess: "Collections Data synced successfully!",

  fetchData: "Fetch Collections Data",
  fetchDataSuccess: "Collections data fetched successfully!",

  rename: "Rename",
  duplicate: "Duplicate",
  addFolder: "Add Folder",
  addNewRequest: "Add New Request",
  syncItem: "Sync to server",
  shareCollection: "Share Collection",
  delete: "Delete",

  renameItemPopupTitle: "Rename",
  renameItemPopupSubTitle: "Please enter a new Name",
  renameItemPopupPlaceholder: "New Name",
  renameItemNameError: "Name can not be empty!",

  addFolderPopupTitle: "Add New Folder",
  addFolderPopupSubtitle: "Please enter a name for new folder",
  addFolderPopupPlaceholder: "Folder name",
  addFolderSuccess: "Folder added successfully!",
  folderNameError: "Folder name can not be empty!",

  addNewRequestPopupTitle: "Add New Request",
  addNewRequestPopupSubtitle: "Please enter a name for new request",
  addNewRequestPopupPlaceholder: "Request name",
  addNewRequestSuccess: "Request added successfully!",

  addAPISuccess: "API saved successfully!",
  changesSaved: "Changes saved successfully!",

  syncItemSuccess: "Item synced successfully!",
  syncItemAndCollectionSuccess:
    "Item along with collection synced successfully!",
  syncItemErrorTitle: "Unable to sync item",
  syncItemErrorDescription:
    "Seems like you do not have write permission for this collection!",

  deleteItemErrorTitle: "Unable to delete item",
  deleteCollectionSuccess: "Collection deleted successfully!",
  deleteItemSuccess: "Item deleted successfully!",

  shareCollectionPopupTitle: "Share Collection : ",
  shareCollectionPopupSubtitle:
    "Please add users along with access levels to this collection.",
  shareCollectionSuccess: "Collection shared successfully!",
  shareCollectionErrorTitle: "Unable to share collection",
  shareCollectionErrorDescription:
    "Seems like you do not have permission to share this collection!",

  importCurlPopupTitle: "Import API using cURL",
  importCurlPopupSubtitle: "Enter a name and cURL for the API",
  importCurlPlaceholder1: "Enter API name (Optional)",
  importCurlPlaceholder2: "Paste your cURL here...",
  importCurlSuccess: "API imported successfully!",
  importCurlErrorTitle: "Error importing API",
  importCurlErrorEmptyCurl: "cURL should not be empty!",
  importCurlErrorIncorrectCurl: "Entered cURL is not in a correct format!",
  importCurlErrorPartialCorrectCurl: "Some of cURL params were not imported!",

  collectionNotAvailableOnServerError:
    "Seems like this collection is not present on server! Please sync the whole collection first.",

  /*---------- Sidebar Env -----------------*/

  environment: "Environment",
  addEnvironment: "Add Environment",
  syncEnvironment: "Sync Environments Data",
  fetchEnvironment: "Fetch Environments Data",
  syncEnvironmentSuccess: "Environments data synced successfully!",
  fetchEnvironmentSuccess: "Environments data fetched successfully!",

  addEnvironmentPopupTitle: "Add New Environment",
  addEnvironmentPopupSubtitle: "Please enter a name for new environment",
  addEnvironmentPopupPlaceholder: "Environment name",
  addEnvironmentSuccess: "New Environment added!",
  environmentNameError: "Environment name can not be empty!",

  editEnvironmentNamePopupTitle: "Update Environment Name",
  editEnvironmentNamePopupMessage: "Enter a new name for Environment",
  editEnvironmentNameSuccess: "Environment name updated!",

  saveEnvironmentErrorTitle: "Error saving environment",
  saveEnvironmentError1: "Variable name can not be empty!",
  saveEnvironmentError2: "Variable's initial value should be provided!",
  saveEnvironmentError3: "Variable's current value should be provided!",
  saveEnvironmentSuccess: "Environment data saved!",

  envNameErrorTitle: "Error adding Environment",
  envNameErrorMessage: "Please enter a valid Environment name.",

  envCollectionErrorTitle: "Error adding Environment",
  envCollectionErrorMessage: "Please select a collection.",

  variable: "Variable",
  addVariablePopupTitle: "Add New Variable",
  addVariablePopupSubtitle: "Please enter a name for new variable",
  addVariablePopupPlaceholder: "Variable name",
  addVariableSuccess: "New Variable added!",
  variableNameError: "Variable name can not be empty!",

  deleteEnvironmentPopupTitle: "Delete Environment",
  deleteEnvironmentPopupMessage:
    "Are you sure to delete this environment and all variables associated wit it?",
  deleteEnvironmentSuccess: "Environment deleted!",

  name: "Name",
  initialValue: "Initial Value",
  currentValue: "Current Value",
  saveEnvironment: "Save Environment",
  selectEnvironment: "Select Environment",
  deselectEnvironment: "Remove Environment",
  deleteEnvironment: "Delete Environment",

  variablesList: "Variables List",
  noVariablesFound: "No Variables found!",
  collectionLinkedToEnv: "Collection Linked with environment : ",

  /*----------------- Request Panel -------------------*/

  enterApiName: "Enter API Name",
  enterUrl: "Enter request URL",
  method: "Method",
  send: "Send",
  saveApi: "Save",

  saveNewApiPopupTitle: "Save New API",
  saveNewApiPopupSubTitle: "Please select a location to save new API.",

  saveApiErrorTitle: "Error while saving!",
  saveApiErrorMessage: "Please enter a valid API name.",

  emptyHeaderError: "An empty header item already present!",
  emptyFormDataError: "An empty form data item already present!",
  bodyTextJsonError: "Body text should be a valid JSON Object!",
  noNewChangesFound: "No new changes found!",
  emptyListItemAlreadyFound: "An empty list item already present!",

  /*----------------- CONSTANTS -------------------*/
  RAW_DATA: "raw",
  FORM_DATA: "formdata",
  PRETTY_DATA: "pretty",

  NODE_TYPE_FOLDER: "FOLDER",
  NODE_TYPE_API: "API",
  NODE_TYPE_COLLECTION: "COLLECTION",

  LIST_TYPE_HEADERS: "HEADERS",
  LIST_TYPE_FORM_DATA: "FORM_DATA",
  LIST_TYPE_SHARE_COLLECTION: "SHARE_COLLECTION",
  LIST_TYPE_VARIABLES: "VARIABLES",

  CONTENT_TYPE_HTML: "text/html",
  CONTENT_TYPE_JSON: "application/json",
  CONTENT_TYPE_ERROR: "ERROR",
  CONTENT_TYPE_LOADING: "LOADING",

  TIMESTAMP_FORMAT: "MM:DD:YYYY HH:mm:ss",

  POPUP_TYPE_ADD_COLLECTION: "POPUP_TYPE_ADD_COLLECTION",
  POPUP_TYPE_ADD_FOLDER: "POPUP_TYPE_ADD_FOLDER",
  POPUP_TYPE_ADD_NEW_REQUEST: "POPUP_TYPE_ADD_NEW_REQUEST",
  POPUP_TYPE_RENAME: "POPUP_TYPE_RENAME",

  CONTEXT_APIS: "apis",
  CONTEXT_ENV: "env",
  CONTEXT_FLOW: "flow",
  CONTEXT_HISTORY: "history",

  GLOBAL_VARIABLES_ENV_ID: "0",
  NO_ENV_SELECTED_ID: "-1",
};
