export const headerItemKeys = [
  "Accept",
  "Accept-Charset",
  "Accept-Datetime",
  "Accept-Encoding",
  "Accept-Language",
  "Access-Control-Request-Method",
  "Access-Control-Request-Headers",
  "Authorization",

  "Cache-Control",
  "Connection",
  "Content-Encoding",
  "Content-Length",
  "Content-MD5",
  "Content-Type",
  "Cookie",

  "Date",
  "Expect",
  "Forwarded",
  "From",
  "Host",
  "HTTP2-Settings",

  "If-Match",
  "If-Modified-Since",
  "If-None-Match",
  "If-Range",
  "If-Unmodified-Since",

  "Max-Forwards",
  "Origin",
  "Pragma",
  "Prefer",
  "Proxy-Authorization",
  "Range",
  "Referer ",
  "TE",
  "Trailer",
  "Transfer-Encoding",
  "User-Agent",
  "Upgrade",
  "Via",
  "Warning",
];

export const headerItemValues = [
  "application/json",
  "application/pdf",
  "application/msword ",
  "application/font+woff",
  "application/postscript",
  "application/soap+xml",
  "application/sql",

  "application/xml",
  "application/x-yaml",
  "application/x-www-form-urlencoded",

  "application/gzip",
  "application/zip",
  "application/zstd",

  "application/vnd.api+json",
  "application/vnd.microsoft.portable-executable",
  "application/vnd.ms-word", //(.doc)
  "application/vnd.ms-excel", //(.xls)
  "application/vnd.ms-powerpoint", //(.ppt)
  "application/vnd.oasis.opendocument.text", //(.odt)
  //   "application/vnd.openxmlformats-officedocument.presentationml.presentation", //(.pptx)
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //(.xlsx)
  //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //(.docx)

  "audio/basic",
  "audio/L24",
  "audio/mpeg",
  "audio/ogg",

  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/gif",
  "image/tiff",

  "model/obj",

  "message/https",
  "message/partial",
  "message/rfc822",

  "multipart/mixed",
  "multipart/alternative",
  "multipart/related ",
  "multipart/form-data",
  "multipart/signed",
  "multipart/encrypted",

  "text/plain",
  "text/css",
  "text/csv",
  "text/html",
  "text/javascript",
  "text/xml",
];
