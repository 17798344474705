import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { Cookies } from "react-cookie";
import { showAlert } from "../../../store/alert/alertActions";
import { showLoader, hideLoader } from "../../../store/loader/loaderActions";
import { saveUserAuthDetails } from "../../../store/auth/authActions";
import { history } from "../../../store/configure/configureStore";
import { HOME } from "../../../navigation/routes";
import logo from "../../../assets/images/logo-ssi-new.png";
import { setSelectedContext } from "../../../store/context/contextActions";
import { setupGlobalEnvironment } from "../../../store/environment/environmentActions";
import {
  getUsersReadAccessIds,
  fetchUsersCollections,
  uploadUserLoginDetailsInDB,
  fetchUsersEnvironmentsAndVariable,
} from "../../../firebase";
import "./LoginSuccessStyles.scss";
import { stringConstants } from "../../../constants/stringConstants";

const cookies = new Cookies();

export class LoginSuccessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showLoader: true };
    this.ReadCookies = this.ReadCookies.bind(this);
    this.ReadQueryParams = this.ReadQueryParams.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.ReadQueryParams();
    // this.ReadCookies();
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.props = nextProps;
    }
  }

  ReadCookies = async () => {
    /*
    
    ********** NOT USED ANYMORE ******************
     
    this.props.showLoader();

    var userDetails = cookies.get("usercredentials");
    console.warn("ReadCookies :  " + JSON.stringify(userDetails, null, 4));

    // DUMMY DATA FOR DEBUG ---------------------
    // var userDetails = {
    //   status: "true",
    //   message: "Login was Successful.",
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNwYXdhckBzbWFydHNpZ2h0LmluIiwiaWQiOiI2MzdjOTIxY2MxMDkwZGQxNjIxMmNjYTQiLCJyb2xlSWQiOiI2MTI4YTM4YjZhZWU3NzUyMTJlNWYyYWIiLCJlbXBsb3llZUlkIjoiNjM3YzkyMWNjMTA5MGQ5MGNjMTJjY2E1IiwibGV2ZWwiOjUsImxldmVsTmFtZSI6Ik1hbmFnZXIiLCJpYXQiOjE2ODM2MTA2OTQsImV4cCI6MTY4MzY5NzA5NH0.OE53ihWklwY6vLe34jSYqlFzQV7IwPdtzOUpXQgUHzA",
    //   roleId: "6128a38b6aee775212e5f2ab",
    //   userRole: "Super Admin",
    //   userId: "637c921cc1090dd16212cca4",
    //   employeeId: "637c921cc1090d90cc12cca5",
    //   level: "5",
    //   levelName: "Manager",
    //   email: "spawar@smartsight.in",
    //   firstName: "Sharayu",
    //   lastName: "Pawar",
    // };
    // this.props.saveUserAuthDetails(userDetails);
    //-----------------------------------------

    this.props.saveUserAuthDetails(userDetails);
    this.props.setSelectedContext(stringConstants.CONTEXT_APIS);
    this.props.setupGlobalEnvironment();

    //-----------------------------------------

    uploadUserLoginDetailsInDB(
      userDetails.email,
      userDetails.firstName + " " + userDetails.lastName,
      userDetails.token
    );

    //-----------------------------------------

    var readIds = await getUsersReadAccessIds(userDetails.email);
    console.warn("readIds " + readIds);

    fetchUsersCollections(readIds, () => {
      console.warn("fetchUsersCollections CALLBACK");
    });

    fetchUsersEnvironmentsAndVariable(userDetails.email);

    //-----------------------------------------

    setTimeout(() => {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.loginSuccess,
      });

      this.props.history.replace(HOME);
    }, 1500);

    */
  };

  ReadQueryParams = async () => {
    this.props.showLoader();

    const queryParams = new URLSearchParams(window.location.search);
    console.log({ queryParams }); // ▶ URLSearchParams {}

    var userDetails = {
      status: queryParams.get("status"),
      message: queryParams.get("message"),
      token: queryParams.get("token"),
      roleId: queryParams.get("roleId"),
      userRole: queryParams.get("userRole"),
      userId: queryParams.get("userId"),
      employeeId: queryParams.get("employeeId"),
      level: queryParams.get("level"),
      levelName: queryParams.get("levelName"),
      email: queryParams.get("email"),
      firstName: queryParams.get("firstName"),
      lastName: queryParams.get("lastName"),
    };

    console.warn("ReadQueryParams :  " + JSON.stringify(userDetails, null, 4));

    //-----------------------------------------

    this.props.saveUserAuthDetails(userDetails);
    this.props.setSelectedContext(stringConstants.CONTEXT_APIS);
    this.props.setupGlobalEnvironment();

    //-----------------------------------------

    uploadUserLoginDetailsInDB(
      userDetails.email,
      userDetails.firstName + " " + userDetails.lastName,
      userDetails.token
    );

    //-----------------------------------------

    var readIds = await getUsersReadAccessIds(userDetails.email);
    console.warn("readIds " + readIds);

    fetchUsersCollections(readIds, () => {
      console.warn("fetchUsersCollections CALLBACK");
    });

    fetchUsersEnvironmentsAndVariable(userDetails.email);

    //-----------------------------------------

    setTimeout(() => {
      this.props.hideLoader();
      this.props.showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.loginSuccess,
      });

      this.props.history.replace(HOME);
    }, 1500);
  };

  render() {
    return (
      <div className="login-success-container">
        <div className="login-success-header">
          <img className="img-logo" src={logo} />
        </div>
      </div>
    );
  }
}

LoginSuccessContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
  showAlert,
  history,
  saveUserAuthDetails,
  setSelectedContext,
  setupGlobalEnvironment,
})(LoginSuccessContainer);
