import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import { showLoader, hideLoader } from "../../../../store/loader/loaderActions";
import {
  addNewEnvironment,
  setClickedEnvironmentId,
} from "../../../../store/environment/environmentActions";
import {
  fetchUsersEnvironmentsAndVariable,
  syncUsersEnvironmentsAndVariable,
} from "../../../../firebase";
import { generateRandomNodeId } from "../../../../utils/apiDataUtil";
import { validateTextInput } from "../../../../utils/validator";
import { getState } from "../../../../store/configure/configureStore";
import { stringConstants } from "../../../../constants/stringConstants";
import EnvSideBarPanel from "./EnvSideBarPanel";
import "./EnvSideBarStyles.scss";

export class EnvSideBarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newEnvironmentName: "",
      newEnvSelectedCollection: null,
      showAddNewEnvironmentPopup: false,
    };

    this.onAddNewEnvClick = this.onAddNewEnvClick.bind(this);
    this.onNewEnvironmentNameChange =
      this.onNewEnvironmentNameChange.bind(this);
    this.onCollectionListClick = this.onCollectionListClick.bind(this);
    this.onAddEnvironmentConfirmClick =
      this.onAddEnvironmentConfirmClick.bind(this);
    this.onAddEnvironmentCancelClick =
      this.onAddEnvironmentCancelClick.bind(this);

    this.onSyncEnvDataClicked = this.onSyncEnvDataClicked.bind(this);
    this.onFetchEnvDataClicked = this.onFetchEnvDataClicked.bind(this);

    this.onEnvironmentListClick = this.onEnvironmentListClick.bind(this);
    this.onGlobalEnvClick = this.onGlobalEnvClick.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    // console.log("componentWillReceiveProps!!");
    if (this.props !== newProps) {
      this.props = newProps;
    }
  }

  componentWillUpdate() {
    // console.log("componentWillUpdate!!");
  }

  onAddNewEnvClick = () => {
    this.setState({ showAddNewEnvironmentPopup: true });
  };

  onNewEnvironmentNameChange = (event) => {
    var name = event.target.value;
    this.setState({ newEnvironmentName: name });
  };

  onCollectionListClick = (item) => {
    this.setState({ newEnvSelectedCollection: item });
  };

  onAddEnvironmentConfirmClick = () => {
    if (!validateTextInput(this.state.newEnvironmentName)) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.envNameErrorTitle,
        type: "danger",
        msg: stringConstants.envNameErrorMessage,
      });
    } else if (this.state.newEnvSelectedCollection === null) {
      this.props.showAlert({
        isOpen: true,
        title: stringConstants.envCollectionErrorTitle,
        type: "danger",
        msg: stringConstants.envCollectionErrorMessage,
      });
    } else {
      //Add new Env in redux along with collection it is associataed with
      var env = {
        id: generateRandomNodeId(this.state.newEnvironmentName),
        name: this.state.newEnvironmentName,
        collectionId: this.state.newEnvSelectedCollection.id,
        selected: false,
      };

      this.props.addNewEnvironment(env);

      this.setState({
        showAddNewEnvironmentPopup: false,
        newEnvironmentName: "",
        newEnvSelectedCollection: null,
      });
    }
  };

  onAddEnvironmentCancelClick = () => {
    this.setState({
      showAddNewEnvironmentPopup: false,
      newEnvironmentName: "",
      newEnvSelectedCollection: null,
    });
  };

  onSyncEnvDataClicked = async () => {
    this.props.showLoader();

    await syncUsersEnvironmentsAndVariable(
      getState().auth.userEmail,
      getState().environment.environments,
      getState().environment.variables
    );

    this.props.hideLoader();

    this.props.showAlert({
      isOpen: true,
      title: "Success",
      type: "success",
      msg: stringConstants.syncEnvironmentSuccess,
    });
  };

  onFetchEnvDataClicked = async () => {
    this.props.showLoader();

    await fetchUsersEnvironmentsAndVariable(getState().auth.userEmail);

    this.props.hideLoader();
    this.props.showAlert({
      isOpen: true,
      title: "Success",
      type: "success",
      msg: stringConstants.fetchEnvironmentSuccess,
    });
  };

  onEnvironmentListClick = (item) => {
    //Here show list of ENV's variables in Main Content Screen
    this.props.setClickedEnvironmentId(item.id);
  };

  onGlobalEnvClick = () => {
    //Here show list of GLOBAL variables in Main Content Screen
    this.props.setClickedEnvironmentId(stringConstants.GLOBAL_VARIABLES_ENV_ID);
  };

  render() {
    return (
      <EnvSideBarPanel
        environments={this.props.environments}
        collections={this.props.collections}
        selectedEnvId={this.props.selectedEnvId}
        //--------------
        onAddNewEnvClick={this.onAddNewEnvClick}
        showAddNewEnvironmentPopup={this.state.showAddNewEnvironmentPopup}
        newEnvironmentName={this.state.newEnvironmentName}
        onNewEnvironmentNameChange={this.onNewEnvironmentNameChange}
        newEnvSelectedCollection={this.state.newEnvSelectedCollection}
        onCollectionListClick={this.onCollectionListClick}
        onAddEnvironmentConfirmClick={this.onAddEnvironmentConfirmClick}
        onAddEnvironmentCancelClick={this.onAddEnvironmentCancelClick}
        //-------------
        onSyncEnvDataClicked={this.onSyncEnvDataClicked}
        onFetchEnvDataClicked={this.onFetchEnvDataClicked}
        //-------------
        onEnvironmentListClick={this.onEnvironmentListClick}
        onGlobalEnvClick={this.onGlobalEnvClick}
      />
    );
  }
}

EnvSideBarContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    collections: state.workspace.collections,
    isEnvClicked: state.environment.isEnvClicked,
    clickedEnvId: state.environment.clickedEnvId,
    selectedEnvId: state.environment.selectedEnvId,
    environments: state.environment.environments,
    timestamp: state.environment.timestamp,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  showLoader,
  hideLoader,
  addNewEnvironment,
  setClickedEnvironmentId,
})(EnvSideBarContainer);
