import axios from "axios";
import { DOMAIN_API_URI, PATH_API_URI } from "./apiConstants";
import { getState, getStore } from "../store/configure/configureStore";

export default class Api {
  static actionsStack = [];

  static methods = {
    GET: "get",
    POST: "post",
    PATCH: "patch",
    PUT: "put",
    DELETE: "delete",
  };

  // static composeRouteUrl(route) {
  //   if (route.startsWith("http")) {
  //     return route;
  //   }
  //   return `${DOMAIN_API_URI}${PATH_API_URI}${route}`;
  // }

  // static get(route, params) {
  //   return Api.request(route, params, undefined, Api.methods.GET);
  // }

  // static put(route, data, params) {
  //   return Api.request(route, params, data, Api.methods.PUT);
  // }

  // static patch(route, params, data) {
  //   return Api.request(route, params, data, Api.methods.PATCH);
  // }

  // static post(route, data, appendHeaders, handleFormError = true) {
  //   return Api.request(
  //     route,
  //     undefined,
  //     data,
  //     Api.methods.POST,
  //     appendHeaders,
  //     handleFormError
  //   );
  // }

  // static delete(route, data, params) {
  //   return Api.request(route, params, data, Api.methods.DELETE);
  // }

  static request(method, url, headers, data, shallRetry = true) {
    console.log("REQUEST Functioin inside api file......");

    return new Promise(async (resolve, reject) => {
      console.log("PROMISE Functioin inside api file......");

      const urlFinal = Api.composeRouteUrl(url);

      let headersFinal = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        // Authorization:
        //   "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjVlNWNiMWQ0MDQ3ODIxYjMxYzcxMzVmZiIsImZ1bGxfbmFtZSI6IkJlbGxhIiwiZW1haWwiOiJiZWxsYUBtYWlsbmF0b3IuY29tIiwicGFzc3dvcmQiOiJwYXNzMTIzIn0sImlhdCI6MTU4MzE0MzUyOH0.dDyTxCZNErvoZpEd5hBsUDsGEieTlbUdCDvluKhvncg",
      };

      // const token = "Bearer " + getState().auth.accessToken;
      // const refreshToken = "Bearer " + getState().auth.refreshToken;

      // const token = Auth.getTokenFromLocalStorage();
      // console.log(getState().auth.accessToken);

      // if (getState().auth.accessToken) {
      //   headers["Authorization"] = token;
      // }

      // if (appendHeaders) {
      //   headers = { ...headers, ...appendHeaders };
      // }

      console.log(
        "BEFORE AXIOS called! \n" +
          method +
          "\n" +
          urlFinal +
          "\n" +
          JSON.stringify(headersFinal) +
          "\n" +
          JSON.stringify(data)
      );

      let params = null;

      axios({
        method,
        urlFinal,
        headersFinal,
        params,
        data,
      })
        .then((resp) => {
          console.log("AXIOS response recieved!!!");

          if (resp.data.msg) {
            Api.alert(resp.data.msg);
          }

          // if (resp.data.rid === "e-auth-14") {
          //       // token expired
          // } else if (resp.data.rid.startsWith("e-")) {
          //   reject(resp.data);
          // } else if (resp.data.rid.startsWith("s-")) {
          return resolve(resp.data);
          // }
        })
        .catch((err) => {
          console.log("AXIOS errror recieved!!!" + err);
          reject(err);
        });
    });
  }
}
