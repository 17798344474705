import { actionTypes } from "./contextActions";

const initialState = {
  // Sidebar Toggle button selected value
  selectedContext: null,
};

export default function context(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SELECT_CONTEXT:
      return { ...state, ...payload };

    default:
      return state;
  }
}
