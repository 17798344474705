import React from "react";
import connect from "react-redux/es/connect/connect";
import { Nav, Container } from "react-bootstrap";
import { ButtonView } from "../../controlsNew";
import { IoCloudUploadOutline, IoCloudDownloadOutline } from "react-icons/io5";
import { showSidebar } from "../../../store/sidebar/sidebarActions";
import "./HeaderStyles.scss";
import { stringConstants } from "../../../constants/stringConstants";

const Header = ({
  userName,
  isUserLoggedIn,
  onLoginClicked,
  onLogoutClicked,
  onImportCollectionClicked,
  onSyncDataClicked,
  onFetchDataClicked,
}) => {
  // console.log("HEADER : " + isUserLoggedIn + " -- " + userName);

  return (
    <div className="header fixed-top">
      <Container fluid>
        <Nav className="header_nav" as="ul">
          <Nav.Item className="header_title d-flex align-items-center" as="li">
            SSI - Checkpoint
          </Nav.Item>

          <div className="button-container ">
            <text style={{ "margin-right": "5px" }}>
              {"Welcome, "} {isUserLoggedIn ? userName + "!" : "Guest!"}
            </text>

            {isUserLoggedIn ? (
              <>
                <ButtonView
                  title={stringConstants.logout}
                  onClick={onLogoutClicked}
                  styles="secondary small"
                />

                {/* <ButtonView
                  title={stringConstants.import}
                  onClick={onImportCollectionClicked}
                  styles="secondary small"
                  tooltip={stringConstants.importCollection}
                />

                <ButtonView
                  // title="Sync All Data"
                  icon={<IoCloudUploadOutline size={15} color="#fff" />}
                  onClick={onSyncDataClicked}
                  styles="secondary small"
                  tooltip={stringConstants.syncData}
                />

                <ButtonView
                  // title="Fetch All Data"
                  icon={<IoCloudDownloadOutline size={15} color="#fff" />}
                  onClick={onFetchDataClicked}
                  styles="secondary small"
                  tooltip={stringConstants.fetchData}
                /> */}
              </>
            ) : null}
          </div>
        </Nav>
      </Container>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.auth.isUserLoggedIn,
  userName: state.auth.userFirstName,
});

export default connect(mapStateToProps, {
  showSidebar,
})(Header);

// <ButtonView
//   title="Login"
//   onClick={onLoginClicked}
//   styles="secondary small"
// />
