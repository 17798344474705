import React from "react";
import {
  CardView,
  IconButtonView,
  RadioButtonGroup,
} from "../../../controlsNew";
import { responseBodyModes } from "../../../../constants/responseBodyModes";
import { stringConstants } from "../../../../constants/stringConstants";
import { abortRequest } from "../../../../service/workspaceService";
import { IoCloseCircleOutline, IoRocketOutline } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import "./ResponseStyles.scss";

const ResponsePanel = (props) => {
  // console.warn(
  //   "RESPONSE PANEL PROPS : responseContentType : " +
  //     props.currentTabApiDetails.responseContentType
  // );

  const conditionallyRenderResponseData = () => {
    // Send Button not clicked, no resposne present
    if (
      props.currentTabApiDetails.responseContentType === undefined ||
      props.currentTabApiDetails.responseContentType === null
    ) {
      return renderPlaceHolderMessage();
    }

    // Loading state
    else if (
      props.currentTabApiDetails.responseContentType ===
      stringConstants.CONTENT_TYPE_LOADING
    ) {
      return renderLoadingState();
    }

    // HTML response
    else if (
      props.currentTabApiDetails.responseContentType.includes(
        stringConstants.CONTENT_TYPE_HTML
      )
    ) {
      return renderHtmlResponse();
    }

    // JSON response
    else if (
      props.currentTabApiDetails.responseContentType.includes(
        stringConstants.CONTENT_TYPE_JSON
      )
    ) {
      return renderJsonResponse();
    }

    // Error response
    else if (
      props.currentTabApiDetails.responseContentType ===
      stringConstants.CONTENT_TYPE_ERROR
    ) {
      return renderErrorMessage();
    }

    //default empty view
    else {
      return null;
    }
  };

  const renderPlaceHolderMessage = () => {
    return (
      <div className="empty py-3">
        <div className="p-3">
          <IoRocketOutline size={25} color="#5d5d5d" />
        </div>

        <text>
          Click <em>Send</em> to get a response!
        </text>
      </div>
    );
  };

  const renderLoadingState = () => {
    return (
      <div className="empty p-4">
        <text className="my-2">Sending Request... </text>

        <CircularProgress
          thickness={2}
          color={"secondary"}
          style={{ marginTop: 20, marginBottom: 20 }}
        />

        <IconButtonView
          icon={<IoCloseCircleOutline size={15} />}
          label="Cancel Request"
          onClick={() => {
            //TODO - Move to Response Container
            abortRequest(props.currentTabApiDetails.request.url.raw);
          }}
        />
      </div>
    );
  };

  const renderJsonResponse = () => {
    return (
      <div className="response">
        <RadioButtonGroup
          selectedValue={props.selectedResponseBodyMode}
          data={responseBodyModes}
          onChange={props.onChangeResponseBodyMode}
        />

        <CardView>
          {props.selectedResponseBodyMode === stringConstants.PRETTY_DATA ? (
            <pre contentEditable={false} className="pretty">
              {JSON.stringify(props.currentTabApiDetails.response, null, 5)}
            </pre>
          ) : (
            <text className="raw">
              {JSON.stringify(props.currentTabApiDetails.response)}
            </text>
          )}
        </CardView>
      </div>
    );
  };

  const renderHtmlResponse = () => {
    let dummyContent =
      "<h2>Pulvinar aliquet lacus habitasse massa in</h2>  <p>Sapien id egestas at sed cursus et id eleifend nam enim malesuada convunc elit vitae vivamus nulla aliquam maecenas ridiculus enim urna consequat tristique.</p><p>Id pellentesque vestibulum sit morbi. Urna purus convallis sem eget. Volutpat malesuada pretium vitae consectetur enim ullamcorper nec consectetur odio enim in gravida fames pretium eros vitae dui.</p> <ul> <li>risus facilisis sed vulputate quis</li> <li>eleifend eget magna scelerisque quam risus cras eget</li>  <li>lacinia mollis interdum urna justo eu in lectus ullamcorper volutpat nunc tincidunt</li>   <li>ultricies nec molestie enim eu vitae lectus placerat aliquam amet ut</li> </ul> <p>Ac placerat rhoncus augue tellus enim sed ut eget fringilla aliquam risus pulvinar vitae imperdiet dictum nam imperdiet. Porttitor gravida morbi ipsum faucibus aenean habitant iaculis dignissim feugiat sem consectetur viverra. Odio senectus egestas lacus tristique facilisis.</p> <h3>In eget sollicitudin pulvinapis s bibendum.</h3> <p>Elit libero egestas augue tellus enim sed ut eget fringilla aliquam risus pulvinar vitae imperdiet dictum nam imperdiet. Porttitor gravida morbi ipsum faucibus aenean habitant iaculis dignissim feugiat sem consectetur vivegestas nunc pulvinar diam integer interdum viverra tortor est diam aliquet nunc</p> <p>Tempor leo pulltrices lobortis neque id orci vestibulum. Eget cursus risus lacus cras ornare neque.</p>";

    return (
      <div className="response">
        <CardView>
          <div>{ReactHtmlParser(props.currentTabApiDetails.response)}</div>

          {/* <div>{ReactHtmlParser(dummyContent)}</div> */}

          {/* <text className="raw">{props.currentTabApiDetails.response}</text> */}
        </CardView>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="response">
        <CardView>
          <text className="response-error">
            {props.currentTabApiDetails.responseError}
          </text>
        </CardView>
      </div>
    );
  };

  return (
    <div className="response-container">
      <text>
        {props.currentTabApiDetails.responseContentType ===
        stringConstants.CONTENT_TYPE_ERROR
          ? "Error"
          : "Response"}
      </text>
      <div className="hr" />

      {conditionallyRenderResponseData()}
    </div>
  );
};

export default ResponsePanel;
