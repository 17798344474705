import { stringConstants } from "../constants/stringConstants";
import { getState } from "../store/configure/configureStore";
import { getVariablesForEnvironment } from "../store/environment/environmentActions";
import apiClientService from "./apiClientService";

// List of all Abort Controllers, with items corresponding to api requests which are currently running.
const ABORT_REQUEST_CONTROLLERS = new Map();

// Get variable value from name
function getValueOfEnvVariable(variableName) {
  var valueOfVariable = null;
  var allEnvVariables = [];

  var envId = getState().environment.selectedEnvId;
  if (envId !== stringConstants.NO_ENV_SELECTED_ID) {
    allEnvVariables = getVariablesForEnvironment(envId);
  }

  var globalVariables = getVariablesForEnvironment(
    stringConstants.GLOBAL_VARIABLES_ENV_ID
  );

  allEnvVariables = allEnvVariables.concat(globalVariables);
  // console.log("POST CONCAT : allEnvVariables : " + JSON.stringify(allEnvVariables));

  // check if variableName is among these allEnvVariables ( Selected env and Global env )
  for (let index = 0; index < allEnvVariables.length; index++) {
    const element = allEnvVariables[index];
    if (String(element.name) === String(variableName)) {
      valueOfVariable = element.currentValue;
      break;
    }
  }

  // console.log("GET VALUE : " + variableName + " --> " + valueOfVariable);

  // return currentValue of that variable
  return valueOfVariable;
}

// Update env variables in given input string
function updateEnvVariableValuesInText(inputText) {
  // var regex = /(?<=\{\{)(.*?)(?=\}\})/;
  // var variablesFound = regex.exec(inputText);
  // console.log("variablesFound : " + JSON.stringify(variablesFound));

  var variablesFound = inputText.match(/\{\{.*?\}\}/g);
  // console.log("VariablesFound in inputText: " + JSON.stringify(variablesFound));

  // If one or more {{variable}} are found in inputText
  if (variablesFound && variablesFound.length > 0) {
    for (let index = 0; index < variablesFound.length; index++) {
      const element = variablesFound[index];
      var newValue = getValueOfEnvVariable(element.slice(2, -2));
      console.log("REPLACE : element, value  : " + element + ", " + newValue);
      inputText = inputText.replace(element, newValue);
    }
    return inputText;
  }

  // If no variables found in inputText, return it as it is
  else {
    return inputText;
  }
}

// Abort previous requests with same key (if any), and create a new AbortController for key.
function getNewAbortControllerSignal(key) {
  abortRequest(key);
  const newController = new AbortController();
  ABORT_REQUEST_CONTROLLERS.set(key, newController);
  return newController.signal;
}

// ABORT REQUEST
export function abortRequest(key, reason = "Operation Aborted!") {
  ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

// SEND REQUEST
export function makeApiRequest(
  method,
  url,
  headers,
  bodyType,
  rawDataForBody,
  formDataForBody,
  callback
) {
  // console.log(
  //   "makeApiRequest : method, url, headers, bodyType, rawDataForBody , formDataForBody: \n" +
  //     method +
  //     ", \n" +
  //     url +
  //     ", \n" +
  //     JSON.stringify(headers) +
  //     ", \n" +
  //     bodyType +
  //     ", \n" +
  //     JSON.stringify(rawDataForBody) +
  //     ", \n" +
  //     JSON.stringify(formDataForBody)
  // );

  var data = {
    method: method,
    headers: {},

    signal: getNewAbortControllerSignal(url), // url is used as key
  };

  if (headers.length > 0) {
    headers.forEach((headerItem) => {
      // Replace {{variable}} in Header Item value (headerItem.value)
      data.headers[headerItem.key] = updateEnvVariableValuesInText(
        headerItem.value
      );
    });
  }

  if (bodyType === stringConstants.RAW_DATA) {
    if (method !== "get" && rawDataForBody !== null) {
      data["body"] = JSON.stringify(rawDataForBody);
    }
  } else if (bodyType === stringConstants.FORM_DATA) {
    data["body"] = new FormData();
    formDataForBody.forEach((formDataItem) => {
      // Replace {{variable}} in Form Data Item value (formDataItem.value)
      data["body"].append(
        formDataItem.key,
        updateEnvVariableValuesInText(formDataItem.value)
      );
    });
  }

  console.warn("makeApiRequest : FINAL DATA OBJECT : ");
  console.warn(data);

  url = updateEnvVariableValuesInText(url);
  console.warn("makeApiRequest : FINAL URL : " + url);

  apiClientService.perform(
    url,
    data,
    (contentType, headers, responseData, error) => {
      console.warn("CALLLLBACKKKK : makeApiRequest");
      // console.log("contentType : " + contentType);
      // console.log("headers : ");
      // console.log(headers);
      // console.log("responseData : ");
      // console.log(responseData);

      callback(contentType, headers, responseData, error);
    }
  );
}
