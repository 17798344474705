import { stringConstants } from "./stringConstants";

export const responseBodyModes = [
  {
    label: "Raw",
    value: stringConstants.RAW_DATA,
  },
  {
    label: "Pretty",
    value: stringConstants.PRETTY_DATA,
  },
];
