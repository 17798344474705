import React from "react";
import PropTypes from "prop-types";
import "./CheckboxStyles.scss";
import Checkbox from "@mui/material/Checkbox";
import { FaCheckCircle, FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import variables from "../../../assets/styles/_variables.scss";

const CheckboxItem = ({ onCheckedChange, label, checked, ...props }) => {
  return (
    <Checkbox
      {...label}
      checkedIcon={<FaCheckCircle size={15} color={variables.secondaryColor} />}
      icon={<FaRegCircle size={15} color={variables.grey} />}
      onChange={onCheckedChange}
      checked={checked}
      // disableRipple
    />
  );
};

CheckboxItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default CheckboxItem;
