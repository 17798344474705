import { stringConstants } from "./stringConstants";

export const requestBodyModes = [
  {
    label: "Raw",
    value: stringConstants.RAW_DATA,
  },
  {
    label: "Form Data",
    value: stringConstants.FORM_DATA,
  },
];
