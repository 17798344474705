import { actionTypes } from "./workspaceActions";

const initialState = {
  // Identifier for whether to show request and response panel or not
  noTabsOpen: true,

  // All api collections in workspace (array of --> tree structured objects for each collection )
  collections: [],

  // All node objects which may be folder or API
  nodes: {},

  // All Open APIs in workspace  (it can be [emptyTabDetailsObject] )
  openTabs: [],

  // Current selected tab API Id  (it can be "new_tab" )
  currentTabApiId: null,

  // Current selected tab API details  (it can be emptyTabDetailsObject )
  currentTabApiDetails: {},

  // Timestamp as a temp variable while updating redux data
  timestamp: null,
};

export default function workspace(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.IMPORT_COLLECTION:
      return { ...state, ...payload };

    case actionTypes.CREATE_NEW_COLLECTION:
      return { ...state, ...payload };

    case actionTypes.ADD_NEW_FOLDER:
      return { ...state, ...payload };

    case actionTypes.SAVE_NODE:
      return { ...state, ...payload };

    case actionTypes.FETCH_NODE:
      return { ...state, ...payload };

    case actionTypes.OPEN_EMPTY_TAB:
      return { ...state, ...payload };

    case actionTypes.OPEN_API_IN_TAB:
      return { ...state, ...payload };

    case actionTypes.SWITCH_API_TAB:
      return { ...state, ...payload };

    case actionTypes.CLOSE_API_TAB:
      return { ...state, ...payload };

    // case actionTypes.SAVE_NEW_API:
    //   return { ...state, ...payload };

    // case actionTypes.SAVE_EXISTING_API:
    //   return { ...state, ...payload };

    case actionTypes.UPDATE_CURRENT_OPEN_API:
      return { ...state, ...payload };

    case actionTypes.UPDATE_NODE_DATA_ON_SAVE:
      return { ...state, ...payload };

    case actionTypes.UPDATE_COLLECTION_NODE_DATA_ON_SAVE:
      return { ...state, ...payload };

    case actionTypes.UPDATE_OPEN_TABS_DATA_ON_RENAME:
      return { ...state, ...payload };

    case actionTypes.RUN_API:
      return { ...state, ...payload };

    case actionTypes.UPDATE_API_RESPONSE_AS_LOADING:
      return { ...state, ...payload };

    case actionTypes.DELETE_COLLECTION:
      return { ...state, ...payload };

    case actionTypes.DELETE_NODE:
      return { ...state, ...payload };

    case actionTypes.RESET_ALL_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
}
