import React from "react";
import { Route, withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import MainLayout from "../mainLayout/mainLayout";

const RestrictedRoute = ({ component: Component, path, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // return (
        //   <MainLayout>
        //     <Component {...props} />
        //   </MainLayout>
        // );

        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(RestrictedRoute));
