import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TextArea, IconButtonView, Dropdown } from "../..";
import { IoTrashOutline } from "react-icons/io5";
import { stringConstants } from "../../../../constants/stringConstants";
import "./ListItemVariablesStyles.scss";

const ListItemVariables = ({
  dataObject, //{ name, initialValue, currentValue, envId }
  onDataChange,
  onDeleteItemClick,
  styles,
}) => {
  function onNameChange(event) {
    let newName = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.name = newName;
    onDataChange(newDataObject, dataObject.name);
  }

  function onInitialValueChange(event) {
    let newDataObject = { ...dataObject };
    newDataObject.initialValue = event.target.value;
    onDataChange(newDataObject, dataObject.name);
  }

  function onCurrentValueChange(event) {
    let newValue = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.currentValue = newValue;
    onDataChange(newDataObject, dataObject.name);
  }

  function onDeleteClicked(event) {
    console.log("onDeleteClicked");
    onDeleteItemClick(dataObject.name);
  }

  return (
    <div className="dynamic-list-item">
      {/* <div className="check-mark">
        <Checkbox
          checked={dataObject.checked}
          onCheckedChange={onCheckedChange}
        />
      </div> */}

      <div className="input-wide">
        <TextArea
          noOutline
          value={dataObject.name}
          placeholder={"Name"}
          onChange={onNameChange}
        />
      </div>

      <div className="input-wide">
        <TextArea
          noOutline
          value={dataObject.initialValue}
          placeholder={"Initial Value"}
          onChange={onInitialValueChange}
        />
      </div>

      <div className="input-wide">
        <TextArea
          noOutline
          value={dataObject.currentValue}
          placeholder={"Current Value"}
          onChange={onCurrentValueChange}
        />
      </div>

      <div className="delete-mark">
        <IconButtonView
          icon={<IoTrashOutline size={15} color="#000" />}
          styles={{ width: "10px" }}
          onClick={onDeleteClicked}
        />
      </div>
    </div>
  );
};

ListItemVariables.propTypes = {
  dataObject: PropTypes.object,
  onDataChange: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ListItemVariables.defaultProps = {
  disabled: false,
};

export default ListItemVariables;
