import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/configure/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { CookiesProvider } from "react-cookie";
import { globals } from "./store/globals";
import { Switch, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { ConnectedRouter } from "connected-react-router";
import { Alert, BigLoader } from "./components/common";
import { ThemeProvider, createTheme } from "@mui/material";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import App from "./components/pages";
import "./App.scss";

const finalTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#242034",
    },
    secondary: {
      main: "#d87d2e",
    },
  },

  typography: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeightLight: 200,
  },

  // Styling for individual components (File level theming code kept as commented in Dropdown.js.
  // File level theming code is deleted from all other components, as everything is added here now.

  components: {
    // DropDown
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "font-size": 13,
          lineHeight: 1,
        },
      },
    },

    // DropDown
    MuiSelect: {
      styleOverrides: {
        select: {
          "font-size": 12,
          height: "15px",
          maxHeight: "15px",
          minHeight: "15px",
          lineHeight: 1,
        },
      },
    },

    // Dropdown list item and TreeItem's menu list item (dense)
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "font-size": 11,
          lineHeight: 1,
        },
        dense: {
          "font-size": 11,
          margin: "-2px",
        },
      },
    },

    // TreeItem
    MuiTreeItem: {
      styleOverrides: {
        label: {
          "font-size": 13,
          "padding-top": "4px",
          "padding-bottom": "4px",
        },
      },
    },

    // TabViewSimple
    MuiTabs: {
      styleOverrides: {
        root: {
          "min-height": "32px",
          "max-height": "32px",
        },
        indicator: {
          "background-color": "#d87d2e",
        },
      },
    },

    // TabViewSimple
    MuiTab: {
      styleOverrides: {
        root: {
          "min-height": "30px",
          "max-height": "30px",
        },
        textColorPrimary: {
          "font-size": 10,
        },
      },
    },

    // Radiobutton and Swich component
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "font-size": 11,
          "padding-top": "4px",
          "padding-bottom": "4px",
        },
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<text>Loading...</text>} persistor={persistor}>
      <ReactNotifications />
      <BigLoader />
      <Alert />
      <CookiesProvider>
        <ConnectedRouter history={globals.history}>
          <Switch>
            <ThemeProvider theme={finalTheme}>
              <Route path="/" component={App} />
            </ThemeProvider>
          </Switch>
        </ConnectedRouter>
      </CookiesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
