import moment from "moment";
import { stringConstants } from "../constants/stringConstants";
import { generateRandomNodeId } from "./apiDataUtil";
import { getStore } from "../store/configure/configureStore";
import { showAlert } from "../store/alert/alertActions";

export const parseCurlToJsonObject = (curl, requestName) => {
  //-------------------------------------------------------------------------------
  // Logic for error handling
  var numberOfNonKeywordItems = 0; // items not in format of --XYZ  --ABC etc.           (This count > 0 means curl is incorrect)
  var numberOfNonParsedItems = 0; // items with keywords other than those 5 we handled   (This count > 0 means curl is partially parsed)

  //-------------------------------------------------------------------------------
  // First of all, remove white spaces and 'curl' word from curl input.
  var curlString = curl.trim();
  if (curlString.toLowerCase().includes("curl")) {
    curlString = curlString.slice(4);
    curlString = curlString.trim();
  } else {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: stringConstants.importCurlErrorTitle,
        type: "danger",
        msg: stringConstants.importCurlErrorIncorrectCurl,
      })
    );
    return null;
  }
  console.log("CURL UPDATED : ", curlString);

  //-------------------------------------------------------------------------------
  // Dig into curlString and separate all it's parts in a array
  var emptyArray = [];
  var arrayOfItems = digCurlForItems(emptyArray, curlString);
  console.warn("CURL arrayOfItems :", JSON.stringify(arrayOfItems, null, 2));

  //-------------------------------------------------------------------------------
  // Sample jsonObject for new tab, we will update values here
  var newTabJson = {
    id: generateRandomNodeId(),
    name: requestName,
    title: requestName,
    type: stringConstants.NODE_TYPE_API,

    request: {
      method: "",
      url: { raw: "" },
      header: [],
      body: {},
    },
    response: {},
    responseContentType: null,
    isUpdated: true,
    isUnsavedNewTab: true,
    updateTimestamp: moment().toString(),
  };

  //-------------------------------------------------------------------------------
  // Check for keywords in curl item and add item to jsonObject after removing the keywords
  for (let index = 0; index < arrayOfItems.length; index++) {
    var curlItem = arrayOfItems[index]; // original curl item with --header --data etc
    var trimmedCurlItem = ""; //final value after removing keywords in item

    // Remove empty spaces and single, double quotes from original curl item
    curlItem = curlItem.trim();
    curlItem = curlItem.replace(/'/g, "");
    if (
      !curlItem.includes("--data-raw") &&
      !curlItem.includes("--data") &&
      !curlItem.includes("-d")
    ) {
      curlItem = curlItem.replace(/"/g, "");
    }

    //#1
    if (curlItem.includes("--location")) {
      trimmedCurlItem = curlItem.slice(10);

      if (trimmedCurlItem.includes("--globoff")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(9);
      }

      if (trimmedCurlItem.includes("--request")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(9);
      }

      //-- If method is specified after request param ....
      if (trimmedCurlItem.includes("GET")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(3);
        newTabJson.request.method = "GET";
      } //--
      else if (trimmedCurlItem.includes("POST")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(4);
        newTabJson.request.method = "POST";
      } //--
      else if (trimmedCurlItem.includes("PUT")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(3);
        newTabJson.request.method = "PUT";
      } //--
      else if (trimmedCurlItem.includes("PATCH")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(5);
        newTabJson.request.method = "PATCH";
      } //--
      else if (trimmedCurlItem.includes("DELETE")) {
        trimmedCurlItem = trimmedCurlItem.trim();
        trimmedCurlItem = trimmedCurlItem.slice(6);
        newTabJson.request.method = "DELETE";
      }

      newTabJson.request.url.raw = trimmedCurlItem.trim();
    }

    //#2
    else if (curlItem.includes("--header") || curlItem.includes("-H")) {
      trimmedCurlItem = curlItem.includes("--header")
        ? curlItem.slice(8)
        : curlItem.slice(2);

      var headerItemAsArray = trimmedCurlItem.split(":");
      newTabJson.request.header.push({
        key: headerItemAsArray[0].trim(),
        value: headerItemAsArray[1].trim(),
        description: "",
        checked: true,
      });
    }

    //#3
    else if (
      curlItem.includes("--data-raw") ||
      curlItem.includes("--data") ||
      curlItem.includes("-d")
    ) {
      trimmedCurlItem = curlItem.includes("--data-raw")
        ? curlItem.slice(10)
        : curlItem.includes("--data")
        ? curlItem.slice(6)
        : curlItem.slice(2);

      newTabJson.request.body["raw"] = trimmedCurlItem.trim();
      newTabJson.request.body["mode"] =
        newTabJson.request.body.mode ?? stringConstants.RAW_DATA;
      newTabJson.request["method"] =
        newTabJson.request.method === "" ? "POST" : newTabJson.request.method;
    }

    //#4
    else if (
      curlItem.includes("--form-string") ||
      curlItem.includes("--form") ||
      curlItem.includes("-F")
    ) {
      trimmedCurlItem = curlItem.includes("--form-string")
        ? curlItem.slice(13)
        : curlItem.includes("--form")
        ? curlItem.slice(6)
        : curlItem.slice(2);

      var formItemAsArray = trimmedCurlItem.split("=");
      newTabJson.request.body["formdata"] =
        newTabJson.request.body.formdata ?? [];

      newTabJson.request.body.formdata.push({
        key: formItemAsArray[0].trim(),
        value: formItemAsArray[1].trim(),
        description: "",
        type: formItemAsArray[1].includes("@") ? "file" : "text",
        checked: true,
      });

      newTabJson.request.body["mode"] =
        newTabJson.request.body.mode ?? stringConstants.FORM_DATA;
      newTabJson.request["method"] =
        newTabJson.request.method === "" ? "POST" : newTabJson.request.method;
    }

    //#5
    else if (curlItem.includes("--url")) {
      trimmedCurlItem = curlItem.slice(5);
      newTabJson.request.url.raw = trimmedCurlItem.trim();
    }

    //#6
    else if (curlItem.includes("--")) {
      numberOfNonParsedItems++;
    }

    //#7
    else {
      numberOfNonKeywordItems++;
    }
  }

  //-------------------------------------------------------------------------------
  // Finally return newTabJson or Null in case of
  console.log("FINAL JSON FOR CURL: ", JSON.stringify(newTabJson, null, 4));

  if (numberOfNonKeywordItems > 0) {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: stringConstants.importCurlErrorTitle,
        type: "danger",
        msg: stringConstants.importCurlErrorIncorrectCurl,
      })
    );
    return null;
  } else if (numberOfNonParsedItems > 0) {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: stringConstants.importCurlErrorPartialCorrectCurl,
      })
    );
    return newTabJson;
  }

  return newTabJson;
};

export const digCurlForItems = (arrayOfItems, curlString) => {
  // console.log( "digCurlForItems : ", JSON.stringify(arrayOfItems, null, 4), "  ,  ",  curlString);

  var newArray = [];

  if (curlString.includes("\\")) {
    var index = curlString.indexOf("\\");
    arrayOfItems.push(curlString.substr(0, index));
    digCurlForItems(arrayOfItems, curlString.substr(index + 1));
  } else {
    arrayOfItems.push(curlString);
  }

  newArray = arrayOfItems;
  return newArray;
};
