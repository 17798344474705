import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch } from "@mui/material";

const SwitchButton = ({
  label,
  checked,
  onCheckedChange,
  disabled,
  styles,
}) => {
  return (
    <FormControlLabel
      labelPlacement="start"
      label={label}
      control={
        <Switch
          checked={checked}
          onChange={onCheckedChange}
          color="warning"
          size="small"
        />
      }
    />
  );
};

SwitchButton.propTypes = {
  disabled: PropTypes.bool,
};

SwitchButton.defaultProps = {
  disabled: false,
};

export default SwitchButton;
