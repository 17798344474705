import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./DropdownStyles.scss";

const Dropdown = ({
  label,
  data,
  value,
  onSelect,
  noBorder,
  noUnderline,
  arrowOnly,
  disabled,
  styles,
}) => {
  // const finalTheme = createTheme({
  //   components: {
  //     MuiInputLabel: {
  //       styleOverrides: {
  //         root: {
  //           "font-family": "Montserrat, sans-serif",
  //           fontSize: "13px",
  //           height: "15px",
  //           lineHeight: 1,
  //         },
  //       },
  //     },
  //
  //     MuiMenuItem: {
  //       styleOverrides: {
  //         root: {
  //           "font-family": "Montserrat, sans-serif",
  //           fontSize: "13px",
  //           height: "15px",
  //           lineHeight: 1,
  //         },
  //       },
  //     },
  //     MuiSelect: {
  //       styleOverrides: {
  //         select: {
  //           "font-family": "Montserrat, sans-serif",
  //           fontSize: "12px",
  //           height: "15px",
  //           maxHeight: "15px",
  //           minHeight: "15px",
  //           lineHeight: 1,
  //         },
  //       },
  //     },
  //   },
  // });

  const renderItems = () => {
    var itemsArray = [];
    // itemsArray.push(
    //   <MenuItem value="">
    //     <em>None</em>
    //   </MenuItem>
    // );

    if (Array.isArray(data)) {
      data.forEach((item) => {
        itemsArray.push(
          <MenuItem dense={true} value={item}>
            {item}
          </MenuItem>
        );
      });
    }

    return itemsArray;
  };

  var sxObject = arrowOnly ? { width: 20 } : { minWidth: 120 };

  return (
    // <ThemeProvider theme={finalTheme}>
    <div className="dropdown-container">
      <FormControl
        variant={noBorder || arrowOnly ? "standard" : null} // HIDES BORDER
        sx={sxObject}
        size="small"
      >
        {noBorder || arrowOnly ? null : ( // HIDES ANIMATED LABEL
          <InputLabel variant="outlined" id="demo-select-small">
            {label}
          </InputLabel>
        )}
        <Select
          // labelId="demo-select-small"
          // id="demo-select-small"
          value={value}
          label={label}
          onChange={onSelect}
          disableUnderline={noUnderline || arrowOnly} //HIDES UNDERLINE
        >
          {renderItems()}
        </Select>
      </FormControl>
    </div>
    // </ThemeProvider>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
};

export default Dropdown;
