export const actionTypes = {
  SELECT_CONTEXT: "SELECT_CONTEXT",
};

/* ----------------  Update selected context -------------------- */

export function setSelectedContext(newContext) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_CONTEXT,
      payload: {
        selectedContext: newContext,
      },
    });
  };
}
