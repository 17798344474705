import React from "react";
import {
  ListViewDynamic,
  ButtonView,
  IconButtonView,
  PopupDialog,
  Input,
} from "../../../controlsNew";
import { HiBadgeCheck } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";
import { SlPuzzle } from "react-icons/sl";
import { FiSave } from "react-icons/fi";
import { CiEdit } from "react-icons/ci";
import { IoTrashOutline } from "react-icons/io5";
import { BsFolder } from "react-icons/bs";
import { stringConstants } from "../../../../constants/stringConstants";
import "./EnvContentStyles.scss";

const EnvContentPanel = (props) => {
  // console.log(
  //   "variablesForClickedEnv :::: " +
  //     JSON.stringify(props.variablesForClickedEnv)
  // );

  function renderEditEnvNamePopupContent() {
    return (
      <Input
        placeholder={stringConstants.addEnvironmentPopupPlaceholder}
        noOutline
        onChange={props.onEditEnvNameInputChange}
        value={props.envNameUpdated}
      />
    );
  }

  return (
    <div className="environment-container">
      <div className="env-title-button-container">
        <div className="buttons-horizontal-container">
          <text className="env-title">{props.clickedEnvData?.name ?? ""}</text>

          {props.clickedEnvData.id !==
          stringConstants.GLOBAL_VARIABLES_ENV_ID ? (
            <IconButtonView
              icon={
                <CiEdit
                  size={18}
                  color="#000"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                />
              }
              tooltip={stringConstants.editEnvironmentNamePopupTitle}
              styles={{ marginLeft: "30px" }}
              onClick={props.onEditEnvNameClick}
            />
          ) : null}
        </div>

        {props.showEditEnvNamePopup === true ? (
          <PopupDialog
            title={stringConstants.editEnvironmentNamePopupTitle}
            message={stringConstants.editEnvironmentNamePopupMessage}
            onConfirmClick={props.onEditEnvNameConfirmClick} //TODO
            onCancelClick={props.onEditEnvNameCancelClick} //TODO
            renderContents={renderEditEnvNamePopupContent()}
          />
        ) : null}

        {props.clickedEnvData.id !== stringConstants.GLOBAL_VARIABLES_ENV_ID ? (
          props.clickedEnvData.selected === false ? (
            <ButtonView
              title={stringConstants.selectEnvironment}
              icon={<HiBadgeCheck size={18} color="#ffff" />}
              styles="secondary huge"
              onClick={props.onSelectEnvironmentClick}
            />
          ) : (
            <ButtonView
              title={stringConstants.deselectEnvironment}
              icon={<RxCrossCircled size={18} color="#555" />}
              styles="grey huge"
              onClick={props.onDeSelectEnvironmentClick}
            />
          )
        ) : null}
      </div>

      <div className="hr" />

      {props.clickedEnvData.id !== stringConstants.GLOBAL_VARIABLES_ENV_ID ? (
        <div className="collection-title">
          <BsFolder size={15} />
          <text className="mx-2">
            {stringConstants.collectionLinkedToEnv}
            <span />
            {props.clickedEnvData?.collectionName ?? ""}
          </text>
        </div>
      ) : null}

      <div className="variable-container">
        <div className="list-title-container">
          {props.variablesForClickedEnv.length > 0 ? (
            <text>{stringConstants.variablesList}</text>
          ) : (
            <text>{stringConstants.noVariablesFound}</text>
          )}
        </div>

        <div className="list-container">
          <ListViewDynamic
            data={props.variablesForClickedEnv}
            onDataChange={props.onChangeVariableData}
            onAddNewItemClick={props.onAddNewVariableClick}
            onDeleteItemClick={props.onDeleteVariableClick}
            type={stringConstants.LIST_TYPE_VARIABLES}
            hideAddItemButton
          />
        </div>

        <div className="buttons-horizontal-container">
          <ButtonView
            title={stringConstants.addVariablePopupTitle}
            icon={<SlPuzzle size={14} color="#fff" />}
            styles="secondary larger"
            onClick={props.onAddNewVariableClick}
          />

          <ButtonView
            title={stringConstants.saveEnvironment}
            icon={<FiSave size={14} color="#fff" />}
            styles="secondary larger"
            onClick={props.onSaveEnvironmentClick}
          />

          {props.clickedEnvData.id !==
          stringConstants.GLOBAL_VARIABLES_ENV_ID ? (
            <ButtonView
              title={stringConstants.deleteEnvironment}
              icon={<IoTrashOutline size={14} color="#000" />}
              styles="grey larger"
              onClick={props.onDeleteEnvironmentClick}
            />
          ) : null}
        </div>

        {props.showDeleteEnvPopup === true ? (
          <PopupDialog
            title={stringConstants.deleteEnvironmentPopupTitle}
            message={stringConstants.deleteEnvironmentPopupMessage}
            onConfirmClick={props.onDeleteEnvConfirmClick}
            onCancelClick={props.onDeleteEnvCancelClick}
            renderContents={() => {}}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EnvContentPanel;
