import { getState, getStore } from "../store/configure/configureStore";
import {
  openSelectedAPI,
  switchToSelectedAPI,
  closeSelectedAPI,
  openEmptyTab,
  saveNode,
  getNodeDetails,
} from "../store/workspace/workspaceActions";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { stringConstants } from "../constants/stringConstants";
import { parseCurlToJsonObject } from "./curlParserUtil";
import { parseCurl } from "curlup";

/*-------------- Add Id, type etc to new collections / folder ------------------*/

export const generateRandomNodeId = (dataObjectName = "") => {
  // Generate a random uuid and append timestamp to it
  var uuid = uuidv4();
  var dateString = moment().toISOString();
  var newId = uuid + "_" + dateString.substring(0, 19);

  // USAGE - FOR DEBUG & DEV ----------------------
  // if (dataObjectName !== "") {
  //   newId = dataObjectName.replace(/\s/g, "").toLowerCase();
  // }
  //------------------------------------------

  console.warn({ newId });
  return newId;
};

export const addIdAndTypeInImportedCollection = (
  dataObject,
  parentConnectingLink,
  isCollection
) => {
  var currentId = generateRandomNodeId(dataObject.name);

  dataObject.id = currentId; // Assign auto generated id to this object
  dataObject.title = dataObject.name; // For TabView title is needed
  dataObject.connectingLink = isCollection
    ? currentId
    : parentConnectingLink + "--" + currentId;

  if (Array.isArray(dataObject.item)) {
    // Children found - It is a folder / collection
    dataObject.type = isCollection
      ? stringConstants.NODE_TYPE_COLLECTION
      : stringConstants.NODE_TYPE_FOLDER;

    // dig deep into folder
    var newItems = [];
    let childrenArray = dataObject.item;
    for (let index = 0; index < childrenArray.length; index++) {
      var element = childrenArray[index];
      var NewElement = addIdAndTypeInImportedCollection(
        element,
        dataObject.connectingLink,
        false
      );

      newItems.push(NewElement);
    }
  } else {
    // No children items found - It's a API
    dataObject.type = isCollection
      ? stringConstants.NODE_TYPE_COLLECTION
      : stringConstants.NODE_TYPE_API;
  }

  // For Nodes DB - save all data of node (request, response etc) along with id, type and connectingLink
  if (!isCollection) {
    getStore().dispatch(saveNode(currentId, dataObject));
  }

  // For collections DB, save only id, title, type and connectingLink
  var newDataObject = {
    id: dataObject.id,
    // name: dataObject.name,
    type: dataObject.type,
    // connectingLink: dataObject.connectingLink,
    item: newItems,
  };

  if (dataObject.type === stringConstants.NODE_TYPE_COLLECTION) {
    // As we are not storing collections in nodes DB, we need a name for TreeView Node.
    newDataObject["name"] = dataObject.name;
  }

  console.warn("dataObject : " + JSON.stringify(dataObject));
  console.warn("newDataObject : " + JSON.stringify(newDataObject));

  return newDataObject;
};

export const addIdAndTypeInNewCollection = (collectionName) => {
  var currentId = generateRandomNodeId(collectionName);
  var dataObject = {};

  dataObject.id = currentId; // Assign auto generated id to this object
  dataObject.name = collectionName; // For Treeview name is needed
  dataObject.type = stringConstants.NODE_TYPE_COLLECTION;
  dataObject.connectingLink = currentId;
  dataObject.item = [];

  console.warn("dataObject : " + JSON.stringify(dataObject));

  return dataObject;
};

export const addIdAndTypeInNewFolder = (folderName, parentConnectingLink) => {
  var currentId = generateRandomNodeId(folderName);
  var dataObject = {};

  dataObject.id = currentId; // Assign auto generated id to this object
  dataObject.name = folderName; // For Treeview name is needed
  dataObject.type = stringConstants.NODE_TYPE_FOLDER;
  dataObject.connectingLink = parentConnectingLink + "--" + currentId;
  dataObject.updateTimestamp = moment().format(
    stringConstants.TIMESTAMP_FORMAT
  );
  dataObject.item = [];

  // For Nodes DB - save all data of node (request, response etc) along with id, type and connectingLink
  getStore().dispatch(saveNode(currentId, dataObject));
  console.warn("dataObject : " + JSON.stringify(dataObject));

  // For collections DB, save only id, title, type and connectingLink
  var newDataObject = {
    id: dataObject.id,
    // name: dataObject.name,
    type: dataObject.type,
    // connectingLink: dataObject.connectingLink,
    item: [],
  };

  return newDataObject;
};

/*-------------- Treeview and Tabview click handles ------------------*/

export const handleTreeViewNodeClick = (nodeId, nodeType) => {
  if (nodeType === stringConstants.NODE_TYPE_API) {
    // Open clicked API details in tabview
    console.log("#1 : API CLICKED! " + nodeId);
    updateCurrentTabDetails(nodeId);
  } else {
    //do nothing on folder click
    console.log("#2 : FOLDER | COLLECTION CLICKED!");
  }
};

export const updateCurrentTabDetails = (id) => {
  console.log("CURRENT TAB API : " + id);
  var nodes = getState().workspace.nodes;
  var nodeObject = nodes[id];

  //To avoid pass-by-reference of redux dataobject, pass a new node object in openTabs[], currentTabApiDetails
  const lodash = require("lodash");
  var nodeCopy = lodash.cloneDeep(nodeObject); // { ...nodeObject };
  nodeCopy.isUpdated = false;

  getStore().dispatch(openSelectedAPI(id, nodeCopy, moment().toString()));

  var emitter = require("tiny-emitter/instance");
  emitter.emit("open-selected-api", nodeCopy.id, nodeCopy.title);
};

export const handleTabViewSwich = (newTabId) => {
  var openTabs = getState().workspace.openTabs;

  for (let index = 0; index < openTabs.length; index++) {
    const tabObject = openTabs[index];
    if (tabObject.id === newTabId) {
      //Id matches
      console.log("Tab Found!");
      getStore().dispatch(
        switchToSelectedAPI(newTabId, tabObject, moment().toString())
      );
      break;
    }
  }
};

export const handleTabViewClose = (id) => {
  getStore().dispatch(closeSelectedAPI(id, moment().toString()));
};

export const handleEmptyTabOpen = (id) => {
  getStore().dispatch(openEmptyTab(id, moment().toString()));
};

export const getTabNameAndUpdatedStatus = (id) => {
  var data = {};
  var openTabs = getState().workspace.openTabs;

  var tabIndex = openTabs.findIndex((tab) => {
    if (tab.id === id) {
      return true;
    } else return false;
  });

  if (tabIndex !== -1) {
    data.isUpdated = openTabs[tabIndex].isUpdated;
    data.title = openTabs[tabIndex].title;
  }

  return data;
};

export const refreshAllTabs = () => {
  var emitter = require("tiny-emitter/instance");
  emitter.emit("refresh-tabs");
};

/*------------ Parse Collection and remove deleted node ids  ------------------*/

export const getCollectionsDataWithoutDeletedItems = (collectionData) => {
  if (Array.isArray(collectionData.item)) {
    // dig deep into collection / folder
    var newItems = [];
    let childrenArray = collectionData.item;

    for (let index = 0; index < childrenArray.length; index++) {
      var element = childrenArray[index];
      var nodeId = element.id;
      var nodeData = getStore().dispatch(getNodeDetails(nodeId));
      // console.log("nodeData: " + JSON.stringify(nodeData));
      if (nodeData) {
        if (
          nodeData.hasOwnProperty("isDeleted") &&
          nodeData.isDeleted === true
        ) {
          // Don't add or parse this item
        } else {
          var NewElement = getCollectionsDataWithoutDeletedItems(element);
          newItems.push(NewElement);
        }
      }
    }
    // console.log("newItems: " + JSON.stringify(newItems));
    collectionData.item = newItems;
  }

  // console.log("BEFORE RETURN : " + collectionData.id + "--> " +  JSON.stringify(collectionData));
  return collectionData;
};

/*--------------------- Imported cURL data handle -----------------------------*/

export const handleCurlImportAndOpenInNewTab = (CURL, requestName) => {
  // const parsed = parseCurl(CURL);
  // console.log("CURL-UP : " , JSON.stringify(parsed, null, 4));

  var newTabJson = parseCurlToJsonObject(CURL, requestName);

  if (newTabJson !== null) {
    getStore().dispatch(
      openSelectedAPI(newTabJson.id, newTabJson, moment().toString())
    );

    var emitter = require("tiny-emitter/instance");
    emitter.emit("open-selected-api", newTabJson.id, newTabJson.title);
  }
};
