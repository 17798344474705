import { stringConstants } from "../constants/stringConstants";

class APIClientService {
  perform(url, data, callback, shallRetry = true) {
    console.log("URL: " + url);

    fetch(url, data)
      .then((response) => {
        console.log("DATA NOW: " + JSON.stringify(data));

        // console.log("response : ");
        // console.log(response);

        // console.log("response.status : " + response.status);
        // console.log("response.ok : " + response.ok);

        // console.log("response.headers : ");
        // console.log(response.headers);
        // console.log(response.headers.get("content-type"));

        var contentType = response.headers.get("content-type");

        return Promise.all([
          contentType,
          response.status,
          response.headers,
          contentType.includes(stringConstants.CONTENT_TYPE_JSON)
            ? response.json()
            : contentType.includes(stringConstants.CONTENT_TYPE_HTML)
            ? response.text()
            : null,
        ]);
      })

      .then(([contentType, status, headers, responseBody]) => {
        console.log("contentType  ------->  " + contentType);

        // JSON response
        if (contentType.includes(stringConstants.CONTENT_TYPE_JSON)) {
          console.log("Success...123: " + JSON.stringify(responseBody));
          return callback(contentType, headers, responseBody, null);
        }

        // HTML response
        else if (contentType.includes(stringConstants.CONTENT_TYPE_HTML)) {
          var parser = new DOMParser();
          var doc = parser.parseFromString(responseBody, "text/html");
          var htmlData = doc.body.innerHTML;
          console.log("Success...123: html data : ");
          console.log(htmlData);
          return callback(contentType, headers, htmlData, null);
        }
      })

      .catch((error) => {
        var contentType = stringConstants.CONTENT_TYPE_ERROR;
        var message = "Some error occurred!";
        if (error.message) {
          message = error.message;
        } else if (error) {
          message = error;
        }
        return callback(contentType, null, null, message);
      });
  }
}

const apiClientService = new APIClientService();
export default apiClientService;
