import React, { useState } from "react";
import PropTypes from "prop-types";
import useDynTabs from "react-dyn-tabs";
import "react-dyn-tabs/style/react-dyn-tabs.min.css";
import "react-dyn-tabs/themes/react-dyn-tabs-bootstrap.min.css";
import {
  handleTabViewSwich,
  handleTabViewClose,
  handleEmptyTabOpen,
  generateRandomNodeId,
  getTabNameAndUpdatedStatus,
} from "../../../utils/apiDataUtil";
import { IconButtonView, ButtonView } from "../../controlsNew";
import { MdAdd, MdOutlineAddLink } from "react-icons/md";
import variables from "../../../assets/styles/_variables.scss";
import "./TabViewDynamicStyles.scss";

const TabViewDynamic = ({
  tabOptions,
  currentTabId,
  onImportCurlClicked,
  disabled,
  styles,
}) => {
  // Handle emitter event when api name clicked in treeview
  var emitter = require("tiny-emitter/instance");
  emitter.once("open-selected-api", function (id, title) {
    openSelectedApi(id, title);
  });

  emitter.once("close-selected-api", function (id) {
    closeSelectedApi(id);
  });
  emitter.once("refresh-tabs", function () {
    refreshInstance();
  });

  // react-dyn-tabs options : tabs array, selected tab id, tabview native functions
  const options = {
    tabs: tabOptions,
    selectedTabID: currentTabId,

    // Add thius tabComponent param to display custom UI component for tabs
    tabComponent: (props) => {
      const { id, title, isSelected, api: instance } = props;
      var tabData = getTabNameAndUpdatedStatus(id);
      // console.warn("tabComponent PROPS :" + JSON.stringify(props));
      // console.log("tabComponent isUpdated :" + tabData.isUpdated);

      return (
        <div className="rc-dyn-tabs-title">
          {tabData.title}

          {tabData.isUpdated ? (
            <i
              class="fa-solid fa-circle fa-2xs"
              style={{
                color: variables.secondaryColor,
                marginRight: "5px",
              }}
            ></i>
          ) : null}
        </div>
      );
    },

    // onLoad: function () {
    //   console.log("[onLoad]");
    // },
    // onInit: function () {
    //   //don't use setState inside the onInit callback because it leads to an infinite loop.
    //   console.log("[onInit]");
    // },
    // onOpen: function () {
    //   console.log("[onOpen]");
    // },
    // beforeSelect: function () {
    //   console.log("[beforeSelect]");
    //   return true;
    // },
    // onFirstSelect: function () {
    //   console.log("[onFirstSelect]");
    // },
    // onSelect: function () {
    //    console.log("[onSelect]");
    // },

    onChange: function () {
      // console.log("[onChange]");
      var newSelectedId = instance.getData().selectedTabID;
      handleTabViewSwich(newSelectedId);
    },

    onClose: function (closedTabID) {
      // console.log("[onClose]");
      handleTabViewClose(closedTabID);
    },
  };

  const [Tablist, Panellist, ready] = useDynTabs(options);

  let instance;
  ready((newInstance) => {
    instance = newInstance;
  });

  function openNewTab() {
    let id = generateRandomNodeId();

    instance
      .open({
        id: id,
        title: "New Request",
      })
      .then(() => {
        console.log("New empty tab is opened");
      });
    instance.select(id);

    handleEmptyTabOpen(id);
  }

  function openSelectedApi(id, title) {
    if (instance.isOpen(id)) {
      instance.select(id).then(() => {
        console.log("The API was already open - same tab is now selected");
      });
    } else {
      instance.open({ id: id, title: title }).then(() => {
        instance.select(id).then(() => {
          console.log("The API is opened in new tab!");
        });
      });
    }
  }

  function closeSelectedApi(id) {
    if (instance.isOpen(id) === true) {
      instance.close(id).then(({ instance }) => {
        console.log("tab " + id + ": is closed");
      });
    }
  }

  function refreshInstance() {
    instance.refresh();
  }

  return (
    <div className="tabs-outer-container">
      <div className="tabs-horizontal-container px-1 py-2">
        <Tablist />

        <div className="tabs-horizontal-container mx-3">
          <IconButtonView
            icon={<MdAdd size={15} color={variables.grey} />}
            tooltip="New Request"
            styles={{ marginLeft: 5 }}
            onClick={openNewTab}
            lightBackground
          />

          <IconButtonView
            icon={<MdOutlineAddLink size={17} color={variables.grey} />}
            tooltip="New CURL Import"
            styles={{ marginLeft: 0 }}
            onClick={onImportCurlClicked}
            lightBackground
          />

          {/* <ButtonView
            title={null}
            icon={<MdAdd size={15} color={variables.grey} />}
            tooltip="New Request"
            styles="grey-outline small"
            onClick={openNewTab}
          />

          <ButtonView
            title={null}
            icon={<MdOutlineAddLink size={17} color={variables.grey} />}
            tooltip="New CURL Import"
            styles="grey-outline small"
            onClick={openNewTab}
          /> */}
        </div>
      </div>

      {/* <Panellist />  */}
    </div>
  );
};

TabViewDynamic.propTypes = {
  tabOptions: PropTypes.array.isRequired,
  style: PropTypes.string,
};

TabViewDynamic.defaultProps = {
  disabled: false,
};

export default TabViewDynamic;
