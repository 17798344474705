import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import "./IconButtonStyles.scss";

const IconButtonView = ({
  icon,
  label,
  onClick,
  lightBackground,
  disabled,
  styles,
  tooltip,
}) => {
  return (
    <div className={`button_control ${lightBackground && "light_background"}`}>
      <div
        className="icon_button"
        onClick={onClick}
        data-tip={tooltip}
        data-for="main"
      >
        {icon}
        {label ? <text style={{ marginLeft: "5px" }}>{label}</text> : null}
      </div>
      {tooltip ? (
        <ReactTooltip
          className="button_tooltip"
          id="main"
          // place="bottom"
          type="light"
          effect="solid"
          backgroundColor="#d7e3f5"
        />
      ) : null}
    </div>
  );
};

IconButtonView.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  style: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

IconButtonView.defaultProps = {
  disabled: false,
  styles: "primal",
  tooltip: "",
};

export default IconButtonView;
