import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import variables from "../../../assets/styles/_variables.scss";
import "./RadioButtonGroupStyles.scss";

const RadioButtonGroup = ({
  data,
  onChange,
  selectedValue,
  className = "",
  error,
  ...props
}) => {
  const renderRadios = (radioDataList) => {
    var radioButtons = [];
    radioDataList.forEach((radioItem) => {
      radioButtons.push(
        <FormControlLabel
          value={radioItem.value}
          control={
            <Radio
              // ------ Styling to change color of Radio Icons ------
              // sx={{
              //   color: variables.secondaryColor,
              //   "&.Mui-checked": {
              //     color: variables.secondaryColor,
              //   },
              // }}
              size="11px"
            />
          }
          label={radioItem.label}
        />
      );
    });
    return radioButtons;
  };

  return (
    <RadioGroup
      row
      // aria-labelledby="demo-row-radio-buttons-group-label"
      // name="row-radio-buttons-group"
      onChange={onChange}
      defaultValue={selectedValue}
      className="radio-label"
      {...props}
    >
      {renderRadios(data)}
    </RadioGroup>
  );
};

RadioButtonGroup.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
};

export default RadioButtonGroup;
