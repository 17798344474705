import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import "./ButtonStyles.scss";
import { propTypes } from "react-bootstrap/esm/Image";

const ButtonView = ({
  title,
  icon,
  onClick,
  disabled,
  styles,
  size,
  tooltip,
  fullWidth,
}) => {
  return (
    <div className={`button_control ${fullWidth && "full-width"}`}>
      <div className="d-grid gap-2">
        <Button
          data-tip={tooltip}
          data-for="main"
          onClick={onClick}
          disabled={disabled}
          className={`${styles} ${size}`}
        >
          <div className="icon_title_container">
            {icon}
            {icon && title ? <div className="icon_title_separator" /> : null}
            {title}
          </div>
        </Button>
      </div>
      <ReactTooltip
        className="button_tooltip"
        id="main"
        place="top"
        type="light"
        effect="solid"
        backgroundColor="#d7e3f5"
      />
    </div>
  );
};

ButtonView.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  title: PropTypes.string,
  style: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonView.defaultProps = {
  disabled: false,
  styles: "primal",
  size: "",
  tooltip: "",
};

export default ButtonView;
