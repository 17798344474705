import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import RestrictedRoute from "../restricted/index";
import HomeContainer from "./HomePage/HomeContainer";
import LoginSuccessContainer from "./LoginSuccessPage/LoginSuccessContainer";
import { HOME, LOGIN_SUCCESS } from "../../navigation/routes";

function App({ match }) {
  // console.log("match:", JSON.stringify(match));

  var defaultRedirection = (
    <Redirect exact from={`${match.url}/`} to={`${match.url}home`} />
  );

  return (
    // <Switch>
    //   {defaultRedirection}
    //   <RestrictedRoute path={`${match.url}home`} component={HomeContainer} />
    //   <RestrictedRoute path={"/login-success"} component={LoginSuccessContainer} />
    // </Switch>

    //-----------------------------------------------------------------------------------------------------
    // <RestrictedRoute --> This renders MainLayout component and inside that renders given child component
    // <Route --> This renders given child component directly

    <Switch>
      {defaultRedirection}
      <RestrictedRoute exact path={HOME} component={HomeContainer} />
      <Route exact path={LOGIN_SUCCESS} component={LoginSuccessContainer} />
    </Switch>
  );
}

export default App;
