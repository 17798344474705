import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, get, child } from "firebase/database";
import { getState, getStore } from "./store/configure/configureStore";
import { stringConstants } from "./constants/stringConstants";
import moment from "moment";

import { replaceAllEnvAndVariablesData } from "./store/environment/environmentActions";

import {
  getCollectionDataFromId,
  getCollectionNodeItemFromId,
  getNodeDetails,
  importCollection,
  saveNode,
} from "./store/workspace/workspaceActions";

import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
} from "../src/constants/env";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

//-------------------  SAMPLE FUNCTIONS ---------------------//
//-----------------------------------------------------------//

/*
export const readAllCollectionsData = (callback) => {
  // const databaseRef = ref(database, "collections/");
  // onValue(databaseRef, (snapshot) => {....})

  const databaseRef = ref(database);
  get(child(databaseRef, "collections/")).then((snapshot) => {
    const data = snapshot.val();
    console.log("readAllCollectionsData : " + JSON.stringify(data, null, 4));
    callback(data);
  });
};

//-----------------------------------------------------------//
export const readSingleFolderData = (folderPath, callback) => {
  // const databaseRef = ref(database, folderPath);
  // onValue(databaseRef, (snapshot) => {...})

  const databaseRef = ref(database);
  get(child(databaseRef, folderPath)).then((snapshot) => {
    const data = snapshot.val();
    console.log("readSingleFolderData : " + JSON.stringify(data, null, 4));
    callback(data);
  });
};

//-----------------------------------------------------------//
export const pushAllCollectionsData = () => {
  var allCollectionsArray = getState().workspace.collections;
  for (let index = 0; index < allCollectionsArray.length; index++) {
    var singleCollection = JSON.parse(
      JSON.stringify(allCollectionsArray[index])
    );
    console.log("SINGLE COLLECTION : " + JSON.stringify(singleCollection));

    var pathAndCollectionId = "collections/" + singleCollection.id;
    set(ref(database, pathAndCollectionId), singleCollection);
  }
};

//-----------------------------------------------------------//
export const pushAllNodesData = () => {
  var allNodes = getState().workspace.nodes;
  var nodesArray = Object.entries(allNodes);

  for (let index = 0; index < nodesArray.length; index++) {
    var singleNodeItem = nodesArray[index];
    console.log("SINGLE NODE : " + JSON.stringify(singleNodeItem));

    var nodePath = "nodes/" + singleNodeItem[0];
    var nodeData = singleNodeItem[1];
    set(ref(database, nodePath), nodeData);
  }
};

//-----------------------------------------------------------//
export const pushAccessControlData = (userAndPermissionObject) => {
  var path = "access-control/";
  set(ref(database, path), userAndPermissionObject);
};

*/

//--------------------- USED FUNCTIONS ----------------------//
//-----------------------------------------------------------//

export const checkIfCollectionExistInDB = async (collectionId) => {
  let collectionExists = false;
  console.log("CheckIfCollectionExistInDB..." + collectionId);

  const databaseRef = ref(database);
  await get(child(databaseRef, "collections/")).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val();
      if (data.hasOwnProperty(collectionId)) {
        collectionExists = true;
      } else {
        collectionExists = false;
      }
    } else {
      console.log("No data available in Firebase DB!");
    }
  });

  console.log("collectionExists: " + collectionId + "-->" + collectionExists);
  return collectionExists;
};

export const getUsersReadAccessIds = async (userEmail) => {
  var usersReadCollectionIds = [];

  const databaseRef = ref(database);
  await get(child(databaseRef, "access-control/permissions/")).then(
    (snapshot) => {
      var permissionsData = snapshot.val();
      // console.log("permissionsData : " + JSON.stringify(permissionsData));

      if (permissionsData === null) {
        console.log("READ access: null");
      } else {
        var userIndex = permissionsData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (userIndex !== -1) {
          console.log("User: " + JSON.stringify(permissionsData[userIndex]));
          usersReadCollectionIds = permissionsData[userIndex].read
            ? permissionsData[userIndex].read
            : [];
        }

        console.log("READ access: " + usersReadCollectionIds);
      }
    }
  );

  return usersReadCollectionIds;
};

export const getUsersWriteAccessIds = async (userEmail) => {
  var usersWriteCollectionIds = [];

  const databaseRef = ref(database);
  await get(child(databaseRef, "access-control/permissions/")).then(
    (snapshot) => {
      var permissionsData = snapshot.val();
      // console.log("permissionsData: " + JSON.stringify(permissionsData));

      if (permissionsData === null) {
        console.log("WRITE access: null");
      } else {
        var userIndex = permissionsData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (userIndex !== -1) {
          console.log("User: " + JSON.stringify(permissionsData[userIndex]));
          usersWriteCollectionIds = permissionsData[userIndex].write
            ? permissionsData[userIndex].write
            : [];
        }

        console.log("WRITE access: " + usersWriteCollectionIds);
      }
    }
  );
  return usersWriteCollectionIds;
};

export const getUsersAdminAccessIds = async (userEmail) => {
  var usersAdminCollectionIds = [];
  const databaseRef = ref(database);

  await get(child(databaseRef, "access-control/permissions/")).then(
    (snapshot) => {
      var permissionsData = snapshot.val();
      // console.log("permissionsData: " + JSON.stringify(permissionsData));

      if (permissionsData === null) {
        console.log("ADMIN access: null");
      } else {
        var userIndex = permissionsData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (userIndex !== -1) {
          console.log("User: " + JSON.stringify(permissionsData[userIndex]));
          usersAdminCollectionIds = permissionsData[userIndex].admin
            ? permissionsData[userIndex].admin
            : [];
        }
        console.log("ADMIN access: " + usersAdminCollectionIds);
      }
    }
  );

  return usersAdminCollectionIds;
};

//------------------- Fetch Collections ---------------------//

export const fetchUsersCollections = async (readIds, callback) => {
  const databaseRef = ref(database);

  for (let index = 0; index < readIds.length; index++) {
    // Read all collections 1 by 1 in read array and import in redux collections

    const element = readIds[index];
    await get(child(databaseRef, "collections/" + element + "/")).then(
      (snapshot) => {
        if (snapshot.exists()) {
          let importedCollectionId = element;
          let collectionData = snapshot.val();
          console.log("COL " + index + ":" + JSON.stringify(collectionData));

          let existingCollections = [];
          existingCollections = getState().workspace.collections;
          let existingIndex = existingCollections.findIndex(
            (collectionItem) => {
              if (collectionItem.id === importedCollectionId) {
                return true;
              } else return false;
            }
          );

          if (existingIndex === -1) {
            // Collection with same id is NOT PRESENT in Redux
            // Import this collection in REDUX collections array
            getStore().dispatch(importCollection(collectionData));

            // Parse this collection tree and import all it's nodes data REDUX nodes object
            parseCollectionAndFetchNodes(collectionData);
          } else {
            // Collection with same id IS PRESENT in Redux
            // Import this collection in REDUX collections array
            getStore().dispatch(
              importCollection(collectionData, existingIndex)
            );
            // Parse this collection tree and import all it's nodes data REDUX nodes object
            var existingCollectionData = existingCollections[existingIndex];
            parseCollectionAndCompare(collectionData, existingCollectionData);
          }
        } else {
          console.log("snapshot not exist at : " + index);
        }
      }
    );
  }

  callback();
};

export const parseCollectionAndCompare = (
  firebaseCollectionObject,
  reduxCollectionObject
) => {
  console.warn("parseCollectionAndCompare called ........... ");

  console.warn(
    "firebaseCollectionObject : " + JSON.stringify(firebaseCollectionObject)
  );
  console.warn(
    "reduxCollectionObject : " + JSON.stringify(reduxCollectionObject)
  );

  // Children found in firebaseCollectionObject - It is a folder / collection, dig deep into it
  if (
    Array.isArray(firebaseCollectionObject.item) &&
    firebaseCollectionObject.item.length > 0
  ) {
    let childrenArray = firebaseCollectionObject.item;

    for (let index = 0; index < childrenArray.length; index++) {
      var firebaseCollectionItem = childrenArray[index]; //folders or APIs in firebaseCollectionObject
      console.log(
        "firebaseCollectionItem : " + JSON.stringify(firebaseCollectionItem)
      );

      if (
        Array.isArray(reduxCollectionObject.item) &&
        reduxCollectionObject.item.length > 0
      ) {
        var isItemPresentInRedux = reduxCollectionObject.item.findIndex(
          (reduxItem) => {
            if (reduxItem.id === firebaseCollectionItem.id) {
              return true;
            } else {
              return false;
            }
          }
        );

        if (isItemPresentInRedux === -1) {
          // add firebase item in redux
          console.log("reduxCollectionObject: NOT HAVE firebaseCollectionItem");
          reduxCollectionObject.item.push(firebaseCollectionItem);
        } else {
          // don't update existing item in redux
          console.log("reduxCollectionObject: HAVE firebaseCollectionItem");
          // dig deep....
          if (Array.isArray(firebaseCollectionItem.item)) {
            parseCollectionAndCompare(
              firebaseCollectionItem,
              reduxCollectionObject.item[isItemPresentInRedux]
            );
          }
        }
      } else {
        // no children item in reduxCollection object
        console.log("No children item in reduxCollection object");
        reduxCollectionObject.item.push(firebaseCollectionItem);
      }
    } //For loop ends -----------
  }

  // No children item in firebaseCollection object
  else {
    console.log("No children item in firebaseCollection object");
  }

  console.warn(
    "UPDATED REDUX COLLECTION OBJECT : " + JSON.stringify(reduxCollectionObject)
  );

  parseCollectionAndFetchNodes(reduxCollectionObject);
};

export const parseCollectionAndFetchNodes = (collectionData) => {
  console.warn("collectionData FETCH : " + JSON.stringify(collectionData));

  if (collectionData !== null && collectionData !== undefined) {
    // For Nodes DB - fetch all data of the node (for a folder / api)
    if (collectionData.type !== stringConstants.NODE_TYPE_COLLECTION) {
      fetchNodeDetailsFromId(collectionData.id);
    }

    if (Array.isArray(collectionData.item)) {
      // Children found - It is a folder / collection, dig deep into it
      let childrenArray = collectionData.item;
      for (let index = 0; index < childrenArray.length; index++) {
        var element = childrenArray[index];
        parseCollectionAndFetchNodes(element);
      }
    }
  }
};

export const fetchNodeDetailsFromId = (nodeId) => {
  const databaseRef = ref(database);
  get(child(databaseRef, "nodes/" + nodeId + "/")).then((snapshot) => {
    if (snapshot.exists()) {
      console.log("NODE Fetched :" + JSON.stringify(snapshot.val()));
      getStore().dispatch(saveNode(nodeId, snapshot.val()));
    }
  });
};

export const fetchCollectionDetailsFromId = async (collectionId) => {
  const databaseRef = ref(database);
  var collectionData = null;
  await get(child(databaseRef, "collections/" + collectionId + "/")).then(
    (snapshot) => {
      if (snapshot.exists()) {
        console.log("Collection Fetched :" + JSON.stringify(snapshot.val()));
        collectionData = snapshot.val();
      }
    }
  );

  return collectionData;
};

//-------------------- Sync Collections ----------------------//

export const pushUsersWriteAccessCollections = async (writeIds) => {
  console.log("pushUsersWriteAccessCollections()");
  if (writeIds.length > 0) {
    for (let index = 0; index < writeIds.length; index++) {
      const collectionId = writeIds[index];

      var pathAndCollectionId = "collections/" + collectionId;
      var reduxCollectionData = getStore().dispatch(
        getCollectionDataFromId(collectionId)
      );
      reduxCollectionData = JSON.parse(JSON.stringify(reduxCollectionData));

      //Fetch server data for write aceess collection, we need to compare it with redux data
      var firebaseCollection = await fetchCollectionDetailsFromId(collectionId);

      //Parse server collection object and add to it any missing nodes (new api / folder created in redux)
      var updatedFirebaseCollection =
        await parseAndCompareCollectionAndSyncNodes(
          firebaseCollection,
          reduxCollectionData
        );

      console.warn(
        "AFTER AWAIT : firebaseCollection : " +
          JSON.stringify(updatedFirebaseCollection)
      );

      // For updated firebaseCollection, WRITE COLLECTIONS DATA to server
      await set(ref(database, pathAndCollectionId), updatedFirebaseCollection)
        .then(() => {
          // Write success...
        })
        .catch((error) => {
          // The write failed... Show error
        });

      // Parse reduxcollection tree to WRITE NODES DATA to server
      parseCollectionAndSyncNodes(reduxCollectionData);
    } // for loop ends
  }
  console.log("pushUsersWriteAccessCollections function ends.......");
};

export const pushUsersNewCollections = async (newCollectionIds) => {
  console.log("pushUsersNewCollections()");
  if (newCollectionIds.length > 0) {
    for (let index = 0; index < newCollectionIds.length; index++) {
      const collectionId = newCollectionIds[index];
      var pathAndCollectionId = "collections/" + collectionId;
      var reduxCollectionData = getStore().dispatch(
        getCollectionDataFromId(collectionId)
      );
      reduxCollectionData = JSON.parse(JSON.stringify(reduxCollectionData));

      // For New created collection, WRITE COLLECTIONS DATA to server
      await set(ref(database, pathAndCollectionId), reduxCollectionData)
        .then(() => {
          // WRITE DATA TO SERVER - Access Controll data for the new collections
          var userEmail = getState().auth.userEmail;
          uploadUserAccessDataInDB(userEmail, collectionId, true, true, true);
        })
        .catch((error) => {
          // The write failed... Show error
        });

      // Parse collection tree to WRITE NODES DATA to server
      parseCollectionAndSyncNodes(reduxCollectionData);
    }
  }
  console.log("pushUsersNewCollections function ends.......");
};

export const parseAndCompareCollectionAndSyncNodes = async (
  firebaseCollectionObject,
  reduxCollectionObject
) => {
  console.warn("parseAndCompareCollectionAndSyncNodes ........... ");

  console.warn(
    "firebaseCollectionObject : " + JSON.stringify(firebaseCollectionObject)
  );
  console.warn(
    "reduxCollectionObject : " + JSON.stringify(reduxCollectionObject)
  );

  // Children found in reduxCollectionObject - It is a folder / collection, dig deep into it
  if (
    Array.isArray(reduxCollectionObject.item) &&
    reduxCollectionObject.item.length > 0
  ) {
    let childrenArray = reduxCollectionObject.item;

    for (let index = 0; index < childrenArray.length; index++) {
      var reduxCollectionItem = childrenArray[index]; //folders or APIs in reduxCollectionObject
      console.log(
        "reduxCollectionItem : " + JSON.stringify(reduxCollectionItem)
      );

      if (
        Array.isArray(firebaseCollectionObject.item) &&
        firebaseCollectionObject.item.length > 0
      ) {
        var isItemPresentInFirebase = firebaseCollectionObject.item.findIndex(
          (firebaseItem) => {
            if (firebaseItem.id === reduxCollectionItem.id) {
              return true;
            } else {
              return false;
            }
          }
        );

        if (isItemPresentInFirebase === -1) {
          // add firebase item in redux
          console.log("firebaseCollectionObject: NOT HAVE reduxCollectionItem");
          firebaseCollectionObject.item.push(reduxCollectionItem);
        } else {
          // don't update existing item in redux
          console.log("firebaseCollectionObject: HAVE reduxCollectionItem");
          // dig deep....
          if (Array.isArray(reduxCollectionItem.item)) {
            parseCollectionAndCompare(
              reduxCollectionItem,
              firebaseCollectionObject.item[isItemPresentInFirebase]
            );
          }
        }
      } else {
        // no children item in firebaseCollection object
        console.log("No children item in firebaseCollection object");
        firebaseCollectionObject.item.push(reduxCollectionItem);
      }
    } //For loop ends -----------
  }

  // No children item in reduxCollection object
  else {
    console.log("No children item in reduxCollection object");
  }

  // Copy all redux collection proprties to firebase collection
  let properties = Object.entries(reduxCollectionObject);
  // console.log("Redux Collection properties: " + JSON.stringify(properties));
  // output ---> [ "id", "dummy"],[ "name", "Dummy"],[ "type", "COLLECTION"],["isDeleted", true],["item", []]

  for (let index = 0; index < properties.length; index++) {
    const element = properties[index];
    // Here element will be ["name", "Dummy"] ---->  element[0] is key and element[1] is value
    // We need to copy all key-values of redux object except items to firebase object
    if (element[0] !== "item") {
      firebaseCollectionObject[element[0]] = element[1];
    }
  }

  console.warn(
    "UPDATED FIREBASE COLLECTION OBJECT : " +
      JSON.stringify(firebaseCollectionObject)
  );

  return firebaseCollectionObject;
};

export const parseCollectionAndSyncNodes = async (reduxCollectionData) => {
  console.log(
    "parseCollectionAndSyncNodes...." + JSON.stringify(reduxCollectionData)
  );
  var nodes = getState().workspace.nodes;
  var nodeId = reduxCollectionData.id;

  // For Nodes DB - fetch all data of the node (for a folder / api)
  if (reduxCollectionData.type !== stringConstants.NODE_TYPE_COLLECTION) {
    var reduxNodeData = nodes[nodeId];

    const databaseRef = ref(database);
    get(child(databaseRef, "nodes/" + nodeId + "/")).then((snapshot) => {
      if (snapshot.exists()) {
        var firebaseNodeData = snapshot.val();

        console.log("REDUX NODE data : " + JSON.stringify(reduxNodeData));
        console.log("FIREBASE NODE data :" + JSON.stringify(firebaseNodeData));

        if (
          moment(
            reduxNodeData.updateTimestamp,
            stringConstants.TIMESTAMP_FORMAT
          ).isAfter(
            moment(
              firebaseNodeData.updateTimestamp,
              stringConstants.TIMESTAMP_FORMAT
            )
          )
        ) {
          // local redux node is updated after server update was made - push local data
          pushNodeData(reduxNodeData);
        } else {
          // server data is updated after local copy was made,
          // TODO -- do not push local copy????
        }
      } else {
        //Node data not present on server
        pushNodeData(reduxNodeData);
      }
    });
  }

  if (Array.isArray(reduxCollectionData.item)) {
    // Children found - It is a folder / collection, dig deep into it
    let childrenArray = reduxCollectionData.item;
    for (let index = 0; index < childrenArray.length; index++) {
      var element = childrenArray[index];
      parseCollectionAndSyncNodes(element);
    }
  }
};

export const pushNodeData = (nodeItem) => {
  var path = "nodes/" + nodeItem.id;
  console.log("NODE path : " + path);

  try {
    set(ref(database, path), nodeItem)
      .then(() => {
        console.warn("pushNodeData success : " + path);
      })
      .catch((error) => {
        // The write failed... Show error
        console.log("pushNodeData error : " + error);
      });
  } catch (error) {
    // The write failed... Show error
    console.log("pushNodeData try-catch : " + error);
  }
};

//--------------------- Sync Item ----------------------------//

export const updateSingleNode = async (
  nodeId,
  nodeData,
  collectionId,
  callback
) => {
  //Paths to write data to server
  var pathAndCollectionId = "collections/" + collectionId;

  // Push collection data also, since synced item  (folder/ api) can be new
  // In case of sync item on folder, sync all its child items in nodes DB and Collection DB

  //Fetch redux data for collection
  var reduxCollectionData = getStore().dispatch(
    getCollectionDataFromId(collectionId)
  );
  reduxCollectionData = JSON.parse(JSON.stringify(reduxCollectionData));

  //Fetch server data for write aceess collection, we need to compare it with redux data
  var firebaseCollectionData = await fetchCollectionDetailsFromId(collectionId);

  // This function updates firebaseCollectionData object
  await mergeReduxTreeIntoFirebaseTree(
    reduxCollectionData,
    firebaseCollectionData,
    nodeId
  );

  console.warn(
    "FINAL firebaseCollectionData :" + JSON.stringify(firebaseCollectionData)
  );

  // This is Collection Tree Item to be parsed so as to sync all it's children
  let collectionItemToUpdate = {};
  getCollectionNodeItemFromId(reduxCollectionData, nodeId, (collectionItem) => {
    collectionItemToUpdate = collectionItem;
  });

  console.warn(
    "FINAL collectionItemToUpdate :" + JSON.stringify(collectionItemToUpdate)
  );

  // COLLECTION DATA PUSH
  try {
    set(ref(database, pathAndCollectionId), firebaseCollectionData)
      .then(() => {
        console.log("updateSingleNode Coll. update success : " + collectionId);
        // NODES DATA PUSH
        parseCollectionAndSyncNodes(collectionItemToUpdate);
      })
      .catch((error) => {
        // The write failed... Show error
        console.error("updateSingleNode Coll. update error : " + error);
      });
  } catch (error) {
    // The write failed... Show error
    console.error("updateSingleNode Coll. update try-catch : " + error);
  }

  callback();
};

export const mergeReduxTreeIntoFirebaseTree = async (
  localTree,
  firebaseTree,
  nodeId
) => {
  console.log("INSIDE FUNCTION --> mergeReduxTreeIntoFirebaseTree : " + nodeId);

  const node = getStore().dispatch(getNodeDetails(nodeId));
  console.log("node : " + JSON.stringify(node));

  var nodeConnectingLink = node.connectingLink;
  var connectingPath = nodeConnectingLink.split("--"); //["student" , "auth", "otp" ***, "loginApi"]
  const revisedConnectingPath = connectingPath.slice(1);
  console.log("connectingPath : " + connectingPath);

  var nearestBranch = findNearestBranchInTree(
    firebaseTree,
    revisedConnectingPath,
    0
  );
  console.log("nearestBranch : " + JSON.stringify(nearestBranch));

  var nearestBranchNode = {};
  var nearestBranchConnectingLink = "";

  if (nearestBranch.type === stringConstants.NODE_TYPE_COLLECTION) {
    nearestBranchNode = getStore().dispatch(
      getCollectionDataFromId(nearestBranch.id)
    );
    nearestBranchConnectingLink = [nearestBranch.id];
  } else {
    nearestBranchNode = getStore().dispatch(getNodeDetails(nearestBranch.id));
    nearestBranchConnectingLink = nearestBranchNode.connectingLink.split("--");
  }

  var mergeablePaths = connectingPath.splice(
    0,
    nearestBranchConnectingLink.length
    // nearestBranchNode.connectingLink.split("--").length
  );

  console.log(
    "connectingPath : " +
      connectingPath +
      "\n" +
      "mergeablePaths : " +
      mergeablePaths +
      "\n" +
      "nearestBranchConnectingLink.length : " +
      nearestBranchConnectingLink.length
  );

  if (!nearestBranch.item) {
    nearestBranch.item = [];
  }

  var revisedItemsList = nearestBranch.item;
  if (connectingPath.length) {
    connectingPath.forEach((mPath, index) => {
      const mDetail = getStore().dispatch(getNodeDetails(mPath));

      console.log("MPATH: " + mPath);

      // if mPath is folder and not at last index
      if (
        mDetail.type === stringConstants.NODE_TYPE_FOLDER &&
        connectingPath.length - 1 !== index
      ) {
        revisedItemsList.push({
          id: mPath,
          type: stringConstants.NODE_TYPE_FOLDER,
          item: [],
        });
        revisedItemsList = revisedItemsList[revisedItemsList.length - 1].item;
      }

      // if mPath is folder and at last index
      else if (mDetail.type === stringConstants.NODE_TYPE_FOLDER) {
        var nearestLocalBranch = findNearestBranchInTree(
          localTree,
          revisedConnectingPath,
          0
        );
        revisedItemsList.push(nearestLocalBranch);
      }

      // if mPath is api
      else if (mDetail.type === stringConstants.NODE_TYPE_API) {
        revisedItemsList.push({
          id: mPath,
          type: stringConstants.NODE_TYPE_API,
        });
      }
    });
  } else {
    var nearestLocalBranch = findNearestBranchInTree(
      localTree,
      revisedConnectingPath,
      0
    );
    var nearestFirebaseBranch = findNearestBranchInTree(
      firebaseTree,
      revisedConnectingPath,
      0
    );

    deepMerger(nearestLocalBranch, nearestFirebaseBranch);
  }
};

export const findNearestBranchInTree = (tree, connectingPath, pathIndex) => {
  console.log(
    "findNearestBranchInTree: " +
      tree.id +
      " -- " +
      connectingPath +
      " -- " +
      pathIndex
  );
  var treeItems = tree.item ?? [];

  if (pathIndex === connectingPath.length) {
    console.log("TREE : " + JSON.stringify(tree));
    return tree;
  }

  for (let index = 0; index < treeItems.length; index++) {
    const item = treeItems[index];
    if (item.id === connectingPath[pathIndex]) {
      return findNearestBranchInTree(item, connectingPath, ++pathIndex);
    } else if (index === treeItems.length - 1) {
      console.log("TREE : " + JSON.stringify(tree));
      return tree;
    }
  }

  console.log("End reached....");
  return tree;
};

export const deepMerger = (sourceTree, destionationTree) => {
  if (!destionationTree.item) {
    destionationTree["item"] = sourceTree.item ?? [];
  } else {
    if (Array.isArray(sourceTree.item)) {
      var sourceTreeItemArray = sourceTree.item;
      var destinationTreeItems = destionationTree.item;

      sourceTreeItemArray.forEach((sourceItem) => {
        var destinationNodeIndex = destinationTreeItems.findIndex((item) => {
          if (item.id === sourceItem.id) {
            return true;
          } else return false;
        });

        // If desitnation tree has sourceItem and it is folder - recursively call this function
        if (
          destinationNodeIndex !== -1 &&
          sourceItem.type === stringConstants.NODE_TYPE_FOLDER
        ) {
          deepMerger(sourceItem, destinationTreeItems[destinationNodeIndex]);
        }

        // If desitnation tree has sourceItem and it is API - do nothing
        else if (
          destinationNodeIndex !== -1 &&
          sourceItem.type === stringConstants.NODE_TYPE_API
        ) {
          // do nothing....
        }
        // If desitnation tree DO NOT have sourceItem add sourceItem to it
        else if (destinationNodeIndex === -1) {
          destinationTreeItems.push(sourceItem);
        }
      });
    }
  }
};

//-------------------- Share Item ----------------------------//

export const getPermissionsDataForCollection = async (collectionId) => {
  var userWiseData = [];
  const databaseRef = ref(database);

  await get(child(databaseRef, "access-control/permissions")).then(
    (snapshot) => {
      var permissionsData = snapshot.val();

      if (permissionsData === null) {
        console.log("permissionsData : null");
      } else {
        for (let index = 0; index < permissionsData.length; index++) {
          const element = permissionsData[index];

          var userData = {
            userEmail: element.email,
            read: element.read?.includes(collectionId) ? true : false,
            write: element.write?.includes(collectionId) ? true : false,
            admin: element.admin?.includes(collectionId) ? true : false,
          };

          console.log("User data : " + JSON.stringify(userData));

          if (
            userData.read === true ||
            userData.write === true ||
            userData.admin === true
          ) {
            //Means this user have at least 1 typer of access to this collection. Then only add in list!
            userWiseData.push(userData);
          }
        }
      }
    }
  );

  return userWiseData;
};

export const uploadUserAccessDataInDB = async (
  userEmail,
  collectionId,
  readAccess,
  writeAccess,
  adminAccess
) => {
  // const databaseRef = ref(database, "access-control/permissions/");
  // onValue(databaseRef, (snapshot) => {

  var permissionsData = null;
  var UserExistingIndex = 0;

  let accessControlPath = "";
  let accessControlUserData = {};

  const databaseRef = ref(database);

  // SET accessControlPath AND accessControlUserData
  await get(child(databaseRef, "access-control/permissions/")).then(
    (snapshot) => {
      permissionsData = snapshot.val();

      if (permissionsData === null) {
        accessControlPath = "access-control/permissions/" + 0;
        accessControlUserData = {
          email: userEmail,
          read: [],
          write: [],
          admin: [],
        };
      } else {
        UserExistingIndex = permissionsData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (UserExistingIndex === -1) {
          // If users record is NOT present in permissions db
          UserExistingIndex = permissionsData.length;
          accessControlPath = "access-control/permissions/" + UserExistingIndex;
          accessControlUserData = {
            email: userEmail,
            read: [],
            write: [],
            admin: [],
          };
        } else {
          // If users record is present in permissions db
          accessControlPath = "access-control/permissions/" + UserExistingIndex;
          accessControlUserData = permissionsData[UserExistingIndex];
          accessControlUserData.read = accessControlUserData.read ?? [];
          accessControlUserData.write = accessControlUserData.write ?? [];
          accessControlUserData.admin = accessControlUserData.admin ?? [];
        }
      }
    }
  );

  console.log(
    "AFTER AWAIT : ACCESS CONTROL PATH and DATA:" +
      accessControlPath +
      "\n " +
      JSON.stringify(accessControlUserData)
  );

  // if Read acecss = true, add collectionId in read[] array. Remove duplicate if already present.
  // if Read acecss = false, check if collectionId present in read[] array. Remove if  present.
  if (readAccess) {
    let readNew = accessControlUserData.read;
    readNew.push(collectionId);
    accessControlUserData.read = [...new Set(readNew)];
  } else {
    let readNew = accessControlUserData.read;
    let index = readNew.indexOf(collectionId);
    if (index > -1) {
      readNew.splice(index, 1);
    }
    accessControlUserData.read = readNew;
  }

  //Do the same for Write Access------------
  if (writeAccess) {
    let WriteNew = accessControlUserData.write;

    WriteNew.push(collectionId);
    accessControlUserData.write = [...new Set(WriteNew)];
  } else {
    let WriteNew = accessControlUserData.write;

    let index = WriteNew.indexOf(collectionId);
    if (index > -1) {
      WriteNew.splice(index, 1);
    }
    accessControlUserData.write = WriteNew;
  }

  //Do the same for Admin Access------------
  if (adminAccess) {
    let AdminNew = accessControlUserData.admin;

    AdminNew.push(collectionId);
    accessControlUserData.admin = [...new Set(AdminNew)];
  } else {
    let AdminNew = accessControlUserData.admin;

    let index = AdminNew.indexOf(collectionId);
    if (index > -1) {
      AdminNew.splice(index, 1);
    }
    accessControlUserData.admin = AdminNew;
  }

  //Print Data-----------------------------------------
  console.log(
    "FINAL : ACCESS CONTROL PATH and DATA: " +
      accessControlPath +
      "\n" +
      JSON.stringify(accessControlUserData)
  );

  //-----------------------------------------------------------------
  //Finally, Push updated access control data in DB on generated path
  await set(ref(database, accessControlPath), accessControlUserData)
    .then(() => {
      console.warn("Write success at : " + accessControlPath);
    })
    .catch((error) => {
      console.warn("Write failed at : " + accessControlPath);
    });
};

//----------------- Sync and Fetch Env -----------------------//

export const fetchUsersEnvironmentsAndVariable = async (userEmail) => {
  let userEnvPath = "user-environments-variables/";
  let usersEnvData = null;

  //Get Path of users record
  const databaseRef = ref(database);
  await get(child(databaseRef, "user-environments-variables/")).then(
    (snapshot) => {
      var usersData = snapshot.val();

      if (usersData === null) {
        userEnvPath = null;
      } else {
        var userIndex = usersData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (userIndex === -1) {
          // If users record is NOT present in users db
          userIndex = usersData.length;
          userEnvPath = userEnvPath + userIndex;
        } else {
          // If users record is present in users db
          userEnvPath = userEnvPath + userIndex;
        }
      }
    }
  );

  //Print Path
  console.log("AFTER AWAIT : USER ENV PATH  " + userEnvPath);

  //Get Data from generated Path
  if (userEnvPath !== null) {
    await get(child(databaseRef, userEnvPath)).then((snapshot) => {
      if (snapshot.exists()) {
        usersEnvData = snapshot.val();
        var envArray = usersEnvData?.environments ?? [];
        var varArray = usersEnvData?.variables ?? [];
        getStore().dispatch(replaceAllEnvAndVariablesData(envArray, varArray));
      }
    });
  }
  //Print Data
  console.log("AFTER AWAIT : USER ENV DATA  " + JSON.stringify(usersEnvData));
};

export const syncUsersEnvironmentsAndVariable = async (
  userEmail,
  environmentsArray,
  variablesArray
) => {
  let userEnvPath = "user-environments-variables/";
  let userEnvAndVarData = {
    email: userEmail,
    environments: environmentsArray,
    variables: variablesArray,
  };

  //Get Path of users record
  const databaseRef = ref(database);
  await get(child(databaseRef, "user-environments-variables/")).then(
    (snapshot) => {
      var usersData = snapshot.val();

      if (usersData === null) {
        userEnvPath = userEnvPath + 0;
      } else {
        var userIndex = usersData.findIndex((user) => {
          if (String(user.email) === String(userEmail)) {
            return true;
          } else {
            return false;
          }
        });

        if (userIndex === -1) {
          // If users record is NOT present in users db
          userIndex = usersData.length;
          userEnvPath = userEnvPath + userIndex;
        } else {
          // If users record is present in users db
          userEnvPath = userEnvPath + userIndex;
        }
      }
    }
  );

  //Print Path
  console.log("AFTER AWAIT : USER ENV PATH: " + userEnvPath);

  //Push user env and var data to generated path
  await set(ref(database, userEnvPath), userEnvAndVarData)
    .then(() => {
      // Data saved successfully!
    })
    .catch((error) => {
      // The write failed... Show error
    });
};

//-------------------- User Login ----------------------------//
export const uploadUserLoginDetailsInDB = (userEmail, name, token) => {
  // const databaseRef = ref(database, "access-control/permissions/");
  // onValue(databaseRef, (snapshot) => {

  const databaseRef = ref(database);
  get(child(databaseRef, "access-control/users/")).then((snapshot) => {
    var usersData = snapshot.val();
    console.log("usersData: " + JSON.stringify(usersData));

    let accessControlPath = "";
    let accessControlUserData = {
      email: userEmail,
      name: name,
      token: token,
      last_login: moment().toString(),
    };

    if (usersData === null) {
      accessControlPath = "access-control/users/" + 0;
    } else {
      var userIndex = usersData.findIndex((user) => {
        if (String(user.email) === String(userEmail)) {
          return true;
        } else {
          return false;
        }
      });

      if (userIndex === -1) {
        // If users record is NOT present in users db
        userIndex = usersData.length;
        accessControlPath = "access-control/users/" + userIndex;
      } else {
        // If users record is present in users db
        accessControlPath = "access-control/users/" + userIndex;
      }
    }

    //Print Data-----------------------------------------
    console.log(
      "ACCESS CONTROL PATH and DATA: " +
        accessControlPath +
        "\n" +
        JSON.stringify(accessControlUserData)
    );

    //push updated user data in DB on generated path
    set(ref(database, accessControlPath), accessControlUserData)
      .then(() => {
        // Data saved successfully!
      })
      .catch((error) => {
        // The write failed... Show error
      });
  });
};

export default database;
