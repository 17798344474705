import { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { ListItemSimple } from "../../controlsNew";
import styles from "./listView.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default class ListView extends Component {
  data = [];

  constructor(props) {
    super(props);
    this.data = props.data;
    this.renderChildren = this.renderChildren.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.data = nextProps.data;
    }
  }

  renderChildren = () => {
    let items = [];

    // console.log("LIST : " + JSON.stringify(this.data));

    if (this.data.length > 0) {
      for (var index = 0; index < this.data.length; index++) {
        // console.log("List item : " + JSON.stringify(this.data[index]));
        const element = this.data[index];
        items.push(
          // <ListGroup.Item
          //   action
          //   onClick={(item) => this.props.onClick(element)}
          //   className={styles.list_item_light_blue}
          // >
          //   {element.name}
          // </ListGroup.Item>

          <ListItemSimple
            dataObject={element}
            onClick={(item) => this.props.onClick(element)}
            showCheckmark={this.props.showCheckmark}
          />
        );
      }
    }
    return items;
  };

  render() {
    return (
      <div className={styles.list_container}>
        <ListGroup>{this.renderChildren()}</ListGroup>
      </div>
    );
  }
}
