import React from "react";
import PropTypes from "prop-types";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import "./InputWithHighlighterStyles.scss";

const InputWithHighlighter = ({
  error,
  tabIndex = 0,
  type,
  className = "",
  label,
  disabled,
  size = "",
  setFocus = 0,
  isrequired = false,
  isNum = false,
  min = "",
  noOutline,
  ...props
}) => {
  const handleKeyPress = (e) => {
    if (isNum) {
      let charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={`highlighter-wrapper ${noOutline && "no-outline"}`}>
      <span className={`label text-muted`}>
        {label} {isrequired && <span className="text-danger mx-1">*</span>}
      </span>

      <HighlightWithinTextarea
        disabled={disabled}
        onKeyPress={handleKeyPress}
        {...props}
        highlight={/\{\{.*?\}\}/g} // Regex for {{variable}}
      />
    </div>
  );
};

InputWithHighlighter.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

InputWithHighlighter.defaultProps = {
  type: "text",
};

export default InputWithHighlighter;
