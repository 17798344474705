import { actionTypes } from "./authActions";

const initialState = {
  isUserLoggedIn: false,
  userFirstName: null,
  userLastName: null,
  userEmail: null,
  token: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SAVE_USER_AUTH_DETAILS:
      return { ...state, ...payload };

    case actionTypes.RESET_USER_AUTH_DETAILS:
      return { ...state, ...payload };

    default:
      return state;
  }
}
