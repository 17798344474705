import React from "react";
import PropTypes from "prop-types";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./ToggleButtonsStyles.scss";

const ToggleButtons = ({
  initialValue,
  data,
  onItemSelected,
  disabled,
  styles,
}) => {
  const [selectedId, setSelectedId] = React.useState(initialValue);

  const handleChange = (event, newId) => {
    // console.log("event " + event.target.value);
    // console.log("newId " + newId);
    setSelectedId(newId);
    onItemSelected(newId);
  };

  const renderChildren = (nodes) => {
    if (Array.isArray(nodes)) {
      // console.log("nodes : " + JSON.stringify(nodes));
      var toggleButtonArray = [];

      nodes.forEach((node) =>
        toggleButtonArray.push(
          <ToggleButton
            value={node.id}
            aria-label={node.name}
            autoCapitalize={false}

            // sx={{ borderColor: "transparent" }}
          >
            <div className="toggle">
              {node.icon}
              <text className="mt-2"> {node.name} </text>
            </div>
          </ToggleButton>
        )
      );

      return toggleButtonArray;
    }
  };

  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={selectedId}
      exclusive
      onChange={handleChange}
    >
      {renderChildren(data)}
    </ToggleButtonGroup>
  );
};

ToggleButtons.propTypes = {
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ToggleButtons.defaultProps = {
  disabled: false,
  styles: "primal",
};

export default ToggleButtons;
