import React from "react";
import PropTypes from "prop-types";
import {
  ListItemHeaders,
  ListItemShareCollection,
  ListItemFormData,
  ListItemVariables,
  IconButtonView,
} from "../../controlsNew";
import { IoAdd } from "react-icons/io5";
import { stringConstants } from "../../../constants/stringConstants";
import "./ListViewDynamicStyles.scss";

const ListViewDynamic = ({
  data,
  onDataChange,
  onAddNewItemClick,
  onDeleteItemClick,
  type,
  hideAddItemButton = false,
  styles,
}) => {
  const renderHeaderItems = () => {
    var items = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      items.push(
        <ListItemHeaders
          dataObject={element}
          onDataChange={onDataChange}
          onDeleteItemClick={onDeleteItemClick}
        />
      );
    }
    return items;
  };

  const renderFormDataItems = () => {
    var items = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      items.push(
        <ListItemFormData
          dataObject={element}
          onDataChange={onDataChange}
          onDeleteItemClick={onDeleteItemClick}
        />
      );
    }
    return items;
  };

  const renderShareCollectionItems = () => {
    var items = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      items.push(
        <ListItemShareCollection
          data={element}
          onDataChange={onDataChange}
          onDeleteItemClick={onDeleteItemClick}
        />
      );
    }
    return items;
  };

  const renderVariablesItems = () => {
    var items = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      items.push(
        <ListItemVariables
          dataObject={element}
          onDataChange={onDataChange}
          onDeleteItemClick={onDeleteItemClick}
        />
      );
    }
    return items;
  };

  return (
    <div className="listview-dynamic-container">
      {type === stringConstants.LIST_TYPE_SHARE_COLLECTION
        ? renderShareCollectionItems()
        : type === stringConstants.LIST_TYPE_FORM_DATA
        ? renderFormDataItems()
        : type === stringConstants.LIST_TYPE_VARIABLES
        ? renderVariablesItems()
        : renderHeaderItems()}

      {hideAddItemButton ? null : (
        <IconButtonView
          icon={<IoAdd size={15} color="#000" />}
          label="Add Item"
          styles={{ marginTop: "10px", width: "100px" }}
          onClick={onAddNewItemClick}
        />
      )}
    </div>
  );
};

ListViewDynamic.propTypes = {
  data: PropTypes.object,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ListViewDynamic.defaultProps = {
  disabled: false,
};

export default ListViewDynamic;
