import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Input, IconButtonView } from "../..";
import variables from "../../../../assets/styles/_variables.scss";
import { BsDot } from "react-icons/bs";
import { MdCheckCircle } from "react-icons/md";
import "./ListItemSimpleStyles.scss";

const ListItemSimple = ({
  dataObject, //{ name, id, etc }
  onClick,
  showCheckmark = false,
  styles,
}) => {
  return (
    <div className="list_container" onClick={onClick}>
      {showCheckmark ? (
        dataObject.selected ? (
          <MdCheckCircle size={15} color={variables.secondaryColor} />
        ) : (
          <BsDot size={15} color="#000" />
        )
      ) : null}

      <text className="list_text p-2">{dataObject.name}</text>
    </div>
  );
};

ListItemSimple.propTypes = {
  dataObject: PropTypes.object,
};

export default ListItemSimple;
