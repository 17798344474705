import moment from "moment";
import { showAlert } from "../alert/alertActions";
import { history, getState, getStore } from "../configure/configureStore";
import { makeApiRequest } from "../../service/workspaceService";
import Api from "../../service/apiAxiosService";
import { stringConstants } from "../../constants/stringConstants";
import { refreshAllTabs } from "../../utils/apiDataUtil";

export const actionTypes = {
  IMPORT_COLLECTION: "IMPORT_COLLECTION",
  CREATE_NEW_COLLECTION: "CREATE_NEW_COLLECTION",
  ADD_NEW_FOLDER: "ADD_NEW_FOLDER",
  ADD_NEW_API: "ADD_NEW_API",

  SAVE_NODE: "SAVE_NODE",
  FETCH_NODE: "FETCH_NODE",

  OPEN_EMPTY_TAB: "OPEN_EMPTY_TAB",
  OPEN_API_IN_TAB: "OPEN_API_IN_TAB",
  SWITCH_API_TAB: "SWITCH_API_TAB",
  CLOSE_API_TAB: "CLOSE_API_TAB",

  UPDATE_CURRENT_OPEN_API: "UPDATE_CURRENT_OPEN_API",
  UPDATE_NODE_DATA_ON_SAVE: "UPDATE_NODE_DATA_ON_SAVE",
  UPDATE_COLLECTION_NODE_DATA_ON_SAVE: "UPDATE_COLLECTION_NODE_DATA_ON_SAVE",
  UPDATE_OPEN_TABS_DATA_ON_RENAME: "UPDATE_OPEN_TABS_DATA_ON_RENAME",

  RUN_API: "RUN_API",
  UPDATE_API_RESPONSE_AS_LOADING: "UPDATE_API_RESPONSE_AS_LOADING",

  DELETE_COLLECTION: "DELETE_COLLECTION",
  DELETE_NODE: "DELETE_NODE",

  RESET_ALL_DATA: "RESET_ALL_DATA",
};

export const emptyTabDetailsObject = {
  id: "new_tab",
  name: "New Request",
  title: "New Request",
  type: stringConstants.NODE_TYPE_API,

  request: {
    method: "",
    url: { raw: "" },
    header: [],
    body: {},
  },
  response: {},
  responseContentType: null,
  // --------------------> add while adding id / title (during import collection)
  //--------------------> update to true on updateCurrentTabData
  //--------------------> Check this onClose of TabView, if true - ask whether to save API changes?
  isUpdated: false,
  isUnsavedNewTab: false,
  updateTimestamp: null,
  //---------------------> //TODO
};

/* ----------------  Import / Add collection -------------------- */

export function importCollection(collectionJSON, existingIndex = -1) {
  let existingCollections = [];
  existingCollections = getState().workspace.collections;

  if (existingIndex === -1) {
    //collection with same id is NOT present in Redux
    existingCollections.push(collectionJSON);
  } else {
    //collection with same id is present in Redux
    existingCollections.splice(existingIndex, 1, collectionJSON);
  }

  console.log("COLLECTION IMPORTED : " + JSON.stringify(existingCollections));

  return (dispatch) => {
    dispatch({
      type: actionTypes.IMPORT_COLLECTION,
      payload: {
        collections: existingCollections,
        timestamp: moment().toString(),
      },
    });
  };
}

export function addNewCollection(newCollectionJson) {
  let existingCollections = [];
  existingCollections = getState().workspace.collections;
  existingCollections.push(newCollectionJson);

  console.log("COLLECTIONS UPDATED : " + existingCollections);

  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_COLLECTION,
      payload: {
        collections: existingCollections,
        timestamp: moment().toString(),
      },
    });
  };
}

export function addNewNodeInCollectionTree(
  parentConnectingLink,
  collectionId,
  newNodeData
) {
  const lodash = require("lodash");
  var newNode = lodash.cloneDeep(newNodeData); //{ ...newNodeData };
  var node = null;
  var digPath = "";
  var collectionObject = getStore().dispatch(
    getCollectionDataFromId(collectionId)
  );

  var folders = parentConnectingLink.split("--"); //--> ['dummy', 'auth', "otp"]

  // console.log("collectionObject : " + JSON.stringify(collectionObject));
  // console.log("folders : " + folders);

  if (folders.length > 1) {
    for (var i = 1; i < folders.length; i++) {
      digPath =
        i === folders.length - 1
          ? digPath + folders[i]
          : digPath + folders[i] + "--";
    }

    collectionObject.item.map((item) => {
      console.log("item.id , folders[1] : " + item.id + " , " + folders[1]);

      if (item.id === folders[1]) {
        var digFolder = item;
        digNode(digPath, digFolder, (data) => {
          node = data;
        });
      }
    });
  } else {
    node = collectionObject;
  }

  console.log("FINAL returned Node ---> " + JSON.stringify(node));
  console.log("FINAL to be inserted ---> " + JSON.stringify(newNode));

  if (node.hasOwnProperty("item") && Array.isArray(node.item)) {
    node.item.push(newNode);
  } else {
    node.item = [];
    node.item.push(newNode);
  }

  setTimeout(() => {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg:
          newNode.type === stringConstants.NODE_TYPE_API
            ? stringConstants.addAPISuccess
            : stringConstants.addFolderSuccess,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_FOLDER,
      payload: {
        timestamp: moment().toString(),
      },
    });
  };
}

/* ----------------- Save API / Folder details as Nodes ------------------------ */

export function saveNode(nodeId, nodeObject) {
  // Remove items from node if it is a folder
  const { item, ...newNode } = nodeObject;
  console.warn("saveNode : " + JSON.stringify(newNode));

  var nodes = getState().workspace.nodes;

  if (nodes.hasOwnProperty(nodeId)) {
    //That means nodeId is present on both server and local
    var serverUpdateTimestamp = newNode.updateTimestamp;
    var localUpdateTimestamp = nodes[nodeId].updateTimestamp;

    // Check if node's updateDateTime is same locally and on server
    // If server's updateTimestamp is latest than that of local, update local node data with server data

    if (
      localUpdateTimestamp !== undefined &&
      moment(serverUpdateTimestamp, stringConstants.TIMESTAMP_FORMAT).isAfter(
        moment(localUpdateTimestamp, stringConstants.TIMESTAMP_FORMAT)
      )
    ) {
      // server node is updated after local copy was made - update local node
      console.log("saveNode - UPDATE redux node with new data");
      nodes[nodeId] = newNode;
    } else {
      // server node is not updated after local copy was made, no need to update local data
      console.log("saveNode - DON'T UPDATE redux node");
      nodes[nodeId] = nodes[nodeId];
    }
  } else {
    // That means nodeId is not present locally, add newNode to nodes in redux
    console.log("saveNode - ADD NEW node data in redux");
    newNode.updateTimestamp = moment().format(stringConstants.TIMESTAMP_FORMAT);

    if (newNode.type === stringConstants.NODE_TYPE_API) {
      //for an API, mark all header items and form data items as checked by default

      if (newNode.hasOwnProperty("request")) {
        if (newNode.request.hasOwnProperty("header")) {
          let headerItems = newNode.request.header;
          headerItems.forEach((headerItem) => {
            headerItem["checked"] = true;
          });
          newNode.request.header = headerItems;
        }

        if (newNode.request.hasOwnProperty("body")) {
          if (newNode.request.body.hasOwnProperty("formdata")) {
            let formDataItems = newNode.request.body.formdata;
            formDataItems.forEach((formDataItem) => {
              formDataItem["checked"] = true;
            });
            newNode.request.body.formdata = formDataItems;
          }
        }
      }
    }

    nodes[nodeId] = newNode;
  }

  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_NODE,
      payload: {
        nodes: nodes,
      },
    });
  };
}

export function getNodeDetails(nodeId) {
  return () => {
    var nodes = getState().workspace.nodes;
    var nodeObject = nodes[nodeId];
    var nodeObjectCopy = {};

    if (nodeObject !== undefined || nodeObject !== null) {
      const lodash = require("lodash");
      nodeObjectCopy = lodash.cloneDeep(nodeObject); //{ ...nodeObject };
    }
    return nodeObjectCopy;
  };
}

export function getNodeDetailsWithRef(nodeId) {
  return () => {
    var nodes = getState().workspace.nodes;
    var nodeObject = nodes[nodeId] ?? {};
    return nodeObject;
  };
}

/* ----------------- API / Tab handling ------------------------ */

export function openSelectedAPI(apiId, apiDetails, timestamp) {
  let existingOpenTabs = [];
  existingOpenTabs = getState().workspace.openTabs;

  var alreadyOpen = existingOpenTabs.some((item) => {
    if (item.id === apiId) {
      return true;
    }
    return false;
  });

  if (alreadyOpen) {
    getStore().dispatch(switchToSelectedAPI(apiId, apiDetails, timestamp));
  } else {
    existingOpenTabs.push(apiDetails);
  }

  return (dispatch) => {
    dispatch({
      type: actionTypes.OPEN_API_IN_TAB,
      payload: {
        noTabsOpen: false,
        currentTabApiId: apiId,
        currentTabApiDetails: apiDetails,
        openTabs: existingOpenTabs,
        timestamp: timestamp,
      },
    });
  };
}

export function switchToSelectedAPI(apiId, apiDetails, timestamp) {
  console.warn("selected id : " + apiId);
  return (dispatch) => {
    dispatch({
      type: actionTypes.SWITCH_API_TAB,
      payload: {
        noTabsOpen: false,
        currentTabApiId: apiId,
        currentTabApiDetails: apiDetails,
        timestamp: timestamp,
      },
    });
  };
}

export function closeSelectedAPI(apiId, timestamp) {
  let noTabsOpen = false;
  let existingOpenTabs = [];
  existingOpenTabs = getState().workspace.openTabs;

  const apiIndex = existingOpenTabs.findIndex((item) => {
    if (String(apiId) === String(item.id)) {
      return true;
    }
    return false;
  });

  if (apiIndex !== -1) {
    existingOpenTabs.splice(apiIndex, 1);
  }

  if (existingOpenTabs.length < 1) {
    noTabsOpen = true;
  }

  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_API_TAB,
      payload: {
        noTabsOpen: noTabsOpen,
        openTabs: existingOpenTabs,
        timestamp: timestamp,
      },
    });
  };
}

export function openEmptyTab(newEmptyTabId, timestamp) {
  var existingOpenTabs = [];
  existingOpenTabs = getState().workspace.openTabs;

  var newTabDetails = {
    id: newEmptyTabId,
    name: "New Request",
    title: "New Request",
    type: stringConstants.NODE_TYPE_API,

    request: {
      method: "",
      url: { raw: "" },
      header: [],
      body: {
        mode: stringConstants.RAW_DATA,
      },
    },
    response: {},
    responseContentType: null,
    isUnsavedNewTab: true,
    isUpdated: false,
    updateTimestamp: null,
  };

  // alert(
  //   "OPEN Tabs Before : " +
  //     JSON.stringify(existingOpenTabs, null, 4) +
  //     "\n New Tab details :" +
  //     JSON.stringify(newTabDetails, null, 4)
  // );

  existingOpenTabs.push(newTabDetails);

  // alert("OPEN Tabs After : " + JSON.stringify(existingOpenTabs, null, 4));

  return (dispatch) => {
    dispatch({
      type: actionTypes.OPEN_EMPTY_TAB,
      payload: {
        noTabsOpen: false,
        currentTabApiId: newEmptyTabId,
        currentTabApiDetails: newTabDetails,
        openTabs: existingOpenTabs,
        timestamp: timestamp,
      },
    });
  };
}

export function updateCurrentTabData(newApiDetails, timestamp) {
  let existingOpenTabs = [];
  existingOpenTabs = getState().workspace.openTabs;

  // alert("OLD OPEN TABS: " + JSON.stringify(existingOpenTabs, null, 4));

  var index = existingOpenTabs.findIndex((tab) => tab.id === newApiDetails.id);
  existingOpenTabs[index] = newApiDetails;
  refreshAllTabs();

  // alert("UPDATED OPEN TABS: " + JSON.stringify(existingOpenTabs, null, 4));

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CURRENT_OPEN_API,
      payload: {
        openTabs: existingOpenTabs,
        currentTabApiDetails: newApiDetails,
        timestamp: timestamp,
      },
    });
  };
}

/* ----------------------  Run API --------------------------- */

export function makeApiCallAndGetResponse(
  apiId,
  apiDetails,
  method,
  url,
  headers,
  bodyType,
  rawDataForBody,
  formDataForBody
) {
  return (dispatch) => {
    /*  Api.request(method, url, headers, body)
      .then((response) => {
        console.log("YEPPIE! Response recieved! : " + JSON.stringify(response));

       
        dispatch({
          type: actionTypes.RUN_API,
          payload: {
            // currentTabApiDetails.response: response,
          },
        });

        history.push(url);
      })
      .catch((err) => {
        
        dispatch(
          showAlert({
            isOpen: true,
            title: "error",
            type: "danger",
            msg: "Error In Processing Request,",
          })
        );
      });
      */

    makeApiRequest(
      method,
      url,
      headers,
      bodyType,
      rawDataForBody,
      formDataForBody,
      (contentType, headers, response, error) => {
        //Copy existing tab details in new variable -------------------
        let newApiDetailsWithResponse = apiDetails;

        //Update error, response and responseContentType ---------------
        if (error) {
          console.log("ERROR!! : " + error);
          newApiDetailsWithResponse.response = response;
          newApiDetailsWithResponse.responseContentType =
            stringConstants.CONTENT_TYPE_ERROR;
          newApiDetailsWithResponse.responseError =
            "Error in processing request! " + error;
          newApiDetailsWithResponse.isUpdated = true;
        }

        //
        else {
          if (response == null) {
            console.log("NULL RESPONSE!!");
            newApiDetailsWithResponse.response = response;
            newApiDetailsWithResponse.responseContentType =
              stringConstants.CONTENT_TYPE_ERROR;
            newApiDetailsWithResponse.responseError =
              "Empty response recieved!";
            newApiDetailsWithResponse.isUpdated = true;
          } else {
            console.log("SUCCESS!!");
            newApiDetailsWithResponse.response = response;
            newApiDetailsWithResponse.responseContentType = contentType;
            newApiDetailsWithResponse.responseError = null;
            newApiDetailsWithResponse.isUpdated = true;
          }
        }

        // Update this new data in openTabs ------------------------------------------------
        let existingOpenTabs = [];
        existingOpenTabs = getState().workspace.openTabs;

        const apiIndex = existingOpenTabs.findIndex((item) => {
          if (String(apiId) === String(item.id)) {
            return true;
          }
          return false;
        });

        if (apiIndex !== -1) {
          existingOpenTabs.splice(apiIndex, 1, newApiDetailsWithResponse);
        }

        // Update this new data in current tab (ifcurrently open) --------------------------
        var currentTabNewData = {};
        if (String(getState().workspace.currentTabApiId) === String(apiId)) {
          currentTabNewData = newApiDetailsWithResponse;
        } else {
          currentTabNewData = getState().workspace.currentTabApiDetails;
        }

        // Update both datas  -------------------------------------------------------------
        dispatch({
          type: actionTypes.RUN_API,
          payload: {
            openTabs: existingOpenTabs,
            currentTabApiDetails: currentTabNewData,
            timestamp: moment().toISOString(), // IMPORTANT***  This should be different than the timestamp value saved while loader is shown (if we use toString() at both places, and response is recieved within 1 second, value will be same and screen won't refresh)  *** THUS WE ARE USING toISOString() HERE AND toString() while showing loader!! ***
          },
        });
        // history.push(url);
      }
    );
  };
}

export function updateApiResponseAsLoading(apiId, apiDetails) {
  var apiDetailsUpdated = apiDetails;
  apiDetailsUpdated["responseContentType"] =
    stringConstants.CONTENT_TYPE_LOADING;

  let existingOpenTabs = [];
  existingOpenTabs = getState().workspace.openTabs;

  const apiIndex = existingOpenTabs.findIndex((item) => {
    if (String(apiId) === String(item.id)) {
      return true;
    }
    return false;
  });

  if (apiIndex !== -1) {
    existingOpenTabs.splice(apiIndex, 1, apiDetailsUpdated);
  }

  // alert("OPEN Tabs After : " + JSON.stringify(existingOpenTabs, null, 4));

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_API_RESPONSE_AS_LOADING,
      payload: {
        noTabsOpen: false,
        currentTabApiDetails: apiDetailsUpdated,
        openTabs: existingOpenTabs,
        timestamp: moment().toString(),
      },
    });
  };
}

/* -------------------- Save / Update ------------------------ */

export function updateNodeDataOnSaveClick(nodeId, updatedNodeDetails) {
  var nodes = getState().workspace.nodes;
  var node = nodes[nodeId];

  console.log("updateNodeDataOnSaveClick called");
  console.log(JSON.stringify(updatedNodeDetails));

  node.name = updatedNodeDetails.name;
  node.title = updatedNodeDetails.title;
  node.request = updatedNodeDetails.request;
  node.response = updatedNodeDetails.response
    ? updatedNodeDetails.response
    : {};

  node.isUpdated = false;
  updatedNodeDetails.isUpdated = false;
  node.updateTimestamp = moment().format(stringConstants.TIMESTAMP_FORMAT);

  setTimeout(() => {
    refreshAllTabs();
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.changesSaved,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_NODE_DATA_ON_SAVE,
      payload: {
        nodes: nodes,
        timestamp: moment().toString(),
      },
    });
  };
}

export function updateCollectionNodeOnSaveClick(nodeId, updatedNodeDetails) {
  var collections = getState().workspace.collections;

  for (let i = 0; i <= collections.length; i++) {
    if (collections[i].id === nodeId) {
      var collection = collections[i];

      collection.name = updatedNodeDetails.name;
      refreshAllTabs();

      setTimeout(() => {
        getStore().dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: stringConstants.changesSaved,
          })
        );
      }, 500);

      return (dispatch) => {
        dispatch({
          type: actionTypes.UPDATE_COLLECTION_NODE_DATA_ON_SAVE,
          payload: {
            collections: collections,
            timestamp: moment().toString(),
          },
        });
      };
    } else {
      console.log("Condition Not Working");
    }
  }
}

export function updateOpenTabsDataOnRename(nodeId, updatedNodeDetails) {
  var openTabs = getState().workspace.openTabs;
  var currentTabId = getState().workspace.currentTabApiId;
  var currentTabDetails = getState().workspace.currentTabApiDetails;

  var index = openTabs.findIndex((tab) => {
    if (tab.id === nodeId) {
      return true;
    }
  });
  openTabs[index] = { ...updatedNodeDetails };

  if (currentTabId === nodeId) {
    currentTabDetails = { ...updatedNodeDetails };
  }

  // console.log("opentabs updated : " + JSON.stringify(openTabs));
  // console.log("currentTabDetails  : " + JSON.stringify(currentTabDetails));
  refreshAllTabs();

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_OPEN_TABS_DATA_ON_RENAME,
      payload: {
        openTabs: openTabs,
        currentTabApiDetails: currentTabDetails,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ----------------------- Delete ---------------------------- */

export function deleteCollection(collectionId) {
  console.log("deleteCollection called");
  var allCollectionsArray = getState().workspace.collections;
  var collectionIndex = null;
  var collectionData = null;

  for (let index = 0; index < allCollectionsArray.length; index++) {
    var singleCollection = allCollectionsArray[index];
    if (String(singleCollection.id) === String(collectionId)) {
      collectionIndex = index;
      collectionData = singleCollection;
      break;
    }
  }

  collectionData["isDeleted"] = true;
  allCollectionsArray.splice(collectionIndex, 1, collectionData);

  console.log("POST DELETE : collections :" + JSON.stringify(collectionData));

  setTimeout(() => {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.deleteCollectionSuccess,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_COLLECTION,
      payload: {
        collections: allCollectionsArray,
        timestamp: moment().toString(),
      },
    });
  };
}

export function deleteNode(nodeId) {
  console.log("deleteNode called : " + nodeId);
  var allNodes = getState().workspace.nodes;
  var nodeData = null;

  console.log("allNodes : " + JSON.stringify(allNodes, null, 4));

  if (allNodes.hasOwnProperty(nodeId)) {
    nodeData = getStore().dispatch(getNodeDetails(nodeId));
    nodeData["isDeleted"] = true;
    nodeData["updateTimestamp"] = moment().format(
      stringConstants.TIMESTAMP_FORMAT
    );
  }

  allNodes[nodeId] = nodeData;
  console.log("POST DELETE : nodeData :" + JSON.stringify(nodeData));

  setTimeout(() => {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.deleteItemSuccess,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_NODE,
      payload: {
        nodes: allNodes,
        timestamp: moment().toString(),
      },
    });
  };
}

/* ----------------- Generic Functions --------------------*/

export const digNode = (digPath, data, callback) => {
  console.log("digNode digPath --->" + digPath);
  console.log("digNode data --->" + JSON.stringify(data));

  var folders = digPath.split("--");
  var newData = null;
  var newPath = null;

  if (folders.length > 1) {
    console.log("folders : " + folders);
    for (var i = 1; i < folders.length; i++) {
      newPath =
        i === folders.length - 1
          ? newPath + folders[i]
          : newPath + folders[i] + "--";
    }

    data.item.map((item) => {
      console.log("item.id , folders[1] : " + item.id + " , " + folders[1]);

      if (item.id === folders[1]) {
        var newFolder = item;
        digNode(newPath, newFolder, (data) => {
          newData = data;
        });

        console.log("callback folders.length >1 " + JSON.stringify(newData));
        callback(newData);
      }
    });
  } else if (folders.length === 1) {
    console.log("callback folders.length === 1 " + JSON.stringify(data));
    callback(data);
  }
};

export function getCollectionDataFromId(collectionId) {
  return () => {
    var allCollectionsArray = getState().workspace.collections;
    var collectionObject = {};

    for (let index = 0; index < allCollectionsArray.length; index++) {
      var singleCollection = allCollectionsArray[index];
      if (singleCollection.id === collectionId) {
        collectionObject = singleCollection;
      }
    }
    return collectionObject;
  };
}

export function getCollectionNodeItemFromId(collectionData, nodeId, callback) {
  var nodeObject = {};

  // TODO -  Convert this function to action function!!!

  // console.log(
  //   "getCollectionNodeItemFromId ..." +
  //     collectionData.id +
  //     " --> " +
  //     collectionData.type
  // );

  if (
    collectionData.type !== stringConstants.NODE_TYPE_COLLECTION &&
    collectionData.id === nodeId
  ) {
    nodeObject = collectionData;
    console.log("nodeObject TO RETURN --> " + JSON.stringify(nodeObject));
    callback(nodeObject);
  } else if (Array.isArray(collectionData.item)) {
    // Children found - It is a folder / collection, dig deep into it
    let itemArray = collectionData.item;
    for (let index = 0; index < itemArray.length; index++) {
      var item = itemArray[index];
      getCollectionNodeItemFromId(item, nodeId, callback);
    }
  }
}

export function resetAllCollectionAndTabData() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_ALL_DATA,
      payload: {
        noTabsOpen: true,
        collections: [],
        nodes: {},
        openTabs: [],
        currentTabApiId: null,
        currentTabApiDetails: {},
        timestamp: null,
      },
    });
  };
}

/* -------------------------------------------------*/
/* --------------    OLD CODE  ---------------------*/
/* -------------------------------------------------*/

/*
export const digNodeOld = (path, data, callback) => {
  // console.log("digNode path --->" + path);
  // console.log("digNode data --->" + JSON.stringify(data));

  var folders = path.split("/");
  var newData = null;

  if (folders.length > 1) {
    if (folders[0] === "item") {
      newData = data.item[parseInt(folders[1])];

      if (folders.length > 2) {
        var newPath = "";
        for (var i = 2; i < folders.length; i++) {
          newPath =
            i === folders.length - 1
              ? newPath + folders[i]
              : newPath + folders[i] + "/";
        }
        digNode(newPath, newData, callback);
      } else {
        callback(newData);
      }
    }
  } else if (folders.length === 1) {
    callback(newData);
  }
};

export const getRealtimeDbPathForSingleItem = (
  selectedId,
  parentPath,
  collectionObject,
  callback
) => {
  var path = parentPath === "" ? collectionObject.id : parentPath;
  var finalPath = "";

  // Children found - It is a folder / collection
  if (Array.isArray(collectionObject.item)) {
    if (collectionObject.id === selectedId) {
      console.log("#0  : BINGO !!");
      finalPath = path;
      return callback(finalPath);
    }

    let childrenArray = collectionObject.item;
    for (let index = 0; index < childrenArray.length; index++) {
      const element = childrenArray[index];
      finalPath = path + "/item/" + index;

      if (element.id === selectedId) {
        console.log("#1  : BINGO !!");
        return callback(finalPath);
      } else {
        console.log("#2");
        getRealtimeDbPathForSingleItem(
          selectedId,
          finalPath,
          element,
          callback
        );
      }
    }
  }

  // No children items found - It's a API
  else {
    if (String(collectionObject.id) === String(selectedId)) {
      console.log("#3  : BINGO !!");
      finalPath = path;
      return callback(finalPath);
    } else {
      console.log("#4");
    }
  }
};

export function getIndicesOf(searchStr, str) {
  var searchStrLen = searchStr.length;
  var startIndex = 0,
    index,
    indices = [];
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}

export function getCollectionNodeItemIdsInArray(
  collectionNodeItem,
  idsArray = []
) {
  // console.log("getCollectionNodeItemIdsInArray... " + collectionNodeItem.id);
  idsArray.push(collectionNodeItem.id);

  if (Array.isArray(collectionNodeItem.item)) {
    let itemArray = collectionNodeItem.item;
    for (let index = 0; index < itemArray.length; index++) {
      var item = itemArray[index];
      getCollectionNodeItemIdsInArray(item, idsArray);
    }
  }

  // console.log("RETURN idsArray... " + idsArray);
  return idsArray;
}

 export function addNewApiInCollection(parentNode, newApiDetails) {
  var apiData = {
    id: newApiDetails.id,
    type: newApiDetails.type,
  };

  if (parentNode.hasOwnProperty("item") && Array.isArray(parentNode.item)) {
    parentNode.item.push(apiData);
  } else {
    parentNode.item = [];
    parentNode.item.push(apiData);
  }

  console.log(
    "addNewApiInCollection : parentNode : " + JSON.stringify(parentNode)
  );

  setTimeout(() => {
    getStore().dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: stringConstants.addAPISuccess,
      })
    );
  }, 500);

  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_FOLDER,
      payload: {
        timestamp: moment().toString(),
      },
    });
  };
}  */
