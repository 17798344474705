import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { ButtonView } from "../../controlsNew";

const PopupDialog = ({
  title,
  message,
  onConfirmClick,
  onCancelClick,
  renderContents,
  widePopup,
  styles,
}) => {
  return (
    <>
      <Modal
        size={widePopup ? "lg" : null}
        show={true}
        onHide={onCancelClick}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <text>{message}</text>
          <br />
          {renderContents}
          <br />
        </Modal.Body>

        <Modal.Footer>
          <ButtonView
            title="Confirm"
            onClick={onConfirmClick}
            styles="secondary large"
          />
          <ButtonView
            title="Cancel"
            onClick={onCancelClick}
            styles="secondary-outline large"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

PopupDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  renderContents: PropTypes.element,
  style: PropTypes.string,
};

PopupDialog.defaultProps = {
  disabled: false,
  styles: "primal",
};

export default PopupDialog;
