import React, { useState } from "react";
import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import { TreeItemMenu } from "..";
import {
  IoChevronForward,
  IoChevronDown,
  IoLayersOutline,
} from "react-icons/io5";
import { HiOutlineFolderOpen } from "react-icons/hi2";
import { TbApi } from "react-icons/tb";
import { handleTreeViewNodeClick } from "../../../utils/apiDataUtil";
import { getState } from "../../../store/configure/configureStore";
import { stringConstants } from "../../../constants/stringConstants";
import "./TreeViewStyles.scss";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    // [`& .${treeItemClasses.label}`]: {
    //   fontWeight: "inherit",
    //   color: "inherit",
    // },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const TreeViewJson = ({
  data,
  onRenameItemClicked,
  onDuplicateItemClicked,
  onAddFolderClicked,
  onAddNewRequestClicked,
  onSyncItemClicked,
  onShareItemClicked,
  onDeleteItemClicked,
  disabled,
  styles,
}) => {
  const [style, setStyle] = useState({ display: "none" });

  const renderTree = (node) => {
    var nodeId = node.id;
    var nodeLabel =
      node.type === stringConstants.NODE_TYPE_COLLECTION
        ? node.name
        : getState().workspace.nodes[nodeId]?.name;

    return (
      <StyledTreeItemRoot
        key={node.id}
        nodeId={node.id}
        label={
          <div
            style={{ display: "flex", alignItems: "center", pr: 0 }}
            onMouseEnter={(e) => {
              setStyle({ display: "block", marginTop: -5 });
            }}
            onMouseLeave={(e) => {
              setStyle({ display: "none" });
            }}
          >
            {node.id === "root" ? null : node.type ===
              stringConstants.NODE_TYPE_FOLDER ? (
              <HiOutlineFolderOpen size={14} style={{ marginRight: 10 }} />
            ) : node.type === stringConstants.NODE_TYPE_API ? (
              <TbApi size={14} style={{ marginRight: 10 }} />
            ) : // ) : node.type === stringConstants.NODE_TYPE_COLLECTION ? (
            //   <IoLayersOutline size={14} style={{ marginRight: 10 }} />
            null}

            <text
              style={{ flexGrow: 1 }}
              onClick={() => {
                handleTreeViewNodeClick(node.id, node.type);
              }}
            >
              {nodeLabel}
            </text>

            <div style={{ width: "20px" }}>
              <div style={style}>
                <TreeItemMenu
                  isRootItem={node.id === "root" ? true : false}
                  itemType={node.type}
                  onRenameItemClicked={() => {
                    onRenameItemClicked(node.id, node);
                  }}
                  onDuplicateItemClicked={() => {
                    onDuplicateItemClicked(node.id, node);
                  }}
                  onAddFolderClicked={() => {
                    onAddFolderClicked(node.id, node);
                  }}
                  onAddNewRequestClicked={() => {
                    onAddNewRequestClicked(node.id, node);
                  }}
                  onSyncItemClicked={() => {
                    onSyncItemClicked(node.id, node);
                  }}
                  onShareItemClicked={() => {
                    onShareItemClicked(node.id, node);
                  }}
                  onDeleteItemClicked={() => {
                    onDeleteItemClicked(node.id, node);
                  }}
                />
              </div>
            </div>
          </div>
        }
        style={{
          "--tree-view-color": "#242034", // primary color
          "--tree-view-bg-color": "#d7e3f5", //bg-color-blue-dark
        }}
      >
        {Array.isArray(node.item)
          ? node.item.map((childNode) => renderTree(childNode))
          : null}
      </StyledTreeItemRoot>
    );
  };

  function onNodeSelected(event, nodeId) {
    // console.log("onNodeSelected : Left click : NODE ID: " + nodeId);
    // handleTreeViewNodeClick(nodeId, data);
  }

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<IoChevronDown />}
      defaultExpanded={["root"]}
      defaultExpandIcon={<IoChevronForward />}
      onNodeSelect={onNodeSelected}
      sx={{
        height: "100%",
        flexGrow: 1,
        maxWidth: 300,
        overflowY: "auto",
        // backgroundColor: "aqua",
        // color: "red",
      }}
    >
      {renderTree(data)}
    </TreeView>
  );
};

TreeViewJson.propTypes = {
  data: PropTypes.element.isRequired,
  style: PropTypes.string,
};

TreeViewJson.defaultProps = {
  disabled: false,
  styles: "primal",
};

export default TreeViewJson;
