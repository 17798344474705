import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../store/alert/alertActions";
import { LOGIN } from "../../../navigation/routes";
import { getState, history } from "../../../store/configure/configureStore";
import { sidebarButtons } from "../../../constants/sidebarContextButtons";
import { setSelectedContext } from "../../../store/context/contextActions";
import { resetAllCollectionAndTabData } from "../../../store/workspace/workspaceActions";
import {
  saveUserAuthDetails,
  resetUserAuthDetails,
} from "../../../store/auth/authActions";
import { handleCurlImportAndOpenInNewTab } from "../../../utils/apiDataUtil";
import { resetAllEnvData } from "../../../store/environment/environmentActions";
import { stringConstants } from "../../../constants/stringConstants";
import HomePanel from "./HomePanel";
import "./HomeStyles.scss";

export class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Logout popup
      showLogoutConfirmDialog: false,

      // Import curl popup
      showImportCurlPopup: false,
      importedCurl: "",
      importedCurlName: "",
    };

    this.onLoginClicked = this.onLoginClicked.bind(this);
    this.onLogoutClicked = this.onLogoutClicked.bind(this);
    this.onLogoutConfirmClick = this.onLogoutConfirmClick.bind(this);
    this.onLogoutCancelClick = this.onLogoutCancelClick.bind(this);

    this.onToggleButtonSelected = this.onToggleButtonSelected.bind(this);

    this.onImportCurlClicked = this.onImportCurlClicked.bind(this);
    this.onCurlChange = this.onCurlChange.bind(this);
    this.onCurlNameChange = this.onCurlNameChange.bind(this);
    this.onImportCurlConfirmClick = this.onImportCurlConfirmClick.bind(this);
    this.onImportCurlCancelClick = this.onImportCurlCancelClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    console.log("HOME : selectedContext : " + this.props.selectedContext);
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.props = nextProps;
    }
  }

  /*--------------------- LOGIN CLICK ------------------------*/

  onLoginClicked = () => {
    window.open(LOGIN, "_self");

    // FOR DEBUG ---------- without login ------------
    // var userDetails = {
    //   status: "true",
    //   message: "Login was Successful.",
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNwYXdhckBzbWFydHNpZ2h0LmluIiwiaWQiOiI2MzdjOTIxY2MxMDkwZGQxNjIxMmNjYTQiLCJyb2xlSWQiOiI2MTI4YTM4YjZhZWU3NzUyMTJlNWYyYWIiLCJlbXBsb3llZUlkIjoiNjM3YzkyMWNjMTA5MGQ5MGNjMTJjY2E1IiwibGV2ZWwiOjUsImxldmVsTmFtZSI6Ik1hbmFnZXIiLCJpYXQiOjE2ODM2MTA2OTQsImV4cCI6MTY4MzY5NzA5NH0.OE53ihWklwY6vLe34jSYqlFzQV7IwPdtzOUpXQgUHzA",
    //   roleId: "6128a38b6aee775212e5f2ab",
    //   userRole: "Super Admin",
    //   userId: "637c921cc1090dd16212cca4",
    //   employeeId: "637c921cc1090d90cc12cca5",
    //   level: "5",
    //   levelName: "Manager",
    //   email: "spawar@smartsight.in",
    //   firstName: "Sharayu",
    //   lastName: "Pawar",
    // };
    // this.props.saveUserAuthDetails(userDetails);
    // this.props.setSelectedContext(stringConstants.CONTEXT_APIS);
    // uploadUserLoginDetailsInDB(
    //   userDetails.email,
    //   userDetails.firstName + " " + userDetails.lastName,
    //   userDetails.token
    // );
    // this.props.showAlert({
    //   isOpen: true,
    //   title: "Success",
    //   type: "success",
    //   msg: stringConstants.loginSuccess,
    // });
    //------------------------------------------------------
  };

  /*--------------------- LOGOUT CLICK ------------------------*/

  onLogoutClicked = () => {
    this.setState({ showLogoutConfirmDialog: true });
  };

  onLogoutCancelClick = () => {
    this.setState({ showLogoutConfirmDialog: false });
  };

  onLogoutConfirmClick = () => {
    this.setState({ showLogoutConfirmDialog: false });
    this.props.resetUserAuthDetails();
    this.props.resetAllCollectionAndTabData();
    this.props.resetAllEnvData();

    var emitter = require("tiny-emitter/instance");
    emitter.off("open-selected-api");
    emitter.off("close-selected-api");
    emitter.off("refresh-tabs");

    this.props.showAlert({
      isOpen: true,
      title: "Success",
      type: "success",
      msg: stringConstants.logoutSuccess,
    });
  };

  /*-------------- TOGGLE BUTTONS ITEM CLICK ------------------*/

  onToggleButtonSelected = (id) => {
    if (id !== null) {
      if (
        id == stringConstants.CONTEXT_APIS ||
        id === stringConstants.CONTEXT_ENV
      ) {
        this.props.setSelectedContext(id);
      } else {
        this.props.showAlert({
          isOpen: true,
          title: "Coming Soon",
          type: "warning",
          msg: "This Feature is coming soon!",
        });
      }
    } else {
      console.log("Same toggle button clicked!");
    }
  };

  /*----------------- IMPORT CURL ------------------*/

  onImportCurlClicked = () => {
    console.log("IMPORT CURL in HOME CONTAINER");
    this.setState({ showImportCurlPopup: true });
  };

  onCurlChange = (event) => {
    var curl = event.target.value;
    this.setState({ importedCurl: curl });
  };

  onCurlNameChange = (event) => {
    var curlName = event.target.value;
    this.setState({ importedCurlName: curlName });
  };

  onImportCurlConfirmClick = () => {
    if (this.state.importedCurl.trim() === "") {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: stringConstants.importCurlErrorEmptyCurl,
      });
    } else {
      this.setState({
        showImportCurlPopup: false,
        importedCurl: "",
        importedCurlName: "",
      });
      var apiName =
        this.state.importedCurlName.trim() === ""
          ? "New Request"
          : this.state.importedCurlName;
      handleCurlImportAndOpenInNewTab(this.state.importedCurl, apiName);
    }
  };

  onImportCurlCancelClick = () => {
    this.setState({
      showImportCurlPopup: false,
      importedCurl: "",
      importedCurlName: "",
    });
  };

  render() {
    return (
      <HomePanel
        // Which context option is selected from toggle buttons
        selectedContext={this.props.selectedContext}
        toggleButtonsData={sidebarButtons}
        onToggleButtonSelected={this.onToggleButtonSelected}
        // Env Data
        isEnvClicked={this.props.isEnvClicked}
        clickedEnvId={this.props.clickedEnvId}
        selectedEnvId={this.props.selectedEnvId}
        // Tabs data
        noTabsOpen={this.props.noTabsOpen}
        tabOptions={this.props.openTabs}
        currentTabId={this.props.currentTabApiId}
        // Auth details
        userName={this.props.userFirstName}
        isUserLoggedIn={this.props.isUserLoggedIn}
        // Login - Logout
        onLoginClicked={this.onLoginClicked}
        onLogoutClicked={this.onLogoutClicked}
        showLogoutConfirmDialog={this.state.showLogoutConfirmDialog}
        onLogoutCancelClick={this.onLogoutCancelClick}
        onLogoutConfirmClick={this.onLogoutConfirmClick}
        // Import CURL
        onImportCurlClicked={this.onImportCurlClicked}
        showImportCurlPopup={this.state.showImportCurlPopup}
        importedCurl={this.state.importedCurl}
        importedCurlName={this.state.importedCurlName}
        onCurlChange={this.onCurlChange}
        onCurlNameChange={this.onCurlNameChange}
        onImportCurlConfirmClick={this.onImportCurlConfirmClick}
        onImportCurlCancelClick={this.onImportCurlCancelClick}
      />
    );
  }
}

HomeContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: state.auth.isUserLoggedIn,
    userFirstName: state.auth.userFirstName,
    userEmail: state.auth.userEmail,
    selectedContext: state.context.selectedContext,
    isEnvClicked: state.environment.isEnvClicked,
    clickedEnvId: state.environment.clickedEnvId,
    selectedEnvId: state.environment.selectedEnvId,
    noTabsOpen: state.workspace.noTabsOpen,
    collections: state.workspace.collections,
    openTabs: state.workspace.openTabs,
    currentTabApiId: state.workspace.currentTabApiId,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  saveUserAuthDetails,
  resetUserAuthDetails,
  resetAllCollectionAndTabData,
  resetAllEnvData,
  history,
  setSelectedContext,
})(HomeContainer);

//---------- WRITE ALL COLLECTIONS DATA TO SERVER --------------
// let allCollections = this.props.collections;
// pushAllCollectionsData(allCollections);

//----------- WRITE ACCESS-CONTROL DATA TO SERVER ---------------
// pushAccessControlData(this.access_control);

//--------- WRITE SINGLE FOLDER / API DATA TO SERVER  -----------
// pushSingleItemData(
//   "ansdummy.supervisor.check-in-check-out",
//   //dataObject for that Id which will replace all data at path of that Id
//   {
//     id: "ansdummy.supervisor.check-in-check-out",
//     name: "Updated check in check out",
//     request: { url: "" },
//     response: { status: 200 },
//   }
// );

//-------------------------------------------------
// console.log(
//   this.props.userFirstName +
//     " --- " +
//     this.props.userEmail +
//     " --- " +
//     this.props.isUserLoggedIn
// );

//-------------------------------------------------
// var userEmail = "spawar@smartsight.in";
//     getUsersReadAccessIds(userEmail, () => {});
//     uploadUserAccessDataInDB(userEmail, "collectionId", true, true, true);
