import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Input,
  IconButtonView,
  Dropdown,
  InputWithHighlighter,
} from "../..";
import { IoTrashOutline } from "react-icons/io5";
import "./ListItemFormDataStyles.scss";
import { stringConstants } from "../../../../constants/stringConstants";
import { formDataTypes } from "../../../../constants/formDataTypes";

const ListItemFormData = ({
  dataObject, //{ key, value, desription, checked }
  onDataChange,
  onDeleteItemClick,
  styles,
}) => {
  function onKeyChange(event) {
    let newKey = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.key = newKey;
    onDataChange(newDataObject, dataObject.key);
  }

  function onDropdownSelect(event) {
    let newDataObject = { ...dataObject };
    newDataObject.type = event.target.value;
    newDataObject.value = "";
    console.log("dropdown select : " + event.target.value);
    onDataChange(newDataObject, newDataObject.key);
  }

  function onValueChange(value) {
    let newValue = value; // For InputWithHighlighter
    let newDataObject = { ...dataObject };
    newDataObject.value = newValue;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onDescriptionChange(event) {
    let newDescription = event.target.value;
    let newDataObject = { ...dataObject };
    newDataObject.description = newDescription;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onCheckedChange(event) {
    let newDataObject = { ...dataObject };
    newDataObject.checked = !dataObject.checked;
    onDataChange(newDataObject, newDataObject.key);
  }

  function onDeleteClicked(event) {
    console.log("onDeleteClicked");
    onDeleteItemClick(dataObject.key);
  }

  function onFileUpload(event) {
    event.preventDefault();
    console.log("FILE : ");
    console.log(event.target.files[0]);

    let newDataObject = { ...dataObject };
    newDataObject["value"] = event.target.files[0];
    console.log("newDataObject : ", newDataObject);
    onDataChange(newDataObject, newDataObject.key);

    // let reader = new FileReader();
    // reader.readAsDataURL(event.target.files[0]);
    // reader.onload = (e) => {
    //   let newDataObject = { ...dataObject };
    //   newDataObject.value = e.target.result;
    //   onDataChange(newDataObject, newDataObject.key);
    // };
  }

  return (
    <div className="dynamic-list-item">
      <div className="check-mark">
        <Checkbox
          checked={dataObject.checked}
          onCheckedChange={onCheckedChange}
        />
      </div>
      <div className="input-wide">
        <Input
          noOutline
          value={dataObject.key}
          placeholder={"Key"}
          onChange={onKeyChange}
        />
        <Dropdown
          label={"Type"}
          data={formDataTypes}
          value={dataObject.type}
          onSelect={onDropdownSelect}
          noBorder
        />
      </div>

      <div className="input-wide">
        <div className="floating-placeholder">
          {/* //Need this, as InputWithHighlighter does not support placeholder with custom styling */}
          <text>
            {dataObject.type === "text" && dataObject.value === ""
              ? "Value"
              : ""}
          </text>
        </div>

        {dataObject.type === "text" ? (
          <InputWithHighlighter
            noOutline
            value={dataObject.value}
            placeholder={"Value"}
            onChange={onValueChange}
          />
        ) : (
          <input className="file-input" type="file" onChange={onFileUpload} />
        )}
      </div>

      <div className="input-wide">
        <Input
          noOutline
          value={dataObject.description}
          placeholder={"Description"}
          onChange={onDescriptionChange}
        />
      </div>

      <div className="delete-mark">
        <IconButtonView
          icon={<IoTrashOutline size={15} color="#000" />}
          styles={{ width: "10px" }}
          onClick={onDeleteClicked}
        />
      </div>
    </div>
  );
};

ListItemFormData.propTypes = {
  dataObject: PropTypes.object,
  onDataChange: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCheckedChange: PropTypes.func,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ListItemFormData.defaultProps = {
  disabled: false,
};

export default ListItemFormData;
