import React from "react";
import {
  ButtonView,
  Dropdown,
  Input,
  InputWithHighlighter,
  TabViewSimple,
  ListViewDynamic,
  RadioButtonGroup,
  PopupDialog,
  ListView,
  TextArea,
} from "../../../controlsNew";
import { VscSave } from "react-icons/vsc";
import { IoRocketOutline } from "react-icons/io5";
import { methods } from "../../../../constants/methods";
import { requestBodyModes } from "../../../../constants/requestBodyModes";
import { stringConstants } from "../../../../constants/stringConstants";
import "./RequestStyles.scss";

const RequestPanel = (props) => {
  // console.log("REQUEST PANEL PROPS: " + JSON.stringify(props, null, 4));

  const tabOptions = [
    {
      id: "1",
      title: "Headers",
      closable: false,
      panelComponent: renderHeaders(),
    },
    {
      id: "2",
      title: "Body",
      closable: false,
      panelComponent: renderBody(),
    },
  ];

  function renderHeaders() {
    var headersArray = props.currentTabApiDetails.request?.header ?? [];
    return (
      <div className="p-2">
        <ListViewDynamic
          data={headersArray}
          onDataChange={props.onHeadersDataChange}
          onAddNewItemClick={props.onAddNewHeaderClick}
          onDeleteItemClick={props.onDeleteHeaderItemClick}
          type={stringConstants.LIST_TYPE_HEADERS}
        />
      </div>
    );
  }

  function renderBody() {
    var rawData = props.currentTabApiDetails.request?.body?.raw ?? "";
    var formData = props.currentTabApiDetails.request?.body?.formdata ?? [];

    return (
      <div className="p-2 mb-2">
        <RadioButtonGroup
          data={requestBodyModes}
          selectedValue={props.requestBodyModeSelected}
          onChange={props.onChangeRequestBodyMode}
        />

        {props.requestBodyModeSelected === stringConstants.RAW_DATA ? (
          <TextArea
            value={rawData}
            onChange={props.onChangeRequestBodyRawData}
            largerBox
          />
        ) : null}

        {props.requestBodyModeSelected === stringConstants.FORM_DATA ? (
          <ListViewDynamic
            data={formData}
            onDataChange={props.onChangeRequestBodyFormData}
            onAddNewItemClick={props.onAddNewFormDataClick}
            onDeleteItemClick={props.onDeleteFormDataClick}
            type={stringConstants.LIST_TYPE_FORM_DATA}
          />
        ) : null}
      </div>
    );
  }

  function renderSaveApiPopupContents() {
    return (
      <>
        <ListView
          data={props.saveApiFolderList}
          onClick={(item) => props.onCollectionListItemClick(item)}
        />

        <br />
        <text>
          Selected Location : {props.saveApiCollectionPath}
          {props.saveApiCurrentFolderPath}
        </text>
        <br />
      </>
    );
  }

  return (
    <div className="request-container">
      <div className="horizontal-container">
        <div className="input-box px-2">
          <Input
            placeholder={stringConstants.enterApiName}
            showBottomline
            onChange={props.onApiNameChange}
            value={props.currentTabApiDetails.name}
          />
        </div>

        <div className="p-1">
          {/* {props.currentTabApiDetails.isUpdated ? (
            <div className="red-circle" />   // This red dot is now displayedf in tabview!!
          ) : null} */}

          <ButtonView
            title={stringConstants.saveApi}
            icon={<VscSave size={12} color="#ffff" />}
            styles="secondary medium"
            onClick={props.onSaveClick}
          />
        </div>
      </div>

      <div className="horizontal-container mt-1">
        <div className="mx-1" />
        <Dropdown
          label={stringConstants.method}
          data={methods}
          value={props.currentTabApiDetails.request.method}
          onSelect={props.onMethodChange}
        />
        <div className="input-box mt-1">
          <div className="floating-placeholder">
            {/* Need this, as InputWithHighlighter does not support placeholder with custom styling */}
            <text>
              {props.currentTabApiDetails.request.url.raw === ""
                ? stringConstants.enterUrl
                : ""}
            </text>
          </div>

          <InputWithHighlighter
            placeholder={stringConstants.enterUrl}
            value={props.currentTabApiDetails.request.url.raw}
            onChange={props.onUrlChange}
          />
        </div>

        <div className="p-1">
          <ButtonView
            title={stringConstants.send}
            icon={<IoRocketOutline size={15} color="#fff" />}
            styles="secondary medium"
            onClick={props.onSendClick}
          />
        </div>
      </div>

      <div className="mt-2 pt-1">
        <TabViewSimple tabOptions={tabOptions} />
      </div>

      {props.showSaveNewApiPopup === true ? (
        <PopupDialog
          title={stringConstants.saveNewApiPopupTitle}
          message={stringConstants.saveNewApiPopupSubTitle}
          renderContents={renderSaveApiPopupContents()}
          onConfirmClick={props.onSaveNewApiConfirmClick}
          onCancelClick={props.onSaveNewApiCancelClick}
        />
      ) : null}
    </div>
  );
};

export default RequestPanel;
