import React from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const TabViewSimple = ({ tabOptions, onItemClick, disabled, styles }) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabTitles = () => {
    var tabTitles = [];
    tabOptions.forEach((tabItem) => {
      tabTitles.push(<Tab label={tabItem.title} value={tabItem.id} />);
    });
    return tabTitles;
  };

  const renderTabPanels = () => {
    var tabPanels = [];
    tabOptions.forEach((tabItem) => {
      tabPanels.push(
        <TabPanel
          style={{ /* background: "yellow",*/ padding: "0px" }}
          value={tabItem.id}
        >
          {tabItem.panelComponent}
        </TabPanel>
      );
    });
    return tabPanels;
  };

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
        // textColor="#d87d2e"
        // indicatorColor="#d87d2e"
      >
        {renderTabTitles()}
      </TabList>
      {renderTabPanels()}
    </TabContext>
  );
};

TabViewSimple.propTypes = {
  tabOptions: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  style: PropTypes.string,
};

TabViewSimple.defaultProps = {
  disabled: false,
};

export default TabViewSimple;
