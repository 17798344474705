import React from "react";
import PropTypes from "prop-types";
import { Input, SwitchButton } from "../..";
import { IoTrashOutline } from "react-icons/io5";
import "./ListItemShareCollectionStyles.scss";

const ListItemShareCollection = ({
  data, //{ userEmail, read, write, admin }
  onDataChange,
  styles,
}) => {
  function onEmailChange(event) {
    let newValue = event.target.value;
    let newdata = { ...data };
    newdata.userEmail = newValue;
    onDataChange(newdata, data.userEmail);
  }

  function onReadChange(event) {
    let newdata = { ...data };
    newdata.read = !data.read;
    onDataChange(newdata, data.userEmail);
  }

  function onWriteChange(event) {
    let newdata = { ...data };
    newdata.write = !data.write;
    onDataChange(newdata, data.userEmail);
  }

  function onAdminChange(event) {
    let newdata = { ...data };
    newdata.admin = !data.admin;
    onDataChange(newdata, data.userEmail);
  }

  return (
    <div className="list_item_share_collection">
      <div className="email-input">
        <text>Email :</text>
        <div style={{ flex: 1 }}>
          <Input noOutline value={data.userEmail} onChange={onEmailChange} />
        </div>
      </div>

      <div className="switch-input">
        <SwitchButton
          label="Read"
          checked={data.read}
          onCheckedChange={onReadChange}
        />
      </div>

      <div className="switch-input">
        <SwitchButton
          label="Write"
          checked={data.write}
          onCheckedChange={onWriteChange}
        />
      </div>

      <div className="switch-input">
        <SwitchButton
          label="Admin"
          checked={data.admin}
          onCheckedChange={onAdminChange}
        />
      </div>
    </div>
  );
};

ListItemShareCollection.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};

ListItemShareCollection.defaultProps = {
  disabled: false,
};

export default ListItemShareCollection;
