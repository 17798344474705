// app
export const HOME = "/home";
export const LOGIN_SUCCESS = "/login-success";

export const LOGIN =
  // USAGE - FOR DEBUG
  // "https://democonnect.smartsight.in/login?type=e&url=http%3A%2F%2Flocalhost%3A3000%2Flogin-success&mode=queryString";

  // USAGE - FOR DEV
  // "https://democonnect.smartsight.in/login?type=e&url=https%3A%2F%2Fdemocheckpoint.smartsight.in%2Flogin-success&mode=queryString";

  // USAGE - FOR PROD
  "https://connect.smartsight.in/login?type=e&url=https%3A%2F%2Fcheckpoint.smartsight.in%2Flogin-success&mode=queryString";

// LOGIN OLD URLS
// "https://democonnect.smartsight.in/login?type=e&url=https://democheckpoint.smartsight.in/login-success"; //DEV
// "http://connect.smartsight.in/login?type=e&url=http://checkpoint.smartsight.in/login-success"; //PROD
